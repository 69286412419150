<template>
  <v-toolbar flat>
    <v-toolbar-title>{{ title }}</v-toolbar-title>
    <v-divider class="mx-5" inset vertical></v-divider>
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Поиск"
      single-line
      hide-details
    ></v-text-field>
    <v-divider class="mx-5" inset vertical></v-divider>
    <v-toolbar-title>{{ counter }}</v-toolbar-title>
  </v-toolbar>
</template>
<script>
import { sync } from "vuex-pathify";
export default {
  name: "IndustryToolBar.vue",
  props: {
    title: {
      type: String,
      default: "",
    },
    counter: {
      type: String,
      default: "",
    },
  },
  computed: {
    search: sync("reference/search"),
  },
};
</script>