// Pathify
import { make } from 'vuex-pathify'
import image1 from "@/assets/oil-production-white.png";
import image2 from "@/assets/oil-refining-white.png";
import image3 from "@/assets/oil-export-white.png";


const state = {
    stats: [
        [
            {
                actionIcon: "mdi-information-outline",
                actionText: "Смотреть далее...",
                actionLink: "/app/report/produce",
                color: "#00A876",
                icon: "mdi-account-hard-hat",
                title: "Добыча нефти",
                value: "86 млн.т.",
            },
            {
                actionIcon: "mdi-information-outline",
                actionText: "Смотреть далее...",
                actionLink: "/app/report/refinery",
                color: "#00A876",
                icon: "mdi-pipe",
                title: "Пере&shy;работка нефти",
                value: "11.53 млн.т.",
            },
            {
                actionIcon: "mdi-information-outline",
                actionText: "Смотреть далее...",
                actionLink: "/app/report/transport",
                color: "#00A876",
                icon: "mdi-swap-horizontal-bold",
                title: "Экспорт нефти",
                value: "68.5 млн.т.",
            },
        ],
        [
            {
                actionIcon: "mdi-information-outline",
                actionText: "Смотреть далее...",
                actionLink: "/app/report/produce",
                color: "#00A876",
                icon: "mdi-account-hard-hat",
                title: "Добыча нефти",
                value: " т.",
            },
            {
                actionIcon: "mdi-information-outline",
                actionText: "Смотреть далее...",
                actionLink: "/app/report/refinery",
                color: "#00A876",
                icon: "mdi-pipe",
                title: "Пере&shy;работка нефти",
                value: " т.",
            },
            {
                actionIcon: "mdi-information-outline",
                actionText: "Смотреть далее...",
                actionLink: "/app/report/transport",
                color: "#00A876",
                icon: "mdi-swap-horizontal-bold",
                title: "Экспорт нефти",
                value: " т.",
            },
        ],
        [
            {
                actionIcon: "mdi-information-outline",
                actionText: "Смотреть далее...",
                actionLink: "/app/reference/organization",
                color: "#FFA400",
                icon: "mdi-factory",
                title: "Нефте&shy;добыва&shy;ющие<wbr> организации",
                value: "36 из 75",
            },
            {
                actionIcon: "mdi-information-outline",
                actionText: "Смотреть далее...",
                actionLink: "/app/reference/organization",
                color: "#FFA400",
                icon: "mdi-factory",
                title: "Нефте&shy;транспорт&shy;ные организации",
                value: "4 из 4",
            },
            {
                actionIcon: "mdi-information-outline",
                actionText: "Смотреть далее...",
                actionLink: "/app/reference/organization",
                color: "#FFA400",
                icon: "mdi-factory",
                title: "Нефте&shy;пере&shy;рабатывающие заводы",
                value: "8 из 24",
            },
        ]
    ],
}

const mutations = make.mutations(state)

const actions = {}

const getters = {}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
