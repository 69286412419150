import axios from "axios";

const path = {
    shipment: "api/Chart/Shipment",
    delivery: "api/Chart/Delivery",
    process: "api/Chart/Process",
    refinery: "api/Chart/Refinery",
    storage: "api/Chart/Storage",
    preparation: "api/Chart/Preparation",
};

const getPath = (url) => path[url.trim().toLowerCase()];

const getChartData = async (url, dateFrom, dateTo) => {
    try {
        const path = getPath(url);
        const params = {
            params: { date_from: dateFrom, date_to: dateTo }
        };

        // Получаем значение объекта пользователя из localStorage и парсим его в объект
        const user = JSON.parse(localStorage.getItem("user"));

        // Извлекаем значение роли из объекта user
        const userRole = user?.role || ""; // Используем ?. для безопасного извлечения и предотвращения ошибки, если user не существует

        // Проверяем роль пользователя
        if (userRole === "Customer") {

        // Если роль "Customer", то получаем organizationId из localStorage
        const organizationId = localStorage.getItem("organizationId");
        if (organizationId) {
            params.params.organizationId = organizationId;
        }
    }
        const response = await axios.get(path, params);
        const data = response.data
        // console.log(`Success load from ${path}: `, data);
        return data
    } catch (error) {
        console.log(`Error load from ${path}: `, error.message);
    }
};

export { getChartData }
