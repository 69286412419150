<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    :items-per-page="itemsPerPage"
    class="elevation-1"
    hide-default-footer
  >
    // 29.03.2022 - футер скрыт по требованию аналитиков
    <!-- :footer-props="{
      itemsPerPageAllText: 'Все',
      itemsPerPageText: 'Строк на страницу',
    }" -->
    <template v-slot:[`footer.page-text`]="items">
      {{ items.pageStart }} - {{ items.pageStop }} из {{ items.itemsLength }}
    </template>

    <template v-slot:no-data>
      <v-btn color="primary" @click="reset">Reset</v-btn>
    </template>
  </v-data-table>
</template>
<script>
export default {
  name: "PopupTable.vue",
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      search: "",
    };
  },
  computed: {},
  methods: {
    initialize() {},
    reset() {
      this.$emit("reset");
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
