<template>
  <v-row>
    <v-col>
      <div id="report_chart">
        <apexchart type="area" height="350" :options="chartOptions" :series="series">
        </apexchart>
      </div>
    </v-col>
  </v-row>
</template>
<style>
/**
  Меню экспорта графика в этой версии настраивается только CSS
*/
.apexcharts-menu {
  background-color: transparent !important;
  border: 1px;
}

.apexcharts-menu-item:hover {
  background-color: lightslategrey !important;
  opacity: 1;
}
</style>
<script>
import { Vue } from "vue-property-decorator";

export default Vue.extend({
  name: "ReportChart",
  props: {
    chartOptions: {
      type: Object,
      required: true
    },
    series: {
      type: Array,
      required: true
    }
  }
});
</script>
