<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="loading"
    :search="search"
    :items-per-page="itemsPerPage"
    class="elevation-1"
    :footer-props="{
      itemsPerPageAllText: 'Все',
      itemsPerPageText: 'Строк на страницу',
    }"
  >
    <v-progress-linear
      v-slot:progress
      color="blue"
      indeterminate
    ></v-progress-linear>
    <template v-slot:[`footer.page-text`]="items">
      {{ items.pageStart }} - {{ items.pageStop }} из {{ items.itemsLength }}
    </template>

    <template v-slot:item.datetime="{ item }">
      <td>{{ item.datetime | date }}</td>
    </template>
    <template v-slot:item.action="{ item }">
      <v-btn row justify-center align-center @click="infoItem(item.id)">
        Подробнее...
      </v-btn>
      <!-- <v-icon small class="mr-2" @click="infoItem(item.id)"
          >mdi-dots-horizontal</v-icon
        >-->
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="reset">Reset</v-btn>
    </template>
  </v-data-table>
</template>
<script>
import { get } from "vuex-pathify";
export default {
  name: "IndustryTable.vue",
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 15,
    },
  },
  data() {
    return {};
  },
  computed: {
    search: get("reference/search"),
    loading: get("reference/loading"),
  },
  methods: {
    reset() {
      this.$emit("reset");
    },
    infoItem(itemId) {
      this.$emit("info-item", itemId);
    },
  },
};
</script>