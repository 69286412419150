import { make } from "vuex-pathify";

const state = {
    title: "Информационная система учета сырой нефти и газового конденсата",
    drawer: null,
    drawerImage: true,
    mini: false,
    // notification: {
    //   title: "Уведомление | Notification",
    //   text: '',
    //   show: false
    // },
    items: [
        {
            title: "Главная",
            icon: "mdi-view-dashboard",
            to: "/app/dashboard",
            allowedActionsRoles: ["admin", "manager", "supervisor"],
            allowedOrganizationTypes: ['2', '4'],
        },
        {
            title: "Главная",
            icon: "mdi-view-dashboard",
            to: "/app/customerpage",
            allowedActionsRoles: ["customer"],
            allowedOrganizationTypes: ['10', '11', '13', '14', '16'],
        },
        {
            title: "Отчеты",
            // icon: 'mdi-book-open-variant',
            icon: "mdi-file-chart-outline",
            to: "/app/report/",
            allowedActionsRoles: ["admin", "manager", "supervisor"],
            allowedOrganizationTypes: ['2', '4'],
            items: [
                {
                    title: "Добыча",
                    icon: "mdi-account-hard-hat",
                    to: "/app/report/produce",
                    disabled: false,
                    allowedActionsRoles: [
                        "admin",
                        "manager",
                        "supervisor"
                    ],
                    allowedOrganizationTypes: ['2', '4'],
                },
                // { //! по указанию Жанарыса - 25.05.2021
                //   title: 'Добыча - KGM',
                //   icon: 'mdi-account-hard-hat',
                //   to: '/app/report/produce-kgm',
                // },
                {
                    title: "Транспортировка",
                    // icon: 'mdi-truck-delivery-outline',
                    // icon: 'mdi-pipe',
                    // icon: 'mdi-pipe-valve',
                    icon: "mdi-truck-fast-outline",
                    to: "/app/report/transport",
                    disabled: false,
                    allowedActionsRoles: [
                        "admin",
                        "manager",
                        "supervisor"
                    ],
                    allowedOrganizationTypes: ['2', '4'],
                },
                {
                    title: "Переработка",
                    // icon: 'mdi-hand-saw',
                    // icon: 'mdi-factory',
                    icon: "mdi-hexagon-multiple-outline",
                    to: "/app/report/refinery",
                    disabled: false,
                    allowedActionsRoles: [
                        "admin",
                        "manager",
                        "supervisor"
                    ],
                    allowedOrganizationTypes: ['2', '4'],
                },
                {
                    title: "Битумные заводы",
                    // icon: 'mdi-hand-saw',
                    icon: "mdi-factory",
                    to: "/app/report/factories",
                    disabled: false,
                    allowedActionsRoles: [
                        "admin",
                        "manager",
                        "supervisor"
                    ],
                    allowedOrganizationTypes: ['2', '4'],
                },
                {
                    title: "Нефтяные терминалы",
                    icon: "mdi-oil",
                    to: "/app/report/terminals",
                    disabled: false,
                    allowedActionsRoles: [
                        "admin",
                        "manager",
                        "supervisor"
                    ],
                    allowedOrganizationTypes: ['2', '4'],
                },
            ]
        },
        //Модуль отчеты для роли Customer ниже
        {
            title: "Отчеты",
            icon: "mdi-file-chart-outline",
            to: "/app/customer/creport",
            allowedActionsRoles: ["customer"],
            allowedOrganizationTypes: ['10', '11', '13', '14', '16'],
            items: [
                {
                    title: "Добыча",
                    icon: "mdi-account-hard-hat",
                    to: "/app/customer/creport/cproduce",
                    disabled: false,
                    allowedActionsRoles: ["customer"],
                    allowedOrganizationTypes: ['10']

                },
                {
                    title: "Транспортировка",
                    icon: "mdi-truck-fast-outline",
                    to: "/app/customer/creport/ctransport",
                    disabled: false,
                    allowedActionsRoles: ["customer"],
                    allowedOrganizationTypes: ['11'],
                },
                {
                    title: "Переработка",
                    icon: "mdi-hexagon-multiple-outline",
                    to: "/app/customer/creport/crefinery",
                    disabled: false,
                    allowedActionsRoles: ["customer"],
                    allowedOrganizationTypes: ['13'],
                },
                {
                    title: "Битумные заводы",
                    icon: "mdi-factory",
                    to: "/app/customer/creport/cfactories",
                    disabled: false,
                    allowedActionsRoles: ["customer"],
                    allowedOrganizationTypes: ['14'],
                },
                {
                    title: "Терминал",
                    icon: "mdi-oil",
                    to: "/app/customer/creport/cterminals",
                    disabled: false,
                    allowedActionsRoles: ["customer"],
                    allowedOrganizationTypes: ['16'],
                }
            ]
        },
        {
            title: "Мои контракты",
            icon: "mdi-file-document-edit-outline",
            to: "/app/customer/contracts",
            allowedActionsRoles: ["customer"],
            allowedOrganizationTypes: ["10"],
        },
        {
            title: "Паспорт организации",
            icon: "mdi-passport-biometric",
            to: "/app/customer/passport",
            disabled: false,
            allowedActionsRoles: ["customer"],
            allowedOrganizationTypes: ["10", "11", "13", "14", "16"]
        },
        // {
        //     title: "Месторождения",
        //     icon: "mdi-focus-field",
        //     to: "/app/customer/fields",
        //     allowedActionsRoles: ["customer"],
        //     allowedOrganizationTypes: ["10"],
        // },    
        {
            title: "Справочники",
            icon: "mdi-bookshelf",
            to: "/app/reference/",
            allowedActionsRoles: ["admin", "manager", "supervisor"],
            allowedOrganizationTypes: ['2', '4'],
            items: [
                {
                    title: "Компании",
                    icon: "mdi-domain",
                    to: "/app/reference/organization",
                    allowedActionsRoles: ["admin", "manager"],
                    allowedOrganizationTypes: ['2'],
                },
                {
                    title: "Организации",
                    icon: "mdi-domain",
                    to: "/app/reference/industry/items",
                    allowedActionsRoles: [
                        "admin",
                        "manager",
                        "supervisor"
                    ],
                    allowedOrganizationTypes: ['2', '4'],
                },
                {
                    title: "Контакты",
                    icon: "mdi-phone-message-outline",
                    to: "/app/reference/contact",
                    allowedActionsRoles: [
                        "admin",
                        "manager",
                        "supervisor"
                    ],
                    allowedOrganizationTypes: ['2', '4'],
                },
                // Закрыты временно для демонстрации рукводству по распоряжению Смагулова Аскара 05.04.2022
                {
                    title: "Регионы",
                    icon: "mdi-map-marker-multiple-outline",
                    to: "/app/reference/region",
                    allowedActionsRoles: [
                        "admin",
                        "manager",
                        "supervisor"
                    ],
                    allowedOrganizationTypes: ['2', '4'],
                },
                {
                    title: "Объекты",
                    icon: "mdi-hydraulic-oil-temperature",
                    to: "/app/reference/production_facility",
                    allowedActionsRoles: [
                        "admin",
                        "manager",
                        "supervisor"
                    ],
                    allowedOrganizationTypes: ['2', '4'],
                },
                {
                    title: "Нефтепроводы",
                    icon: "mdi-pipe",
                    to: "/app/reference/pipeline",
                    allowedActionsRoles: [
                        "admin",
                        "manager",
                        "supervisor"
                    ],
                    allowedOrganizationTypes: ['2', '4'],
                },
                {
                    title: "Контракты",
                    icon: "mdi-file-document-edit-outline",
                    to: "/app/reference/contract/items",
                    allowedActionsRoles: [
                        "admin",
                        "manager",
                        "supervisor"
                    ],
                    allowedOrganizationTypes: ['2', '4'],
                },
                {
                    title: "Устройства",
                    icon: "mdi-devices",
                    to: "/app/reference/device",
                    allowedActionsRoles: ["admin"],
                    allowedOrganizationTypes: ['2'],
                },
                {
                    title: "Типы операций",
                    icon: "mdi-format-list-bulleted-type",
                    to: "/app/reference/operation",
                    allowedActionsRoles: ["admin"],
                    allowedOrganizationTypes: ['2'],
                },
                {
                    title: "Типы объектов",
                    icon: "mdi-format-list-bulleted-type",
                    to: "/app/reference/production_facility_type",
                    allowedActionsRoles: ["admin"],
                    allowedOrganizationTypes: ['2'],
                },
                {
                    title: "Типы организаций",
                    icon: "mdi-format-list-bulleted-type",
                    to: "/app/reference/organization_type",
                    allowedActionsRoles: ["admin"],
                    allowedOrganizationTypes: ['2'],
                },
                {
                    title: "Статусы",
                    icon: "mdi-list-status",
                    to: "/app/reference/status",
                    allowedActionsRoles: ["admin"],
                    allowedOrganizationTypes: ['2'],
                }
            ]
        },
        {
            title: "Аналитика",
            icon: "mdi-bug-check",
            to: "/app/analytics/",
            allowedActionsRoles: ["admin", "manager", "customer", "supervisor"],
            allowedOrganizationTypes: ['2', '4', '10', '11', '13', '14'],
            items: [
                {
                    title: "Плановые показатели",
                    icon: "mdi-chart-bar",
                    to: "/app/analytics/oil/kpi/planned-indicators",
                    allowedActionsRoles: [
                        "admin",
                        "manager",
                        "supervisor"
                    ],
                    allowedOrganizationTypes: ['2', '4'],
                },
                {
                    title: "Плановые показатели",
                    icon: "mdi-chart-bar",
                    to: "/app/analytics/oil/kpi/customer-plans",
                    allowedActionsRoles: ["customer"],
                    allowedOrganizationTypes: ['10', '11', '13', '14'],
                },
                {
                    title: "Анализ данных",
                    icon: "mdi-database-alert-outline",
                    to: "/app/analytics/oil/anomalies/max",
                    allowedActionsRoles: [
                        "admin",
                        "manager",
                        "supervisor"
                    ],
                    allowedOrganizationTypes: ['2', '4'],
                },
                {
                    title: "Аудит передачи данных",
                    icon: "mdi-database-alert-outline",
                    to: "/app/analytics/audit/data-transfer",
                    allowedActionsRoles: [
                        "admin",
                        "manager",
                        "supervisor"
                    ],
                    allowedOrganizationTypes: ['2', '4'],
                }
            ]
        },
        {
            title: "Администрирование",
            icon: "mdi-account-cog-outline",
            to: "/app/account/",
            allowedActionsRoles: ["admin"],
            allowedOrganizationTypes: ['2'],
            // allowedActionsRoles: ["admin", "manager"],
            items: [
                {
                    title: "Роли",
                    icon: "mdi-account-group-outline",
                    to: "/app/account/role",
                    allowedActionsRoles: ["admin"],
                    allowedOrganizationTypes: ['2'],
                    // allowedActionsRoles: ["admin", "manager"],
                },
                {
                    title: "Пользователи",
                    icon: "mdi-account-details-outline",
                    to: "/app/account/user",
                    allowedActionsRoles: ["admin"],
                    allowedOrganizationTypes: ['2'],
                    // allowedActionsRoles: ["admin", "manager"],
                },
                {
                    title: "Пользователи-Активность",
                    icon: "mdi-account-check-outline",
                    to: "/app/account/usersActivity",
                    allowedActionsRoles: ["admin"],
                    allowedOrganizationTypes: ['2'],
                    // allowedActionsRoles: ["admin", "manager", "customer", "supervisor"],
                }
            ]
        }
    ],
    months: {
        RU: [
            { id: 0, name: "Январь", short: "Янв", alt: "" },
            { id: 1, name: "Февраль", short: "Фев", alt: "" },
            { id: 2, name: "Март", short: "Мар", alt: "" },
            { id: 3, name: "Апрель", short: "Апр", alt: "" },
            { id: 4, name: "Май", short: "Май", alt: "" },
            { id: 5, name: "Июнь", short: "Июн", alt: "" },
            { id: 6, name: "Июль", short: "Июл", alt: "" },
            { id: 7, name: "Август", short: "Авг", alt: "" },
            { id: 8, name: "Сентябрь", short: "Сен", alt: "" },
            { id: 9, name: "Октябрь", short: "Окт", alt: "" },
            { id: 10, name: "Ноябрь", short: "Ноя", alt: "" },
            { id: 11, name: "Декабрь", short: "Дек", alt: "" }
        ],
        EN: [
            { id: 0, name: "January", short: "Jan", alt: "Ja" },
            { id: 1, name: "February", short: "Feb", alt: "Fe" },
            { id: 2, name: "March", short: "Mar", alt: "Ma" },
            { id: 3, name: "April", short: "Apr", alt: "Ap" },
            { id: 4, name: "May", short: "May", alt: "May" },
            { id: 5, name: "June", short: "Jun", alt: "Ju" },
            { id: 6, name: "July", short: "Jul", alt: "Jul" },
            { id: 7, name: "August", short: "Aug", alt: "Au" },
            { id: 8, name: "September", short: "Sep", alt: "Se" },
            { id: 9, name: "October", short: "Oct", alt: "Oc" },
            { id: 10, name: "November", short: "Nov", alt: "No" },
            { id: 11, name: "December", short: "Dec", alt: "De" }
        ]
    },
    week: {
        RU: [
            { id: 0, name: "Понедельник", short: "Пнд", alt: "Пн" },
            { id: 1, name: "Вторник", short: "Втр", alt: "Вт" },
            { id: 2, name: "Среда", short: "Сре", alt: "Ср" },
            { id: 3, name: "Четверг", short: "Чтв", alt: "Чт" },
            { id: 4, name: "Пятница", short: "Птн", alt: "Пт" },
            { id: 5, name: "Суббота", short: "Суб", alt: "Сб" },
            { id: 6, name: "Воскресенье", short: "Вск", alt: "Вс" }
        ],
        EN: [
            { id: 0, name: "Monday", short: "Mon", alt: "Mo" },
            { id: 1, name: "Tuesday", short: "Tue", alt: "Tu" },
            { id: 2, name: "Wednesday", short: "Wed", alt: "We" },
            { id: 3, name: "Thursday", short: "Thu", alt: "Th" },
            { id: 4, name: "Friday", short: "Fri", alt: "Fry" },
            { id: 5, name: "Saturday", short: "Sat", alt: "Sa" },
            { id: 6, name: "Sunday", short: "Sun", alt: "Su" }
        ]
    }
};

const mutations = {
    ...make.mutations(state)
    // NOTIFICATION_SET: (state, data) => {
    //   state.notification.title = data.title;
    //   state.notification.text = data.text;
    //   state.notification.show = true;

    // },
    // NOTIFICATION_CLEAR: (state) => {
    //   state.notification.title = '';
    //   state.notification.text = '';
    //   state.notification.show = false;

    // },
};

const actions = {
    ...make.actions(state),
    // showNotification: async ({ commit }, data) => {
    //   commit('NOTIFICATION_SET', data)
    //   setTimeout(() => commit('NOTIFICATION_CLEAR'), 5000)
    // },
    init: async ({ dispatch }) => {
        // dispatch('user/update', 'test', { root: true })
        //this.localeChanged(this.$i18n.locale);
    }
};

const getters = {
    shortRU: state => state.months.RU.map(({ short }) => short),
    longRU: state => state.months.RU.map(({ name }) => name)
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
