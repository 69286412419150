<template>
  <v-container fluid class="px-0 mx-0">
    <v-row class="border pa-0 ma-0" no-gutters>
      <v-col class="pa-0 ma-0">
        <div ref="mapView" class="map-container border pa-0 ma-0">
          <default-popup ref="popupComponent" />
          <default-map-settings ref="mapSettings" />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="scss" scoped>
.map-container {
  width: 100%;
  height: 550px; // Обязательно должна быть указана конкретная величина, не %
}

.list-overflow {
  overflow-y: auto;
}

.border {
  border: 1px solid cyan;
}
</style>
<script>
import { Vue } from "vue-property-decorator";
import { sync } from "vuex-pathify";
import { AppMap as Map } from "@/services/map.service.js";
// import fakeNPZ from "@/data/map/NPZ.json";
// import fakeDeposit from "@/data/map/Deposit.json";
import {
  getRefineData,
  getProduceData,
  getExportData,
  getTransportData
} from "@/api/map.api.js";
import "ol/ol.css";

export default Vue.extend({
  name: "MapContainer",
  components: {
    DefaultMapSettings: () => import("./MapSettings.vue"),
    DefaultPopup: () => import("@/components/map/MapPopup.vue")
  },
  data() {
    return {
      appMap: null,
      map: null,
      datas: {
        facilityData: null,
        depositData: null,
        exportData: null,
        transportData: null
      },
      mapRefs: {}
    };
  },
  computed: {
    ...sync("map", [
      "show@facility",
      "show@deposit",
      "show@pipeline",
      "show@oilexport",
      "show@transport",
      "show@grey",
      "show@red",
      "show@yellow",
      "show@green"
    ])
  },
  watch: {
    facility(visible) {
      this.appMap.showFacility(visible);
    },
    deposit(visible) {
      this.appMap.showDeposit(visible);
    },
    pipeline(visible) {
      this.appMap.showPipeline(visible);
    },
    oilexport(visible) {
      this.appMap.showExport(visible);
    },
    transport(visible) {
      this.appMap.showTransport(visible);
    },
    grey(visible) {
      this.appMap.showLayersGroupByConnectionStatus(
        visible,
        this.red,
        this.yellow,
        this.green
      );
    },
    red(visible) {
      this.appMap.showLayersGroupByConnectionStatus(
        this.grey,
        visible,
        this.yellow,
        this.green
      );
    },
    yellow(visible) {
      this.appMap.showLayersGroupByConnectionStatus(
        this.grey,
        this.red,
        visible,
        this.green
      );
    },
    green(visible) {
      this.appMap.showLayersGroupByConnectionStatus(
        this.grey,
        this.red,
        this.yellow,
        visible
      );
    }
  },
  methods: {
    async initialize() {
      const refineData = (await getRefineData()).data;
      // refineData.features.push(...fakeNPZ.features); // по требовнию Аскара Смагулова 07.07.2022
      this.datas.facilityData = refineData;

      const produceData = (await getProduceData()).data;
      // produceData.features.push(...fakeDeposit.features); // по требовнию Аскара Смагулова 07.07.2022
      this.datas.depositData = produceData;

      this.datas.exportData = (await getExportData()).data;
      this.datas.transportData = (await getTransportData()).data;
    },
    reset() {
      this.$emit("reset");
    }
  },
  async mounted() {
    await this.initialize();
    this.mapRefs = {
      Target: this.$refs.mapView,
      Control: this.$refs.mapSettings.$refs.customControl,
      Popup: this.$refs.popupComponent.$refs.popup,
      Table: this.$refs.popupComponent
    };
    this.appMap = new Map(this.mapRefs, this.datas);
    this.map = this.appMap.getMap();
  }
});
</script>
