<template>
  <div ref="popup" id="popup">
    <div class="popup popup-container" align="center">
      <v-row>
        <v-col cols="12">
          <popup-table :headers="headers" :items="items" class="popup-table" />
          <div class="popup-action">
            <a id="popup-report">Перейти к отчету</a>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "MapPopUp",
  components: {
    PopupTable: () => import("@/components/map/MapPopupTable.vue")
  },

  data: () => ({
    headers: [
      { text: "Организация", value: "organizationName" },
      { text: "СИКН", value: "facilityName" },
      // { text: "From", value: "CodeFrom" },
      // { text: "To", value: "Code" },
      { text: "Дата", value: "date" },
      { text: "Масса (тонн)", value: "volume" },
      { text: "Тип учета", value: "accountingType" }
    ],
    items: [{}] //! данные напрямую передаются из карты при обработке нажатия на объект
  }),
  computed: {}
};
</script>
<style lang="scss" scoped>
.popup-container {
  display: none;
  padding: 5px;
  font-family: Tahoma;
  font-size: small;
}

.popup-table {
  zoom: 0.8;
  border-radius: 10px;
}

.popup-action {
  // zoom: 1.1;
  display: flex;
  justify-content: space-between;
  background-color: #1E1E1E;
  padding-right: 10px;
  padding-left: 10px;
  width: fit-content;
  max-width: 70%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  // font-weight: bold;

}

#popup-report {
  outline: none;
  text-decoration: none;
}

#popup-report:link {
  color: white;
}

#popup-report:visited {
  color: white;
}
</style>
