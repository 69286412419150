const alphaLowerCase = "abcdefghijklmnopqrstuvwxyz";
const alphaUpperCase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const numeric = "0123456789";
const nonAlphanumeric = "~@#$%^&*()-_=+|/?.,<>";
const minPartLength = 3;

export function leadingSlash(str) {
  return str.startsWith('/') ? str : '/' + str
}

export function trailingSlash(str) {
  return str.endsWith('/') ? str : str + '/'
}

export const wait = timeout => {
  return new Promise(resolve => setTimeout(resolve, timeout))
}

export const capitalizeString = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// export const fileReader = (oEvent) => {
//   var oFile = oEvent.target.files[0];

//   var reader = new FileReader();
//   var result = {};

//   reader.onload = function (e) {
//     var data = e.target.result;
//     data = new Uint8Array(data);
//     result = xlsx2json(data)
//     console.log(result)
//   }
//   reader.readAsArrayBuffer(oFile);
// }

export const xlsx2json = (data) => {
  const result = {}
  const XLSX = require("xlsx");
  var workbook = XLSX.read(data, { type: 'array' });
  workbook.SheetNames.forEach(function (sheetName) {
    var roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
    if (roa.length) result[sheetName] = roa;
  });
  return result
}

export const json2xlsx = (options) => {
  const XLSX = require("xlsx");
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet([]);
  XLSX.utils.sheet_add_aoa(ws, options.header);

  ws["!cols"] = fitToColumn(options.header);

  XLSX.utils.sheet_add_json(ws, options.data, {
    origin: "A2",
    skipHeader: true,
  });

  XLSX.utils.book_append_sheet(wb, ws, options.sheetName);
  XLSX.writeFile(wb, options.fileName, { bookType: "xlsx", type: "buffer" });
}

const fitToColumn = (arrayOfArray) => {
  return arrayOfArray[0].map((a, i) => ({
    wch: Math.max(
      ...arrayOfArray.map((a2) => (a2[i] ? a2[i].toString().length : 0))
    ),
  }));
}

export const getCurrentLoggedUserRole = () => JSON.parse(localStorage.getItem("user")).role.toLowerCase();
export const getCurrentOrganizationTypeId = () => localStorage.getItem("organizationType")
export const checkPermissions = (roles, role) => roles && roles.includes(role)

const getRandomIntInclusive = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const shuffleString = (string) => string.split('').sort(function () { return 0.5 - Math.random() }).join('');

export const randomPasswordGenerator = () => {
  const partAlphaLowerCase = shuffleString(alphaLowerCase).substring(0, minPartLength);
  const partAlphaUpperCase = shuffleString(alphaUpperCase).substring(0, minPartLength);
  const partNumeric = shuffleString(numeric).substring(0, minPartLength);
  const partNonAlphanumeric = shuffleString(nonAlphanumeric).substring(0, minPartLength);
  const result = shuffleString(partAlphaLowerCase + partAlphaUpperCase + partNumeric + partNonAlphanumeric)
  return result;
}

export const sortByNameRuDescending = function (a, b) {
  if (a.nameRu > b.nameRu) {
    return -1;//or +1 for ascending order  
  } else if (a.nameRu < b.nameRu) {
    return 1;//or -1 for ascending order
  } else {
    return 0;
  }
};

export const sortByNameRuAscending = function (a, b) {
  if (a.nameRu > b.nameRu) {
    return 1;
  } else if (a.nameRu < b.nameRu) {
    return -1;
  } else {
    return 0;
  }
};

// функционал перенесен на бекенд
// export const convertFacilityDataToGeoJSON = (data) => {
//   const collection = {
//     type: "FeatureCollection",
//     name: "СИКН",
//     features: [],
//   };
//   const features = data.map((item, index) => {
//     return {
//       type: "Feature",
//       properties: {
//         id: index,
//         org: item.org,
//         nps: item.name,
//         dt: item.dt,
//         // code: item.code,
//         // codeFrom: item.codeFrom,
//         volume: item.volume,
//       },
//       geometry: {
//         type: "Point",
//         coordinates: [item.lon, item.lat],
//       },
//     };
//   });
//   collection.features = [...features];
//   const result = JSON.stringify(collection);
//   return result;
// }