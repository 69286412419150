import { getChartData } from "@/api/chart.api.js";
import { format, add } from "date-fns";
import axios from "axios";
import { OPERATION_TYPE } from '@/util/globals'

class Chart {
    DEFAULT_CHART_OPTIONS = {
        color: "#1e1e1e",
        title: " ",
        subtitle: "Показатели производства",
        time: "updated ...",
        options: {
            chart: {
                id: "vuechart-default",
                type: 'line',
                height: 180,
                toolbar: {
                    show: false,
                    tools: {
                        download: false,
                        selection: false,
                        zoom: false,
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                        reset: false | '<img src="/static/icons/reset.png" width="20">',
                    },
                },
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '70%',
                    dataLabels: {
                        position: 'center',

                    },
                }
            },
            tooltip: {
                // shared: false,
                // intersect: false
                // onDatasetHover: {
                //     highlightDataSeries: true
                // },
                // x: {
                //     show: true
                // }
            },
            grid: {
                borderColor: '#D3D3D3',
                strokeDashArray: 3,
                xaxis: {
                    lines: {
                        show: true
                    }
                },
                yaxis: {
                    lines: {
                        show: true
                    }
                }
            },
            dataLabels: {
                style: {
                    fontSize: '14px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 'bold',
                    colors: ['#FFF']
                },
            },
            // stroke: {
            //     curve: 'smooth'
            // },
            title: {
                text: undefined,
                align: 'left',
                style: {
                    color: '#FFFFFF',
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    cssClass: 'apexcharts-xaxis-title',
                },
            },
            markers: {
                size: 5
            },
            xaxis: {
                type: 'category',
                // Месяц жесток зашиты для Аналитики План/Факт
                categories: [
                    "Январь",
                    "Февраль",
                    "Март",
                    "Апрель",
                    "Май",
                    "Июнь",
                    "Июль",
                    "Август",
                    "Сентябрь",
                    "Октябрь",
                    "Ноябрь",
                    "Декабрь",
                ],
                title: {
                    text: 'Период',
                    style: {
                        color: '#FAFAFA',
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 600,
                        cssClass: 'apexcharts-xaxis-title',
                    },
                },
                labels: {
                    show: true,
                    rotate: -45,
                    // rotateAlways: false,
                    hideOverlappingLabels: true,
                    showDuplicates: false,
                    trim: false,
                    minHeight: undefined,
                    maxHeight: 120,
                    style: {
                        colors: '#fff',
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400,
                        cssClass: 'apexcharts-xaxis-label',
                    }
                },
            },
            yaxis: {
                title: {
                    text: 'Масса (тонн)',
                    style: {
                        color: '#FAFAFA',
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 600,
                        cssClass: 'apexcharts-xaxis-title',
                    },
                },
                labels: {
                    formatter: function (value) {
                        // if (!options.plotOptions.bar.horizontal) {
                        //     return Math.round(value / 1000);
                        // }
                        return value.toLocaleString();//Math.round(value / 1000);
                    },
                    style: {
                        colors: 'white',
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400,
                        cssClass: 'apexcharts-xaxis-label',
                    }
                },
            },
            legend: {
                show: true,
                showForSingleSeries: true,
                position: 'top',
                horizontalAlign: 'right',
                floating: true,
                offsetY: -25,
                offsetX: -5,
                labels: {
                    colors: '#000',
                    useSeriesColors: true
                },
                tooltipHoverFormatter: function (seriesName, opts) {
                    return seriesName + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>'
                }
            }
        },
        series: [],
    }

    direction = "";
    loading = true;
    loadingMessage = "Подождите, идет загрузка данных...";
    recentYearsNumber = 5;
    displayMonth = "";
    displayQuarter = "";
    displayYear = "";
    valueTotal = 0;

    constructor({ title, type, direction, months }) {
        this.DEFAULT_CHART_OPTIONS.options.title.text = title
        this.chartOptions = this.DEFAULT_CHART_OPTIONS
        this.title = title
        if (type == 'column') {
            this.chartOptions.options.chart.type = 'bar'
            this.chartOptions.options.plotOptions.bar.horizontal = true
            this.chartOptions.options.xaxis.title.text = 'Масса (тонн)'
            this.chartOptions.options.yaxis.title.text = 'Период'
        }
        else {
            this.chartOptions.options.chart.type = type
        }
        this.quarter = [1, 2, 3, 4];
        this.months = [...months]
        this.year = new Date().getFullYear();
        this.years = [...Array(this.recentYearsNumber).keys()].map((item) => this.year - item)
        this.displayYear = new Date().getFullYear();
        this.direction = direction;
        // this.chartOptions.options = {
        //     ...this.chartOptions.options, ...{
        //         xaxis: {
        //             categories: this.months
        //         }
        //     }
        // }
    }

    totalValues = (series) => series.reduce((total, oneSeries) => total += oneSeries.name + ': ' + oneSeries.data.reduce((a, b) => a + b, 0).toLocaleString() + " тонн; ", '');
    updateTime = () => "обновлено в " + format(Date.now(), "HH:mm:ss") + '; ';
    setChartData(data) {
        const series = []
        const items = {}
        items[OPERATION_TYPE.ShipmentToProcessingPlants.toString()] = {
            name: 'Прием на НПЗ',
            type: 'line',
            data: ''
        }
        items[OPERATION_TYPE.ExportShipment.toString()] = {
            name: 'Экспорт',
            type: 'bar',
            data: ''
        }

        data.forEach(item => {
            const oneSeries = items[item.operationType.toString()]
            oneSeries.data = item.data.reverse()
            series.push(oneSeries)
        })

        this.chartOptions.series = series
        this.chartOptions.time = this.updateTime() + this.totalValues(series);
        // this.chartOptions.options.xaxis.title.text = 'Период'
    }
    formatChartData(data, timeUnit) {
        const series = [];
        const labels = [];
        data.forEach((item) => {
            const currentTimeUnit =
                timeUnit == "week" || timeUnit == "month"
                    ? format(new Date(item.dates), "dd-MM-yyyy")//new Date(item.dates).getDate()
                    : this.months[new Date(item.dates).getMonth()];
            const currentIndex = labels.indexOf(currentTimeUnit);
            currentIndex == -1
                ? labels.push(currentTimeUnit) && series.push(item.series)
                : (series[currentIndex] += item.series);
        });
        this.chartOptions.options.xaxis.categories = labels.reverse().map(value => value.toString());

        // const categories = labels.reverse().map(value => value.toString());
        const categories = months.reverse();
        // Недокументированное поведение
        // при обновлении xaxis
        // https://github.com/apexcharts/vue-apexcharts/issues/31
        this.chartOptions.options = {
            ...this.chartOptions.options, ...{
                xaxis: {
                    categories: categories
                }
            }
        }
        return series
    }
    drawChart(data, timeUnit) {
        this.setChartData(data.map(item =>
        ({
            operationType: item.operationType,
            data: this.formatChartData(item.data, timeUnit)
        })));
    }
    getValueTotal() { return this.valueTotal }
    getWeekDate() {
        this.displayQuarter = "";
        this.displayMonth = "";
        return [
            format(add(new Date(), { days: -7 }), "yyyy-MM-dd"),
            format(new Date(), "yyyy-MM-dd"),
        ];
    }
    getQuarterDate(index) {
        this.displayQuarter = index + 1;
        this.displayMonth = "";
        return [
            format(new Date(this.displayYear, index * 3, 1), "yyyy-MM-dd"),
            format(new Date(this.displayYear, index * 3 + 3, 1), "yyyy-MM-dd"),
        ];
    }
    getMonthDate(index) {
        this.displayQuarter = "";
        this.displayMonth = this.months[index];
        return [
            format(new Date(this.displayYear, index, 1), "yyyy-MM-dd"),
            format(new Date(this.displayYear, index + 1, 1), "yyyy-MM-dd"),
        ];
    }
    getYearDate(index) {
        this.displayQuarter = "";
        this.displayMonth = "";
        this.displayYear = this.years[index];
        return [`${this.displayYear}-01-01`, `${this.displayYear + 1}-01-01`];
    }
    async fetchChartData(dateFrom, dateTo) {
        this.loading = true;
        const path = "api/Chart/ShipmentExtended";
        const params = [
            {
                params: { operationType: OPERATION_TYPE.ShipmentToProcessingPlants, date_from: dateFrom, date_to: dateTo },
            },
            {
                params: { operationType: OPERATION_TYPE.ExportShipment, date_from: dateFrom, date_to: dateTo },
            }
        ];
        const requests = params.map(param => axios.get(path, param))
        const values = (await axios.all(requests)).map(value => value.data)
        this.loading = false;
        return values;
    }
    async showChartForLastWeek() {
        const [fromDate, toDate] = this.getWeekDate(); //! Для Production
        // const [fromDate, toDate] = ["2020-12-1", "2020-12-8"]; // Для тестов
        const data = await this.fetchChartData(fromDate, toDate);
        this.drawChart(data, "week");
    }
    async showChartForQuarter(index) {
        const [fromDate, toDate] = this.getQuarterDate(index);
        const data = await this.fetchChartData(fromDate, toDate);
        this.drawChart(data, "quartal");
    }
    async showChartForMonth(index) {
        const [fromDate, toDate] = this.getMonthDate(index);
        const data = await this.fetchChartData(fromDate, toDate);
        this.drawChart(data, "month");
    }
    async showChartForYear(index) {
        const [fromDate, toDate] = this.getYearDate(index);
        const data = await this.fetchChartData(fromDate, toDate);
        this.drawChart(data, "year");
    }
}
export { Chart }
