const EN_LOCALE_ONLY = process.env.EN_LOCALE_ONLY === 'true'
const IN_BROWSER = typeof window !== 'undefined'
const IS_DEBUG = process.env.DEBUG === 'true'
const IS_PROD = process.env.NODE_ENV === 'production'

const PRODUCTION_FACILITY_TYPES = Object.freeze({
  /// <summary>
  /// Нефтедобывающая организация
  /// </summary>
  OilProducingCompany: 2,

  /// <summary>
  /// Нефтетранспортная компания
  /// </summary>
  OilTransportationCompany: 3,

  /// <summary>
  /// Нефтеперерабатывающий завод
  /// </summary>
  OilRefiningCompany: 4,

  /// <summary>
  /// Битумный завод
  /// </summary>
  BitumenFactory: 5,

  /// <summary>
  /// Нефтяной терминал
  /// </summary>
  OilTerminal: 6,

  /// <summary>
  /// Оператор
  /// </summary>
  Operator: 7,
})
const STATUS = Object.freeze({
  /// <summary>
  /// Удаленный объект
  /// </summary>
  Deleted: 2,

  /// <summary>
  /// Архивированный объект
  /// </summary>
  Archieved: 3,

  /// <summary>
  /// Активный объект
  /// </summary>
  Active: 4,

  /// <summary>
  /// Просрочен
  /// </summary>
  Expired: 5
})

const OPERATION_TYPE = Object.freeze({
  /// <summary>
  /// Поставка от нефтедобывающих организации
  /// </summary>
  DeliveryFromOilProducingOrganizations: 1,

  /// <summary>
  /// Отгрузка на перерабатывающие заводы
  /// </summary>
  ShipmentToProcessingPlants: 2,

  /// <summary>
  /// Отгрузка на транспортные организации
  /// </summary>
  ShipmentToTransportOrganizations: 3,

  /// <summary>
  /// Отгрузка на экспорт
  /// </summary>
  ExportShipment: 4,

  /// <summary>
  /// Поставка от транспортных организации
  /// </summary>
  DeliveryFromTransportOrganizations: 5,

  /// <summary>
  /// Подготовка
  /// </summary>
  Preparation: 6,

  /// <summary>
  /// Хранение
  /// </summary>
  Storage: 7,

  /// <summary>
  /// Отгрузка на нефтяные терминалы
  /// </summary>
  ShipmentToOilTerminals: 8,
})

const ORGANIZATION_TYPES = Object.freeze({
  /// <summary>
  /// Оператор
  /// </summary>
  Operator: 2,

  /// <summary>
  /// Нефтянное предприятие
  /// </summary>
  OilCompany: 3,

  /// <summary>
  /// Государственное учреждение
  /// </summary>
  Government: 4,

  /// <summary>
  /// Добывающая компания
  /// </summary>
  Produce: 10,
  
  /// <summary>
  /// Транспортная компания
  /// </summary>
  Transport: 11,
  
  /// <summary>
  /// Перерабатывающая компания
  /// </summary>
  Refinery: 13,

  /// <summary>
  /// Битумный завод
  /// </summary>
  Factory: 14,

  /// <summary>
  /// Битумный завод
  /// </summary>
  Terminal: 16,
})
module.exports = {
  STATUS,
  OPERATION_TYPE,
  PRODUCTION_FACILITY_TYPES,
  ORGANIZATION_TYPES,
  EN_LOCALE_ONLY,
  IN_BROWSER,
  IS_DEBUG,
  IS_PROD,
}
