<template>
    <div style="margin: 20px 0 -20px">
        <v-row no-gutters>
            <v-col>
                <div>
                    <p class="font-weight-bold text-center text--accent-2 ml-5">
                        <v-icon v-text="icon" class="mr-4" style="flex:0"></v-icon>
                        {{title.toUpperCase()}}
                    </p>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: 'ChartSectionTitle.vue',
        components: {},
        props: {
            title: String,
            icon: String,
        },
        data() {
            return {}
        },
        methods: {
        },
        async mounted() {
        }
    }
</script>