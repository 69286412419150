import { getChartData } from "@/api/chart.api.js";
import { format, add } from "date-fns";

class Chart {
    DEFAULT_CHART_OPTIONS = {
        color: "#1e1e1e",
        title: " ",
        subtitle: "Показатели производства",
        time: "updated ...",
        options: {
            chart: {
                id: "vuechart-default",
                type: 'line',
                height: 180,
                toolbar: {
                    show: false,
                    tools: {
                        download: false,
                        selection: false,
                        zoom: false,
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                        reset: false | '<img src="/static/icons/reset.png" width="20">',
                    },
                },
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '70%',
                    dataLabels: {
                        position: 'center',

                    },
                }
            },
            tooltip: {
                // shared: false,
                // intersect: false
                // onDatasetHover: {
                //     highlightDataSeries: true
                // },
                // x: {
                //     show: true
                // }
            },
            grid: {
                borderColor: '#D3D3D3',
                strokeDashArray: 3,
                xaxis: {
                    lines: {
                        show: true
                    }
                },
                yaxis: {
                    lines: {
                        show: true
                    }
                }
            },
            dataLabels: {
                enabled: false,
                style: {
                    fontSize: '10px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 'bold',
                    colors: ['#FFF']
                },
            },
            stroke: {
                curve: 'smooth'
            },
            title: {
                text: undefined,
                align: 'left',
                style: {
                    color: '#FFFFFF',
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    cssClass: 'apexcharts-xaxis-title',
                },
            },
            markers: {
                size: 5
            },
            xaxis: {
                type: 'category',
                categories: [],
                title: {
                    text: 'Период',
                    style: {
                        color: '#FAFAFA',
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 600,
                        cssClass: 'apexcharts-xaxis-title',
                    },
                },
                labels: {
                    show: true,
                    rotate: -45,
                    // rotateAlways: false,
                    hideOverlappingLabels: true,
                    showDuplicates: false,
                    trim: false,
                    minHeight: undefined,
                    maxHeight: 120,
                    style: {
                        colors: '#fff',
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400,
                        cssClass: 'apexcharts-xaxis-label',
                    }
                },
                // datetimeFormatter: {
                //     year: 'yyyy',
                //     month: "MMM 'yy",
                //     day: 'dd MMM',
                //     hour: 'HH:mm',
                // },
            },
            yaxis: {
                title: {
                    text: 'Масса (тонн)',
                    style: {
                        color: '#FAFAFA',
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 600,
                        cssClass: 'apexcharts-xaxis-title',
                    },
                },
                labels: {
                    formatter: function (value) {
                        // if (!options.plotOptions.bar.horizontal) {
                        //     // return Math.round(value / 1000);
                        //     return value.toLocaleString();
                        // }
                        return value.toLocaleString();//Math.round(value / 1000);
                    },
                    style: {
                        colors: 'white',
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400,
                        cssClass: 'apexcharts-xaxis-label',
                    }
                },
            },
            legend: {
                show: true,
                showForSingleSeries: true,
                position: 'top',
                horizontalAlign: 'right',
                floating: true,
                offsetY: -25,
                offsetX: -5,
                labels: {
                    colors: '#000',
                    useSeriesColors: true
                },
                tooltipHoverFormatter: function (seriesName, opts) {
                    return seriesName + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>'
                }
            }
        },
        series: [],
    }

    direction = "";
    loading = true;
    loadingMessage = "Подождите, идет загрузка данных...";
    recentYearsNumber = 5;
    displayMonth = "";
    displayNineMonth = "";
    displayQuarter = "";
    displayYear = "";
    valueTotal = 0;

    constructor({ title, type, direction, months }) {
        this.DEFAULT_CHART_OPTIONS.options.title.text = title
        this.chartOptions = this.DEFAULT_CHART_OPTIONS
        this.title = title
        if (type == 'column') {
            this.chartOptions.options.chart.type = 'bar'
            this.chartOptions.options.plotOptions.bar.horizontal = true
            this.chartOptions.options.xaxis.title.text = 'Масса (тонн)'
            this.chartOptions.options.yaxis.title.text = 'Период'
        }
        else {
            this.chartOptions.options.chart.type = type
        }
        this.nineMonth = ["9 месяцев"];
        this.quarter = [1, 2, 3, 4];
        this.months = [...months]
        this.year = new Date().getFullYear();
        this.years = [...Array(this.recentYearsNumber).keys()].map((item) => this.year - item)
        this.displayYear = new Date().getFullYear();
        this.direction = direction;

    }

    setChartData(series, labels) {

        // Округляем значения в массиве series до двух знаков после запятой
        const roundedSeries = series.map(value => value.toFixed(2));
        
        this.chartOptions.series = [{
            name: this.title,
            data: roundedSeries.reverse()
        }];
        const categories = labels.reverse().map(value => value.toString());
        // Недокументированное поведение
        // при обновлении xaxis
        // https://github.com/apexcharts/vue-apexcharts/issues/31
        this.chartOptions.options = {
            ...this.chartOptions.options, ...{
                xaxis: {
                    categories: categories
                }
            }
        }
        // console.log("Processed series for chart:", series);
        // console.log("Processed labels for chart:", labels);
        // console.log(this.chartOptions.options.xaxis.categories)
        const total = "; Всего: " + series.reduce((a, b) => a + b, 0).toLocaleString() + " тонн";
        const time = "обновлено в " + format(Date.now(), "HH:mm:ss");
        this.chartOptions.time = `${time}` + `${total}`
    }
    drawChart(data, timeUnit) {
        const series = [];
        const labels = [];
        data.forEach((item) => {
            const currentTimeUnit =
                timeUnit == "week" || timeUnit == "month"
                    // ? new Date(item.dates).getDate()
                    // ? new Date(item.dates).toISOString().slice(0, 10)
                    ? (timeUnit == "week" ? format(new Date(item.dates), "dd-MM-yyyy") : format(new Date(item.dates), "dd"))
                    : this.months[new Date(item.dates).getMonth()];
            const currentIndex = labels.indexOf(currentTimeUnit);
            // console.log(currentTimeUnit)
            currentIndex == -1
                ? labels.push(currentTimeUnit) && series.push(item.series)
                : (series[currentIndex] += item.series);
        });
        // console.log("Original data:", data);
        // console.log("Processed series:", series);
        // console.log("Processed labels:", labels);
        this.setChartData(series, labels);
        // this.setXTitle(timeUnit);
    }
    setXTitle(title) {
        this.chartOptions.options.xaxis.title.text = 'Период'
        // this.DEFAULT_CHART_OPTIONS.options.xaxis.title.text = 'Период';
    }
    getValueTotal() { return this.valueTotal }
    getWeekDate() {
        this.displayQuarter = "";
        this.displayMonth = "";
        return [
            format(add(new Date(), { days: -7 }), "yyyy-MM-dd"),
            format(new Date(), "yyyy-MM-dd"),
        ];
    }
    getQuarterDate(index) {
        this.displayQuarter = index + 1;
        this.displayMonth = "";
        return [
            format(new Date(this.displayYear, index * 3, 1), "yyyy-MM-dd"),
            format(new Date(this.displayYear, index * 3 + 3, 1), "yyyy-MM-dd"),
        ];
    }
    getMonthDate(index) {
        this.displayQuarter = "";
        this.displayMonth = this.months[index];
        return [
            format(new Date(this.displayYear, index, 1), "yyyy-MM-dd"),
            format(new Date(this.displayYear, index + 1, 1), "yyyy-MM-dd"),
        ];
    }
    getYearDate(index) {
        this.displayQuarter = "";
        this.displayMonth = "";
        this.displayYear = this.years[index];
        return [`${this.displayYear}-01-01`, `${this.displayYear + 1}-01-01`];
    }
    getNineMonthsDate() {
        this.displayQuarter = "";
        this.displayMonth = "";
        return [
            format(add(new Date(), { months: -9 }), "yyyy-MM-dd"),
            format(new Date(), "yyyy-MM-dd"),
        ];
    }
    async fetchChartData(dateFrom, dateTo) {
        this.loading = true;
        const data = await getChartData(this.direction, dateFrom, dateTo);
        this.loading = false;
        return data;
    }
    async showChartForLastWeek() {
        const [fromDate, toDate] = this.getWeekDate();
        const data = await this.fetchChartData(fromDate, toDate); //! Для Production
        // const data = await this.fetchChartData("2020-12-1", "2020-12-8"); // Для тестов
        this.drawChart(data, "week");
    }
    async showChartForQuarter(index) {
        const [fromDate, toDate] = this.getQuarterDate(index);
        const data = await this.fetchChartData(fromDate, toDate);
        this.drawChart(data, "quartal");
    }
    async showChartForMonth(index) {
        const [fromDate, toDate] = this.getMonthDate(index);
        const data = await this.fetchChartData(fromDate, toDate);
        this.drawChart(data, "month");
    }
    async showChartForYear(index) {
        const [fromDate, toDate] = this.getYearDate(index);
        const data = await this.fetchChartData(fromDate, toDate);
        this.drawChart(data, "year");
    }
    async showChartForNineMonths() {
        const [fromDate, toDate] = this.getNineMonthsDate();
        const data = await this.fetchChartData(fromDate, toDate);
        this.drawChart(data, "nineMonths");
    }
    
}
export { Chart }
