<template>
  <router-view />
</template>

<script>
import "@/styles/overrides.sass";

export default {
  name: "App",
  metaInfo: {
    title: "АО \"САЦ ТЭК РК\"",
    titleTemplate: "%s | ИСУН",
    htmlAttrs: { lang: "en" },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
  mounted() {
    this.$vuetify.theme.dark = true; // Принудительно выбираю темную тему
  },
};
</script>
