const reg = {
    alfabet: /^[a-zA-Zа-яА-ЯҒғҚқӘәҢңҮүҰұІіЁёЪъҺһ]{3,50}$/,
    login: /^[a-zA-Z0-9.]{3,50}$/,
    alfabetEn: /^[a-zA-Z ]{1,50}$/,
    alfabetRu: /^[а-яА-Я ]{1,50}$/,
    email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    phone: /^\d{10}$/,
    bin: /^\d{12}$/,
    iin: /^\d{12}$/,
    code: /^\d{6}$/,
    dig: /^[0-9]{1,5}$/,
    nums: /^[0-9]$/,
    dot: /^[.]$/,
    coordinate: /^[0-9.]{9}$/,

    // password: /^(?=.*[0-9])(?=.*[~@#$%^&*()-_=+|/?.,<>])[a-zA-Z0-9~@#$%^&*()-_=+|/?.,<>]{8,15}$/
    password: /^[a-zA-Z0-9!~@#$%^&*()-_=+|/?.,<>]{8,15}$/
}
export const rules = {
    required: (v) => !!v || "Обязательное поле.",
    min: (v) => v.length >= 3 || "Минимум 3 символа",
    login: (v) => reg.login.test(v) || "Допускается буквы латиницы, цифры и символ ’.’",
    alfabet: (v) => reg.alfabet.test(v) || "Допускаются только буквы от 3 до 30 символов",
    alfabetEn: (v) => reg.alfabetEn.test(v) || "Допускается только латиница от 1 до 30 символов",
    alfabetRu: (v) => reg.alfabetRu.test(v) || "Допускается только кириллица от 1 до 30 символов",
    email: (v) => reg.email.test(v) || "`Введите почту в формате ‘address@isun.kz’`",
    phone: (v) => reg.phone.test(v) || "Номер должен состоять из 10 цифр, без +7",
    bin: (v) => reg.bin.test(v) || "Введите 12 цифр",
    dig: (v) => reg.dig.test(v) || "Допускаются только цифры, максимальное количество - 5",
    nums: (v) => reg.dig.test(v) || "Допускаются цифры 0-9",
    dot: (v) => reg.dot.test(v) || "Допускается символ точки '.'",
    iin: (v) => reg.iin.test(v) || "Введите 12 цифр",
    code: (v) => reg.code.test(v) || "Код прибора учета должен состоять из 6 цифр",
    password: (v) => reg.password.test(v) || "Пароль должен содержать от 8 до 15 символов, латинские буквы верхнего и нижнего регистра, цифру и специальный символ",
    coordinate: (v) => reg.coordinate.test (v) || "Введите значение в градусах в формате 11.222333"
}