import { make } from 'vuex-pathify'
import axios from "axios";
import { PRODUCTION_FACILITY_TYPES } from '@/util/globals'
import { ORGANIZATION_TYPES } from '@/util/globals'
import { STATUS } from '@/util/globals'
import { getCurrentLoggedUserRole } from "@/util/helpers";

const state = {
    showError: false,
    errorMessages: {
        reference: 'Error while loading reference',
    },
    errorMessage: '',
    loading: false,
    search: '',
    currentItem: null,
    dialog: false,
    optionsRegion: {
        title: {
            main: 'Регионы',
            create: 'Создать регион',
        },
        path: { base: 'api/Reference/Region' },
        editedItem: {
            id: 0,
            code: 0,
            nameRu: "",
            nameKz: "",
            nameEn: "",
            shortName: "",
        },
        defaultItem: {
            id: 0,
            code: 0,
            nameRu: "",
            nameKz: "",
            nameEn: "",
            shortName: "",
        },
        headers: [
            // {
            //     text: "ID",
            //     align: "left",
            //     sortable: false,
            //     value: "id",
            // },
            // { text: "Код", value: "code" },
            { text: "Наименование", value: "nameRu" },
            // { text: "Наименование (KZ)", value: "nameKz" },
            // { text: "Наименование (EN)", value: "nameEn" },
            { text: "Статус", value: "status.nameRu" },
            { text: "Действия", value: "action", sortable: false },
        ],
        data: [],
    },
    optionsFacility: {
        title: {
            main: 'Типы производственных объектов',
            create: 'Создать тип объекта',
        },
        path: { base: "api/Reference/Facility" },
        editedItem: {
            id: 0,
            nameRu: "",
            nameKz: "",
            nameEn: "",
            shortName: "",
        },
        defaultItem: {
            id: 0,
            nameRu: "",
            nameKz: "",
            nameEn: "",
            shortName: "",
        },
        headers: [
            // {
            //     text: "ID",
            //     align: "left",
            //     sortable: false,
            //     value: "id",
            // },
            { text: "Наименование", value: "nameRu" },
            // { text: "Наименование (KZ)", value: "nameKz" },
            // { text: "Наименование (EN)", value: "nameEn" },
            { text: "Символьный код", value: "shortName" },
            { text: "Статус", value: "status.nameRu" },
            { text: "Действия", value: "action", sortable: false },
        ],
        data: [],
    },
    optionsOperation: {
        title: {
            main: 'Типы производственных операций',
            create: 'Создать тип операции',
        },
        path: {
            base: "api/Reference/Operation",
        },
        editedItem: {
            id: 0,
            name: "",
        },
        defaultItem: {
            id: 0,
            name: "",
        },
        headers: [
            // {
            //     text: "ID",
            //     align: "left",
            //     sortable: false,
            //     value: "id",
            // },
            { text: "Наименование", value: "name" },
            { text: "Тип устройства", value: "deviceType.name" },
            { text: "Действия", value: "action", sortable: false },
        ],
        data: [],
    },
    optionsDevice: {
        title: {
            main: 'Типы устройств',
            create: 'Создать тип устройства',
        },
        path: { base: "api/Reference/Device", },
        editedItem: {
            id: 0,
            name: "",
        },
        defaultItem: {
            id: 0,
            name: "",
        },
        headers: [
            // {
            //     text: "ID",
            //     align: "left",
            //     sortable: false,
            //     value: "id",
            // },
            { text: "Тип", value: "name" },
            { text: "Действия", value: "action", sortable: false },
        ],
        data: [],
    },
    optionsPipeline: {
        title: {
            main: 'Нефтепроводы',
            create: 'Создать нефтепровод',
        },
        path: { base: "api/Reference/Pipeline" },
        editedItem: {
            id: 0,
            nameRu: "",
            nameKz: "",
            nameEn: "",
        },
        defaultItem: {
            id: 0,
            nameRu: "",
            nameKz: "",
            nameEn: "",
        },
        headers: [
            // {
            //     text: "ID",
            //     align: "left",
            //     sortable: false,
            //     value: "id",
            // },
            { text: "Наименование", value: "nameRu" },
            // { text: "Наименование (KZ)", value: "nameKz" },
            // { text: "Наименование (EN)", value: "nameEn" },
            { text: "Статус", value: "status.nameRu" },
            { text: "Действия", value: "action", sortable: false },
        ],
        data: [],
    },
    optionsStatus: {
        title: {
            main: 'Статусы',
            create: 'Создать статус'
        },
        path: { base: "api/Reference/Status" },
        editedItem: {
            id: '',
            nameRu: "",
            nameKz: "",
            nameEn: "",
        },
        defaultItem: {
            id: '',
            nameRu: "",
            nameKz: "",
            nameEn: "",
        },
        headers: [
            // {
            //     text: "ID",
            //     align: "left",
            //     sortable: false,
            //     value: "id",
            // },
            { text: "Наименование", value: "nameRu" },
            // { text: "Наименование (KZ)", value: "nameKz" },
            // { text: "Наименование (EN)", value: "nameEn" },
            { text: "Действия", value: "action", sortable: false },
        ],
        data: [],
    },
    optionsContact: {
        title: {
            main: 'Контакты',
            create: 'Создать контакт',
        },
        path: {
            base: `api/Reference/Contact`
        },
        editedItem: {
            id: "",
            position: "",
            subdivision: "",
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
        },
        defaultItem: {
            id: 0,
            position: "",
            subdivision: "",
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
        },
        headers: [
            // {
            //     text: "ID",
            //     align: "left",
            //     sortable: false,
            //     value: "id",
            // },
            { text: "Организация", value: "organization.nameRu" },
            { text: "Должность", value: "position" },
            { text: "Подразделение", value: "subdivision" },
            { text: "Имя", value: "firstName" },
            { text: "Фамилия", value: "lastName" },
            { text: "Адрес", value: "email" },
            { text: "Телефон", value: "phone" },
            { text: "Статус", value: "status.nameRu" },
            { text: "Действия", value: "action", sortable: false },
        ],
        data: [],
    },
    optionsOrganizationType: {
        title: {
            main: 'Типы организаций',
            create: 'Создать тип организации',
        },
        path: {
            base: `api/Reference/Organization/Type`,
            sub: '',
        },
        editedItem: {
            id: "",
            nameRu: "",
            nameKz: "",
            nameEn: "",
            shortName: "",
        },
        defaultItem: {
            id: "",
            nameRu: "",
            nameKz: "",
            nameEn: "",
            shortName: "",
        },
        headers: [
            // {
            //     text: "ID",
            //     align: "left",
            //     sortable: false,
            //     value: "id",
            // },
            { text: "Наименование", value: "nameRu" },
            // { text: "Наименование (RU)", value: "nameKz" },
            // { text: "Наименование (RU)", value: "nameEn" },
            { text: "Символьный код", value: "shortName" },
            { text: "Статус", value: "status.nameRu" },
            { text: "Действия", value: "action", sortable: false },
        ],
        data: [],
    },
    optionsOrganization: {
        title: {
            main: 'Организации',
            create: 'Создать организацию',
        },
        path: {
            base: `api/Reference/Organization`,
            sub: '/0/0',
        },
        editedItem: {
            id: "",
            nameRu: "",
            nameKz: "",
            nameEn: "",
            bin: "",
            address: "",
        },
        defaultItem: {
            id: "",
            nameRu: "",
            nameKz: "",
            nameEn: "",
            bin: "",
            address: "",
        },
        headers: [
            // {
            //     text: "ID",
            //     align: "left",
            //     sortable: false,
            //     value: "id",
            // },
            { text: "Наименование", value: "nameRu" },
            // { text: "Наименование (KZ)", value: "nameKz" },
            // { text: "Наименование (EN)", value: "nameEn" },
            { text: "БИН", value: "bin" },
            { text: "Регион", value: "region.nameRu" },
            { text: "Адрес", value: "address" },
            { text: "Статус", value: "status.nameRu" },
            { text: "Тип", value: "organizationType.shortName" },
            { text: "Действия", value: "action", sortable: false },
        ],
        data: [],
    },
    optionsIndustry: {
        title: {
            main: 'Производственные организации',
            secondary: 'Организации',
            info: 'Подробнее...',
        },
        path: {
            base: `api/Reference/Organization/`,
            organizationType: `${ORGANIZATION_TYPES.Produce}/`,
            status: `/${STATUS.Active}`
        },
        category: [
            {
                id: ORGANIZATION_TYPES.Produce,
                name: "Нефтедобывающие организации",
                link: "OilProducingCompany",
            },
            {
                id: ORGANIZATION_TYPES.Transport,
                name: "Нефтетранспортные компании",
                link: "OilTransportationCompany",
            },
            {
                id: ORGANIZATION_TYPES.Refinery,
                name: "Нефтеперерабатывающие заводы",
                link: "OilRefiningCompany",
            },
            {
                id: ORGANIZATION_TYPES.Factory,
                name: "Битумные заводы",
                link: "BitumenFactory",
            },
            {
                id: ORGANIZATION_TYPES.Terminal,
                name: "Нефтяные терминалы",
                link: "OilTerminal",
            },
        ],
        properties: [
            {
                id: 0,
                name: "Контракты",
                link: "Contracts",
            },
            {
                id: 1,
                name: "Производственные объекты",
                link: "ProductionFacilities",
            },
            // Скрыто времено по распоряжению Смагулова Аскара 07.04.2022
            // {
            //     id: 2,
            //     name: "Приборы учета",
            //     link: "MeteringDevices",
            // },
        ],
        headers: [
            // {
            //     text: "ID",
            //     align: "left",
            //     sortable: false,
            //     value: "id",
            // },
            { text: "Наименование", value: "nameRu" },
            // { text: "Наименование (KZ)", value: "nameKz" },
            // { text: "Наименование (EN)", value: "nameEn" },
            // { text: "БИН", value: "bin" },
            { text: "Регион", value: "region.nameRu" },
            // { text: "Адрес", value: "address" },
            // { text: "Статус", value: "status.nameRu" },
            // { text: "Тип", value: "organizationType.shortName" },
            { text: "Действия", align: "center", value: "action", sortable: false },
        ],
        items: [],
        item: null,
        productionFacilityList: []
    },
    optionsProductionFacility: {
        title: 'Производственные объекты',
        createItemTitle: 'Создать объект',
        path: {
            base: 'api/Reference/ProductionFacility',
            sub: `/${ORGANIZATION_TYPES.OilCompany}/0/4`,
            fullByOrg: `api/Reference/Organization/ProductionFacility/`,
        },
        editedItem: {
            id: 0,
            nameRu: "",
            nameKz: "",
            nameEn: "",
            kilometer: 0,
            latitude: "",
            longitude: "",
            code: "",
            connectionStatus: 0
        },
        defaultItem: {
            id: 0,
            nameRu: "",
            nameKz: "",
            nameEn: "",
            kilometer: 0,
            latitude: "",
            longitude: "",
            code: "",
            connectionStatus: 0
        },
        headers: [
            // {
            //     text: "ID",
            //     align: "left",
            //     sortable: false,
            //     value: "id",
            // },
            { text: "Наименование", value: "nameRu" },
            { text: "Организация", value: "organization.nameRu" },
            { text: "Нефтепровод", value: "oilPipeline.nameRu" },
            { text: "Регион", value: "region.nameRu" },
            // { text: "Километр", value: "kilometer" },
            { text: "Статус", value: "status.nameRu" },
            { text: "Широта", value: "latitude" },
            { text: "Долгота", value: "longitude" },
            { text: "Код", value: "code" },
            { text: "Тип", value: "productionFacilityType.shortName" },
            { text: "Операция", value: "operationType.name" },
            { text: "Подключение", value: "connectionStatus", sortable: false },
            { text: "Действия", value: "action", sortable: false },
        ],
        data: [],
        dataByOrganization: [],
    },
    optionsRole: {
        title: {
            main: 'Роли пользователей',
            create: 'Создать роль',
        },
        path: {
            base: `api/Account/Role`,
        },
        editedItem: {
            id: "",
            name: "",
        },
        defaultItem: {
            id: "",
            name: "",
        },
        headers: [
            // {
            //     text: "ID",
            //     align: "left",
            //     sortable: false,
            //     value: "id",
            // },
            { text: "Имя", value: "name" },
            { text: "Статус", value: "status.nameRu" },
            { text: "Действия", value: "action", sortable: false },
        ],
        data: [],
    },
    optionsUser: {
        title: {
            main: 'Пользователи',
            create: 'Создать пользователя',
        },
        path: {
            base: `api/Account/User`,
        },
        editedItem: {
            id: "",
            userName: "",
            firstNameRu: "",
            lastNameRu: "",
            firstNameKz: "",
            lastNameKz: "",
            firstNameEn: "",
            lastNameEn: "",
            email: "",
            phoneNumber: "",
            password: "",
            confirmpassword: "",
            role: "",
            iin: ''
        },
        defaultItem: {
            id: "",
            userName: "",
            firstNameRu: "",
            lastNameRu: "",
            firstNameKz: "",
            lastNameKz: "",
            firstNameEn: "",
            lastNameEn: "",
            email: "",
            phoneNumber: "",
            password: "",
            confirmpassword: "",
            role: "",
            iin: ''
        },
        headers: [
            // {
            //     text: "ID",
            //     align: "left",
            //     sortable: false,
            //     value: "id",
            // },
            { text: "Организация", value: "organization.nameRu" },
            { text: "Роль", value: "role.name" },            
            { text: "Имя", value: "person.firstNameRu" },
            { text: "Фамилия", value: "person.lastNameRu" },
            { text: "Логин", value: "userName" },
            { text: "ИИН", value: "iin" },
            { text: "Почта", value: "email" },
            { text: "Телефон", value: "phoneNumber" },
            { text: "Статус", value: "status.nameRu" },
            { text: "Действия", value: "action", sortable: false },
        ],
        headersForXLSX: [
            // {
            //     text: "ID",
            //     align: "left",
            //     sortable: false,
            //     value: "id",
            // },
            { text: "Организация", value: "organization.nameRu" },
            { text: "Роль", value: "role.name" },            
            { text: "Имя", value: "person.firstNameRu" },
            { text: "Фамилия", value: "person.lastNameRu" },
            { text: "Логин", value: "userName" },
            { text: "ИИН", value: "iin" },
            { text: "Почта", value: "email" },
            { text: "Телефон", value: "phoneNumber" },
            { text: "Статус", value: "status.nameRu" },
        ],
        data: [],
        dataForXLSX: [],
    },
    optionsContract: {
        title: {
            main: 'Контракты',
        },
        category: [
            {
                id: 0,
                headerId: 0,
                name: "Контракты",
                link: "Contract",
                path: `api/Reference/Contract`
            },
            {
                id: 1,
                headerId: 1,
                name: "Тип",
                link: "Type",
                path: `api/Reference/Contract/Type`,
            },
            {
                id: 2,
                headerId: 1,
                name: "Вид",
                link: "Category",
                path: `api/Reference/Contract/Category`,
            },
            {
                id: 3,
                headerId: 1,
                name: "Группа Полезных ископаемых",
                link: "MineralGroup",
                path: `api/Reference/Contract/MineralGroup`,
            },
            {
                id: 4,
                headerId: 1,
                name: "Вид недрользования",
                link: "SubsoilType",
                path: `api/Reference/Contract/SubsoilType`,
            },
        ],
        headers: [
            [
                // {
                //     text: "ID",
                //     align: "left",
                //     sortable: false,
                //     value: "id",
                // },
                { text: "Организация", value: "organizationName" },
                // { text: "NameRu", value: "nameRu" },
                // { text: "NameKz", value: "nameKz" },
                // { text: "NameEn", value: "nameEn" },
                { text: "Регистрационный номер", value: "registrationNumber" },
                { text: "Дата регистрации", datetime: true, value: "registrationDate" },
                { text: "Дата окончания", value: "expirationDate" },
                // { text: "Тип", value: "contractType.nameRu" },
                { text: "Вид", value: "contractCategory.nameRu" },
                { text: "Группа ПИ", value: "contractMineralGroup.nameRu" },
                { text: "Вид недропользования", value: "contractSubsoilType.nameRu" },
                { text: "Статус", value: "status.nameRu" },
                { text: "Действия", value: "action", sortable: false },
            ],
            [
                // {
                //     text: "ID",
                //     align: "left",
                //     sortable: false,
                //     value: "id",
                // },
                { text: "Символьный код", value: "name" },
                { text: "Код", value: "code" },
                { text: "Наименование", value: "nameRu" },
                // { text: "Наименование (KZ)", value: "nameKz" },
                // { text: "Наименование (EN)", value: "nameEn" },
                { text: "Статус", value: "status.nameRu" },
                { text: "Действия", value: "action", sortable: false },
            ],
        ],
    },
}
const mutations = {
    ...make.mutations(state),
    LOADING: (state, isLoading) => {
        state.loading = isLoading;
    },
    DIALOG: (state, isOpen) => {
        state.dialog = isOpen;
    },
    ERROR: (state, isError) => {
        state.showError = isError
    },
    ERROR_MESSAGE: (state, message) => {
        state.errorMessage = message
    },
    CURRENT_ITEM: (state, item) => {
        state.currentItem = item;
    },
    STATUS: (state, data) => {
        state.optionsStatus.data = data;
    },
    DEVICE: (state, data) => {
        state.optionsDevice.data = data;
    },
    REGION: (state, data) => {
        state.optionsRegion.data = data;
    },
    FACILITY: (state, data) => {
        state.optionsFacility.data = data;
    },
    OPERATIONS: (state, data) => {
        state.optionsOperation.data = data;
    },
    PIPELINE: (state, data) => {
        state.optionsPipeline.data = data;
    },
    PRODUCTION_FACILITY: (state, data) => {
        state.optionsProductionFacility.data = data;
    },
    ORGANIZATIONS: (state, data) => {
        state.optionsOrganization.data = data;
    },
    INDUSTRY_ORGANIZATIONS: (state, data) => {
        state.optionsIndustry.items = data;
    },
    INDUSTRY_ORGANIZATION_ITEM: (state, data) => {
        state.optionsIndustry.item = data;
    },
    PRODUCTION_FACILITY_DATA_BY_ORGANIZATION: (state, data) => {
        state.optionsProductionFacility.dataByOrganization= data;
    },
    INDUSTRY_ORGANIZATION: (state, id) => {
        state.optionsIndustry.item = state.optionsIndustry.items.find(value => value.id == id);
    },
    ORGANIZATION_TYPES: (state, data) => {
        state.optionsOrganizationType.data = data;
    },
    ORGANIZATION_CONTRACTS: (state, data) => {
        state.optionsContract.contracts = data;
    },
    CONTACTS: (state, data) => {
        state.optionsContact.data = data;
    },
    USER: (state, data) => {
        state.optionsUser.data = data;
    },
    ROLE: (state, data) => {
        state.optionsRole.data = data;
    }
}
const actions = {
    setCurrentItem: ({ commit }, item) => {
        commit('CURRENT_ITEM', item)
    },
    openDialog: ({ commit }, isOpen) => {
        commit('DIALOG', isOpen);
    },
    fetchRole: async ({ commit }) => {
        try {
            commit('LOADING', true)
            const path = state.optionsRole.path.base;
            const response = await axios.get(path);
            const data = response.data;
            commit('ROLE', data)
            commit('LOADING', false)
        } catch (e) {
            const message = `${state.errorMessages.reference}: ${e.message}`
            commit('ERROR_MESSAGE', message);
            commit('ERROR', true);
        }
    },
    fetchUser: async ({ commit }) => {
        try {
            commit('LOADING', true)
            const path = state.optionsUser.path.base;
            const response = await axios.get(path);
            const data = response.data;
            commit('USER', data)
            commit('LOADING', false)
        } catch (e) {
            const message = `${state.errorMessages.reference}: ${e.message}`
            commit('ERROR_MESSAGE', message);
            commit('ERROR', true);
        }
    },
    setUserRoles: async ({ commit }) => {
        try {
            commit('LOADING', true)
            //* AspNetUsers - пользователи
            //* AspNetRoles - роли
            //* AspNetUsersRoles - роли пользователя
            // Формируем массив пользователей с ролями
            // С backend-а приходит массив с ролями
            //! По соглашению, пользователь проекта имеет одну роль
            const dataWithAllRoles = state.optionsUser.data.map(user => {
                const newRole = state.optionsRole.data.find(role =>
                    role.id == user.aspNetUserRoles[0].roleId
                )
                user.role = newRole;
                return user;
            })
            commit('USER', dataWithAllRoles)
            commit('LOADING', false)
        } catch (e) {
            const message = `${state.errorMessages.reference}: ${e.message}`
            commit('ERROR_MESSAGE', message);
            commit('ERROR', true);
        }
    },
    excludeUsersWithManagementRoles: async ({ commit }) => {
        try {
            const excludeActionsRoles = ["admin", "manager"];
            commit('LOADING', true)
            const filteredDataWithRoles = state.optionsUser.data.filter(user =>
                !excludeActionsRoles.includes(user.role.name.toLowerCase())
            )
            commit('USER', filteredDataWithRoles)
            commit('LOADING', false)
        } catch (e) {
            const message = `${state.errorMessages.reference}: ${e.message}`
            commit('ERROR_MESSAGE', message);
            commit('ERROR', true);
        }
    },
    excludeManagementRoles: async ({ commit }) => {
        try {
            const excludeActionsRoles = ["admin", "manager"];
            commit('LOADING', true)
            const filteredDataWithRoles = state.optionsRole.data.filter(role =>
                !excludeActionsRoles.includes(role.name.toLowerCase())
            )
            commit('ROLE', filteredDataWithRoles)
            commit('LOADING', false)
        } catch (e) {
            const message = `${state.errorMessages.reference}: ${e.message}`
            commit('ERROR_MESSAGE', message);
            commit('ERROR', true);
        }
    },
    fetchContact: async ({ commit }) => {
        try {
            commit('LOADING', true)
            const path = state.optionsContact.path.base;
            const response = await axios.get(path);
            const data = response.data;
            commit('CONTACTS', data)
            commit('LOADING', false)
        } catch (e) {
            const message = `${state.errorMessages.reference}: ${e.message}`
            commit('ERROR_MESSAGE', message);
            commit('ERROR', true);
        }
    },
    fetchContractByOrganizationId: async ({ commit }) => {
        try {
            commit('LOADING', true)
            const response = await axios.get(`/api/Reference/Contract/${organizationId}`);
            commit('LOADING', false)
            return response.data;
        } catch (e) {
            const message = `${state.errorMessages.reference}: ${e.message}`
            commit('LOADING', false)
            return []; // Вернуть пустой массив в случае ошибки
        }
    },
    fetchRegion: async ({ commit }) => {
        try {
            commit('LOADING', true)
            const path = state.optionsRegion.path.base;
            const response = await axios.get(path);
            const data = response.data;
            commit('REGION', data)
            commit('LOADING', false)
        } catch (e) {
            const message = `${state.errorMessages.reference}: ${e.message}`
            commit('ERROR_MESSAGE', message);
            commit('ERROR', true);
        }
    },
    fetchStatus: async ({ commit }) => {
        try {
            commit('LOADING', true)
            const path = state.optionsStatus.path.base;
            const response = await axios.get(path);
            const data = response.data;
            commit('STATUS', data)
            commit('LOADING', false)
        } catch (e) {
            const message = `${state.errorMessages.reference}: ${e.message}`
            commit('ERROR_MESSAGE', message);
            commit('ERROR', true);
        }
    },
    fetchProductionFacility: async ({ commit }) => {
        try {
            commit('LOADING', true)
            const path = state.optionsProductionFacility.path.base;
            const response = await axios.get(path);
            const data = response.data;
            commit('PRODUCTION_FACILITY', data)
            commit('LOADING', false)
        } catch (e) {
            const message = `${state.errorMessages.reference}: ${e.message}`
            commit('ERROR_MESSAGE', message);
            commit('ERROR', true);
        }
    },
    fetchProductionFacilityOrganizations: async ({ commit }) => {
        try {
            commit('LOADING', true);
            const path = state.optionsOrganization.path.base + state.optionsProductionFacility.path.sub;
            const response = await axios.get(path);
            const data = response.data;
            commit('ORGANIZATIONS', data);
            commit('LOADING', false);
        } catch (e) {
            const message = `${state.errorMessages.organization}: ${e.message}`
            commit('ERROR_MESSAGE', message);
            commit('ERROR', true);
        }
    },
    fetchFacility: async ({ commit }) => {
        try {
            commit('LOADING', true)
            const path = state.optionsFacility.path.base;
            const response = await axios.get(path);
            const data = response.data;
            commit('FACILITY', data)
            commit('LOADING', false)
        } catch (e) {
            const message = `${state.errorMessages.reference}: ${e.message}`
            commit('ERROR_MESSAGE', message);
            commit('ERROR', true);
        }
    },
    fetchOperations: async ({ commit }) => {
        try {
            commit('LOADING', true)
            const path = state.optionsOperation.path.base;
            const response = await axios.get(path);
            const data = response.data;
            commit('OPERATIONS', data)
            commit('LOADING', false)
        } catch (e) {
            const message = `${state.errorMessages.reference}: ${e.message}`
            commit('ERROR_MESSAGE', message);
            commit('ERROR', true);
        }
    },
    fetchDevice: async ({ commit }) => {
        try {
            commit('LOADING', true)
            const path = state.optionsDevice.path.base;
            const response = await axios.get(path);
            const data = response.data;
            commit('DEVICE', data)
            commit('LOADING', false)
        } catch (e) {
            const message = `${state.errorMessages.reference}: ${e.message}`
            commit('ERROR_MESSAGE', message);
            commit('ERROR', true);
        }
    },
    fetchPipelines: async ({ commit }) => {
        try {
            commit('LOADING', true)
            const path = state.optionsPipeline.path.base;
            const response = await axios.get(path);
            const data = response.data;
            commit('PIPELINE', data)
            commit('LOADING', false)
        } catch (e) {
            const message = `${state.errorMessages.reference}: ${e.message}`
            commit('ERROR_MESSAGE', message);
            commit('ERROR', true);
        }
    },
    fetchOrganizations: async ({ commit }) => {
        try {
            commit('LOADING', true);
            const path = state.optionsOrganization.path.base + state.optionsOrganization.path.sub;
            const response = await axios.get(path);
            const data = response.data;
            commit('ORGANIZATIONS', data);
            commit('LOADING', false);
        } catch (e) {
            const message = `${state.errorMessages.organization}: ${e.message}`
            commit('ERROR_MESSAGE', message);
            commit('ERROR', true);
        }
    },
    fetchIndustryOrganizations: async ({ commit }, organizationType = 10) => {
        try {
            commit('LOADING', true);
            state.optionsIndustry.path.organizationType = organizationType;
            // state.optionsIndustry.path.facilityType = facilityType
            const path = state.optionsIndustry.path.base +
                state.optionsIndustry.path.organizationType +
                state.optionsIndustry.path.status;
            const response = await axios.get(path);
            commit('INDUSTRY_ORGANIZATIONS', response.data);
            commit('LOADING', false);
        } catch (e) {
            const message = `${state.errorMessages.organization}: ${e.message}`
            commit('ERROR_MESSAGE', message);
            commit('ERROR', true);
        }
    },
    setIndustryOrganization: ({ commit }, organizationId) => {
        try {
            commit('LOADING', true);
            commit('INDUSTRY_ORGANIZATION', organizationId);
            commit('LOADING', false);
        } catch (e) {
            const message = `${state.errorMessages.organization}: ${e.message}`
            commit('ERROR_MESSAGE', message);
            commit('ERROR', true);
        }
    },
    fetchIndustryOrganizationItem: async ({ commit }, organizationId) => {
        try {
            commit('LOADING', true);
            const path = state.optionsIndustry.path.base + `${organizationId}`
            const response = await axios.get(path);
            commit('INDUSTRY_ORGANIZATION_ITEM', response.data);
            commit('LOADING', false);
        } catch (e) {
            const message = `${state.errorMessages.organization}: ${e.message}`
            commit('ERROR_MESSAGE', message);
            commit('ERROR', true);
        }
    },
    fetchOrganizationTypes: async ({ commit }) => {
        try {
            commit('LOADING', true);
            const path = state.optionsOrganizationType.path.base;
            const response = await axios.get(path);
            const data = response.data;
            commit('ORGANIZATION_TYPES', data);
            commit('LOADING', false);
        } catch (e) {
            const message = `${state.errorMessages.organization}: ${e.message}`
            commit('ERROR_MESSAGE', message);
            commit('ERROR', true);
        }
    },
    fetchProductionFacilitiesByOrganization: async ({ commit }, organizationId, newProductionFacilityOrganizationId) => {
        try {
            commit('LOADING', true);
            const path = state.optionsProductionFacility.path.fullByOrg + `${organizationId}`;
            const response = await axios.get(path);
            const data = response.data;
            commit('PRODUCTION_FACILITY_DATA_BY_ORGANIZATION', data);
            commit('LOADING', false);
        } catch (e) {
            const message = `${state.errorMessages.reference}: ${e.message}`
            commit('ERROR_MESSAGE', message);
            commit('ERROR', true);
        }
    },
    // fetchIndustryOrganizationContracts: async ({ commit }, organizationId) => {
    //     try {
    //         commit('LOADING', true);
    //         const path = state.optionsContract.path.base + `${organizationId}`;
    //         const response = await axios.get(path);
    //         const data = response.data;
    //         commit('ORGANIZATION_CONTRACTS', data);
    //         commit('LOADING', false);
    //     } catch (e) {
    //         const message = `${state.errorMessages.organization}: ${e.message}`
    //         commit('ERROR_MESSAGE', message);
    //         commit('ERROR', true);
    //     }
    // },
}
const getters = {
    getOilOrganizations: state => {
        return state.optionsOrganization.data
    },
    getOrganizations: state => {
        return state.optionsOrganization.data
    },
    getOrganizationTypes: state => {
        return state.optionsOrganizationType.data
    },
    getRegions: state => {
        return state.optionsRegion.data
    },
    getStatus: state => {
        return state.optionsStatus.data
    },
    getFacility: state => {
        return state.optionsFacility.data
    },
    getOperations: state => {
        return state.optionsOperation.data
    },
    getDevice: state => {
        return state.optionsDevice.data
    },
    getPipelines: state => {
        return state.optionsPipeline.data
    },
    getRoles: state => {
        return state.optionsRole.data
    },
    getProductionFacilityByOrganizationList: state => {
        return state.optionsProductionFacility.dataByOrganization
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}