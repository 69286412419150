<template>
  <v-menu
    v-model="showPicker"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
    max-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="rangeValue.value | date"
        :label="rangeValue.label"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="rangeValue.value"
      locale="ru-ru"
      @input="closeDatePicker"
    ></v-date-picker>
  </v-menu>
</template>
<script>
export default {
  name: "CustomerReportDatePicker.vue",
  props: {
    rangeValue: { type: Object },
  },
  data() {
    return {
      showPicker: false,
    };
  },
  methods: {
    closeDatePicker() {
      this.showPicker = false;
    },
  },
};
</script>