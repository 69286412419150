<template>
  <v-container>
    <v-row align="center">
      <!-- Блок временно не используется -->
      <v-col
        v-for="(link, i) in links"
        :key="i"
        class="text-center"
        cols="6"
        md="auto"
      >
        <!-- Закомментировано по требованию Жанарыса 28.05.2021 -->
        <!-- <a
          :href="link.href"
          class="
            text-decoration-none text-uppercase text-caption
            font-weight-regular
          "
          rel="noopener noreferrer"
          target="_blank"
          v-text="link.text"
        /> -->
      </v-col>

      <v-spacer class="hidden-sm-and-down" />

      <v-col cols="12" md="auto">
        <div class="body-1 font-weight-light pt-6 pt-md-0 text-center">
          &copy; {{ new Date().getFullYear() }}
            <a href="https://iacng.kz/" class="text-decoration-none"> АО «Ситуационно-аналитический центр топливно-энергетического комплекса Республики Казахстан»</a>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Links",

  data: () => ({
    links: [
      {
        href: "https://dialog.egov.kz/blogs/2818048/welcome",
        text: "Blog",
      },
      {
        href: "https://www.nitec.kz/index.php/pages/test",
        text: "About Us",
      },
      {
        href: "https://www.nitec.kz/index.php/pages/contacts",
        text: "Contacts",
      },
      {
        href: "#",
        text: "Licenses",
      },
    ],
  }),
};
</script>

<style lang="sass" scoped>
a
  color: inherit !important
</style>
