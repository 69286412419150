import axios from "axios";

const state = {
    authStatus: "",
    token: localStorage.getItem("token") || "",
    organizationId: localStorage.getItem("organizationId") || 0,
    user: {},
    profile: [],
    url: {
        app: {
            home: '/',
            login: 'auth/login',
        },
        api: {
            loginByPassword: 'api/auth/login',
            loginByDigitalSignature: 'api/auth/login/sign',
            logout: 'api/auth/logout',
            refresh: 'api/auth/refresh',
            profile: 'api/account/user/profile',
        }
    },
    action: {
        ShouldLogin: 0,
        AccessDenied: 1,
        Ok: 2,
    },
    errorMessages: '',
    notification: {
        title: "Внимание!",
        text: '',
        show: false,
        ula: false
    },

};

const mutations = {
    auth_request(state) {
        state.authStatus = "loading";
    },
    auth_success(state, { token, user }) {
        state.authStatus = "success";
        state.token = token;
        state.user = user;
    },
    auth_error(state, message) {
        state.authStatus = "error";
        state.errorMessages = message
    },
    logout(state) {
        state.authStatus = "";
        state.token = "";
    },
    SET_PROFILE(state, profile) {
        state.profile = [...profile]
    }
};
const actions = {
    loginByPassword: ({ commit }, userData) => {
        return new Promise((resolve, reject) => {
            commit("auth_request");
            axios
                .post(state.url.api.loginByPassword, {
                    name: userData.username,
                    password: userData.password,
                })
                .then((response) => {
                    const token = response.data.token;
                    const user = { name: response.data.name,
                                    role: response.data.role,
                                    firstLogon: response.data.firstLogon, 
                                    organizationId: response.data.organizationId, 
                                    bin: response.data.bin, 
                                    organizationType: response.data.type };
                    
                    // Сохраняем organizationId в localStorage
                    const organizationId = response.data.organizationId;
                    localStorage.setItem("organizationId", organizationId);

                    // Сохраняем БИН в localStorage
                    const bin = response.data.bin;
                    localStorage.setItem("bin", bin);
                    
                    // Сохраняем тип организации в localStorage
                    const organizationType = response.data.type;
                    localStorage.setItem("organizationType", organizationType)

                    localStorage.setItem("token", token);
                    localStorage.setItem("user", JSON.stringify(user));
                    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
                    commit("auth_success", { token, user });
                    resolve(response);
                })
                .catch((err) => {
                    localStorage.removeItem("token");
                    reject(err);
                });
        });
    },
    loginByDigitalSignature: ({ commit }, cert) => {
        return new Promise((resolve, reject) => {
            commit("auth_request");
            axios
                .post(state.url.api.loginByDigitalSignature, { cert })
                .then((response) => {
                    const token = response.data.token;
                    const user = { name: response.data.name,
                        role: response.data.role,
                        firstLogon: response.data.firstLogon, 
                        organizationId: response.data.organizationId, 
                        bin: response.data.bin, 
                        organizationType: response.data.type };
                    
                    // Сохраняем organizationId в localStorage
                    const organizationId = response.data.organizationId;
                    localStorage.setItem("organizationId", organizationId);
                                        
                    // Сохраняем БИН в localStorage
                    const bin = response.data.bin;
                    localStorage.setItem("bin", bin);
                                                            
                    // Сохраняем тип организации в localStorage
                    const organizationType = response.data.type;
                    localStorage.setItem("organizationType", organizationType)    
                    
                    localStorage.setItem("token", token);
                    localStorage.setItem("user", JSON.stringify(user));
                    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
                    commit("auth_success", { token, user });
                    resolve(response);
                })
                .catch((err) => {
                    localStorage.removeItem("token");
                    reject(err);
                });
        });
    },
    logout({ commit }) {
        window.location.replace(state.url.app.home); // для теста - 01.12.2021
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("item");
        localStorage.removeItem("organizationId");
        localStorage.removeItem("bin");
        localStorage.removeItem("organizationType");
        delete axios.defaults.headers.common["Authorization"];
        return new Promise((resolve, reject) => {
            commit("logout");
            axios
                .get(state.url.api.logout)
                .then((response) => {
                    window.location.replace(state.url.app.home);
                    resolve();
                })
                .catch((err) => {
                    console.log("logout error - ", err);
                    reject(err);
                });
        });
    },
    refreshtoken({ commit }) {
        axios
            .get(state.url.app.refresh)
            .then((response) => {
                const token = response.data.access_token;
                localStorage.setItem("token", token);
                axios.defaults.headers.common["Authorization"] = "Bearer " + token;
                commit("auth_success", { token, });
            })
            .catch((error) => {
                console.log("refresh token error - ", error);
                commit("logout");
                localStorage.removeItem("token");
            });
    },
    authInitialize({ commit }) {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        axios.interceptors.request.use((req) => {
            req.cancelToken = source.token;
            if (localStorage.hasOwnProperty("token")) {
                const token = localStorage.getItem("token");
                req.headers = {
                    Authorization: `Bearer ${token}`,
                };
            }
            return req;
        });
        axios.interceptors.response.use(
            (response) => {
                if (
                    response.data.hasOwnProperty("action") &&
                    response.data.hasOwnProperty("status") &&
                    response.data.hasOwnProperty("text")
                ) {
                    if (response.data.action == state.action.Ok) {
                        if (response.data.status == "Error" && response.data.text != "") {
                            const text = `Ошибка: ${response.data.text}`;
                            // console.log(text);
                            console.log(response);
                            return(response)
                            commit("auth_error", text);
                        }
                        if (response.data.status == "Info" && response.data.text != "") {
                            state.notification.text = response.data.text;
                            state.notification.show = true;
                            // console.log(response);
                        }
                    }
                    if (response.data.action == state.action.ShouldLogin) {
                        const text = `Необходима авторизация: ${response.data.text}`;
                        console.log(text);
                        commit("auth_error", text);
                        localStorage.clear();
                        location.reload();
                        return;
                    }
                    if (response.data.action == state.action.AccessDenied) {
                        const text = `Доступ запрещен: ${response.data.text}`;
                        // console.log(text);
                        // console.log(response)
                        commit("auth_error", text);
                        return(response);
                    }
                }
                return response.data;
            },
            (error) => {
                let text = ''
                if (401 <= error.response.status && error.response.status < 500) {
                    text = `Доступ запрещен: ${response.data.text}, Статус: ${error.response.status}`;
                }
                if (500 <= error.response.status) {
                    text = `Сервис недоступен: ${response.data.text}, Статус: ${error.response.status}`;
                    if (511 === error.response.status) {
                        localStorage.clear();
                        this.$router.push(state.url.app.login);
                    }
                }
                console.log(text);
                commit("auth_error", text);
                return;
            }
        );
    },
    notification: async ({ dispatch }, data) => {
        state.notification.text = data
        state.notification.show = true
        // dispatch('app/notification', data, { root: true })
        //this.localeChanged(this.$i18n.locale);
    },
    getProfile: async ({ commit }) => {
        try {
            const { data } = await axios.get(state.url.api.profile)
            commit("SET_PROFILE", data);
        } catch (error) {
            console.log("При запросе профиля пользователя произошла ошибка - ", error);
        }
    }

};
const getters = {
    authorized: (state) => !!state.token,
    authstatus: (state) => state.authStatus,
    userRole: (state) => (state.user ? state.user.role : null),
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
