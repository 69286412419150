<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn outlined color="white" small v-bind="attrs" v-on="on" class="mr-2">
        {{ title }}
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-for="(item, index) in interval" :key="index" class="tile">
        <v-list-item-title @click="handler(index)">{{
          item
        }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  name: "ChartFilterMenu",
  props: {
    title: {
      type: String,
    },
    interval: {
      type: Array,
    },
    handler: {
      type: Function,
    },
  },
};
</script>