<template>
  <v-row justify="space-around">
    <v-col cols="6" align="center">
      <span class="icon">
        <v-icon x-large>{{ reportKpi.numberOfDays.icon }}</v-icon>
      </span>
      <v-spacer></v-spacer>
      <span class="mr-2 font-weight-black text-md-h2">
        {{ reportKpi.numberOfDays.value.toLocaleString() }}
      </span>
      <span class="text-lowercase">
        {{ reportKpi.numberOfDays.text }}
      </span>
    </v-col>
    <v-col cols="6" align="center">
      <span class="icon">
        <v-icon x-large>{{ reportKpi.oilProduction.icon }}</v-icon>
      </span>
      <v-spacer></v-spacer>
      <span class="mr-2 font-weight-black text-md-h2">
        {{ reportKpi.oilProduction.value.toLocaleString() }}
      </span>
      <span class="text-lowercase">
        {{ reportKpi.oilProduction.text }}
      </span>
    </v-col>
  </v-row>
</template>

<script>
import { Vue } from "vue-property-decorator";
import { get } from "vuex-pathify";
export default Vue.extend({
  name: "CustomerReportKPIBar.vue",
  computed: {
    ...get("report", ["reportKpi"]),
  },
});
</script>