<template>
  <p class="font-weight-bold mt-6 text--accent-1">
    {{ title.toUpperCase() }}
  </p>
</template>

<script>
export default {
  name: "ChartTitle.vue",
  components: {},
  props: {
    title: String,
    subtitle: String,
  },
};
</script>