<template>
  <v-toolbar flat>
    <v-toolbar-title>{{ title }}</v-toolbar-title>
    <v-divider class="mx-5" inset vertical></v-divider>
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Поиск"
      single-line
      hide-details
    ></v-text-field>
    <v-divider class="mx-5" inset vertical></v-divider>
    <v-btn color="primary" dark class="mb-2" @click="open">
      {{ createItemTitle }}
    </v-btn>
    <v-divider class="mx-5" inset vertical></v-divider>   
    <div>
      <v-btn-toggle>
          <v-btn color="secondary" dark @click="exportTable()">
              <v-icon>mdi-download</v-icon>
          </v-btn>
      </v-btn-toggle>
    </div>  
    <v-dialog
      v-if="isVisible"
      v-model="openDialog"
      max-width="800px"
      persistent
    >
      <!-- <template v-slot:activator="{ on }">
        <v-btn color="primary" dark class="mb-2" v-on="on">
          {{ createItemTitle }}
        </v-btn>
      </template> -->
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <!--Форма для создания / редактирования объекта-->
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <slot name="formCreateEdit"></slot>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">Отменить</v-btn>
          <v-btn color="blue darken-1" text @click="save">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-toolbar>
</template>
<script>
import { sync } from "vuex-pathify";
export default {
  name: "ReferenceToolBar.vue",
  props: {
    title: {
      type: String,
      default: ""
    },
    createItemTitle: {
      type: String,
      default: ""
    },
    formTitle: {
      type: String,
      default: ""
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    search: sync("reference/search"),
    isVisible: () =>
      JSON.parse(localStorage.getItem("user")).role.toLowerCase() == "admin" ||
      JSON.parse(localStorage.getItem("user")).role.toLowerCase() == "manager"
        ? true
        : false
  },
  watch: {
    dialog(val) {
      this.openDialog = val; //? true : false;
    }
  },
  data() {
    return {
      openDialog: false
    };
  },
  methods: {
    open() {
      this.$emit("open");
    },
    close() {
      this.openDialog = false;
      this.$emit("close");
    },
    save() {
      this.$emit("save");
    },
    exportTable() {
      this.$emit("export-table");
    }
  }
};
</script>
