<template>
  <v-dialog v-model="showULA" max-width="67%">
    <v-card>
      <v-card-text>
        <pdf
          v-for="i in numPages"
          :key="i"
          :src="src"
          :page="i"
          style="display: inline-block; width: 100%"
        ></pdf>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn text @click="close">Принять соглашение</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import pdf from "vue-pdf";
var loadingTask = pdf.createLoadingTask("/downloads/isun-ula.pdf");

export default {
  name: "ULA",
  components: {
    pdf,
  },
  props: {},
  data() {
    return {
      src: loadingTask,
      numPages: undefined,
      user: {},
      showULA: false,
    };
  },
  methods: {
    close() {
      this.showULA = false;
    },
  },
  created() {
    if (localStorage.hasOwnProperty("user")) {
      this.user = JSON.parse(localStorage.getItem("user"));
      this.showULA = this.user.firstLogon;
      this.src.promise.then((pdf) => {
        this.numPages = pdf.numPages;
      });
    }
  },
};
</script>