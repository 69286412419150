<template>
  <v-btn outlined color="white" small v-on="$listeners" v-bind="$attrs">
    {{ title }}
  </v-btn>
</template>
<script>
export default {
  name: "ChartFilterButton",
  props: {
    title: {
      type: String,
      default: "Период",
    },
  },
};
</script>