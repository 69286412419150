<template>
  <v-tabs
    class="elevation-2"
    dark
    :grow="true"
    show-arrows
    v-model="activeItem"
  >
    <v-tabs-slider :color="colorSlider"></v-tabs-slider>
    <v-tab
      v-for="tab in tabs"
      :key="tab.id"
      :href="'#' + `tab-${tab.link}`"
      @click="selectTab(tab)"
      :style="{ color: colorText }"
    >
      {{ tab.name }}
    </v-tab>
  </v-tabs>
</template> 
<script>
import { ORGANIZATION_TYPES } from '@/util/globals';

export default {
  name: "IndustryCategory",
  props: {
    tabs: { type: Array },
    currentOrganizationType: { type: Number, default: 0 },
  },
  data() {
    return {
      activeItem: 0,
      colorText: "#BBDEFB",
      colorSlider: "#BBDEFB",
      currentTab: null,
      organizationType: ORGANIZATION_TYPES.Produce, // Значение по умолчанию
    };
  },
  methods: {
    selectTab(tab) {
    if (this.currentTab !== tab) {
      this.currentTab = tab;
      // Здесь происходит изменение типа организации
      this.organizationType = tab.organizationType;
      this.$emit("select-tab", this.currentTab);
      }
    },
  },
  mounted() {
    this.currentTab = this.tabs[0];
  },
};
</script>