<template>
  <div v-if="loaderFlag">
    <loader
      :object="loaderStyle.object"
      :color1="loaderStyle.color1"
      :color2="loaderStyle.color2"
      :size="loaderStyle.size"
      :speed="loaderStyle.speed"
      :bg="loaderStyle.bgcolor"
      :objectbg="loaderStyle.objectbg"
      :opacity="loaderStyle.opacity"
      :name="loaderStyle.name"
      :disableScrolling="loaderStyle.disableScrolling"
    ></loader>
  </div>
</template>
<script>
export default {
  name: "LoaderPage",
  props: {
    loaderDuration: {
      type: String,
      default: "2",
    },
  },
  data: () => ({
    loaderStyle: {
      object: "#ff9633",
      color1: "#ffffff",
      color2: "#17fd3d",
      opacity: 80,
      bg: "#343a40",
      objectbg: "#999793",
      size: 5,
      speed: 2,
      name: "box",
      disableScrolling: false,
    },
    loaderFlag: true,
  }),
  mounted() {
    setTimeout(() => {
      this.loaderFlag = false;
    }, this.loaderDuration * 1000);
  },
};
</script>