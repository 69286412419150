<template>
  <v-container fluid>
    <v-slide-y-transition mode="out-in">
      <v-row no-gutters>
        <v-col>
          <v-card class="pa-2 ma-2" outlined tile>
            <!-- toolbar -->
            <reference-tool-bar
              :title="title"
              :editedIndex="editedIndex"
              :createItemTitle="createTitle"
              :formTitle="formTitle"
              :dialog="dialog"
              @save="save"
              @close="close"
              @open="open"
            >
              <template v-slot:formCreateEdit>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="editedItem.name"
                    label="Name"
                    :hint="`Допускаются только буквы`"
                    :rules="[rules.required, rules.min, rules.alfabetEn]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="editedItem.code"
                    label="Code"
                    :hint="`Допускаются только цифры`"
                    :rules="[rules.required, rules.dig]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="editedItem.nameRu"
                    label="NameRu"
                    :hint="`Допускаются только буквы`"
                    :rules="[rules.required, rules.min, rules.alfabetRu]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="editedItem.nameKz"
                    label="NameKz"
                    :hint="`Допускаются только буквы`"
                    :rules="[rules.required, rules.min, rules.alfabetRu]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="editedItem.nameEn"
                    label="NameEn"
                    :hint="`Допускаются только буквы`"
                    :rules="[rules.required, rules.min, rules.alfabetEn]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-select
                    v-model="statusSelect"
                    :hint="`Идентификатор статуса - [${statusSelect.id}]`"
                    :items="statusList"
                    item-text="nameRu"
                    item-value="id"
                    label="Статус"
                    persistent-hint
                    return-object
                    single-line
                  >
                  </v-select>
                </v-flex>
              </template>
            </reference-tool-bar>
            <!--Таблица с данными-->
            <reference-table
              :headers="headers"
              :items="items"
              @edit-item="editItem($event)"
              @remove-item="removeItem($event)"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
import { Vue } from "vue-property-decorator";
import { get } from "vuex-pathify";
import { mapActions } from "vuex";
import { rules } from "@/util/rules";

export default Vue.extend({
  name: "ContractCategoryItemSecondary",
  components: {
    ReferenceTable: () => import("@/components/reference/ReferenceTable.vue"),
    ReferenceToolBar: () =>
      import("@/components/reference/ReferenceToolBar.vue")
  },
  props: {
    title: {
      type: String
    },
    headers: {
      type: Array
    },
    path: {
      type: String
    }
  },
  computed: {
    statusList: get("reference/getStatus"),
    formTitle() {
      return this.editedIndex === -1 ? "Создать" : "Редактировать";
    }
  },
  data() {
    return {
      rules,
      dialog: false,
      editedIndex: -1,
      statusSelect: 0,
      items: [],
      createTitle: "Создать новый элемент",
      editedItem: {
        id: 0,
        name: "",
        code: "",
        nameRu: "",
        nameKz: "",
        nameEn: ""
      },
      defaultItem: {
        id: 0,
        name: "",
        code: "",
        nameRu: "",
        nameKz: "",
        nameEn: ""
      }
    };
  },
  methods: {
    ...mapActions({
      fetchStatus: "reference/fetchStatus"
    }),
    open() {
      this.dialog = true;
    },
    initialize() {
      this.fetchStatus().then(result => {
        this.statusSelect = this.statusList[0];
      });
      (async () => {
        const { data } = await this.$http.get(this.path + "/0");
        this.items = data;
      })();
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.statusSelect = this.statusList[0];
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      const model = {
        statusId: this.statusSelect.id,
        id: this.editedItem.id,
        name: this.editedItem.name,
        code: this.editedItem.code,
        nameRu: this.editedItem.nameRu,
        nameKz: this.editedItem.nameKz,
        nameEn: this.editedItem.nameEn
      };
      if (this.editedIndex > -1) {
        this.$http.put(this.path, model).then(response => {
          if (response.status === "Ok") this.initialize();
        });
      } else {
        model.id = 0;
        this.$http.post(this.path, model).then(response => {
          if (response.status === "Ok") this.initialize();
        });
      }
      this.close();
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.statusSelect = { id: item.status.id, name: item.status.NameRu };
      this.open();
    },
    removeItem(item) {
      if (confirm("Are you sure you want to delete this item?")) {
        const model = Object.assign({}, item);
        this.$http.delete(this.path, { data: model }).then(response => {
          if (response.status === "Ok") this.initialize();
        });
      }
    }
  },
  created() {
    this.initialize();
  }
});
</script>
