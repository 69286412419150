<template>
  <v-container fluid>
    <v-data-iterator
      :items="items"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :search="search"
      :sort-by="sortBy.toLowerCase()"
      :sort-desc="sortDesc"
      hide-default-footer
    >
      <template v-slot:header>
        <v-toolbar dark color="grey darken-3" class="mb-1">
          <v-text-field
            v-model="search"
            clearable
            flat
            solo-inverted
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Поиск"
          ></v-text-field>
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-spacer></v-spacer>
            <v-select
              v-model="sortBy"
              flat
              solo-inverted
              hide-details
              :items="filteredKeys"
              prepend-inner-icon="mdi-magnify"
              label="Сортировать по"
            ></v-select>
            <v-spacer></v-spacer>
            <v-btn-toggle v-model="sortDesc" mandatory>
              <v-btn large depressed color="grey" :value="false">
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn large depressed color="grey" :value="true">
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
            </v-btn-toggle>
          </template>
        </v-toolbar>
      </template>

      <template v-slot:default="props">
        <v-row justify="space-around">
          <v-col
            v-for="(item, index) in props.items"
            :key="index"
            cols="12"
            sm="6"
            md="6"
            lg="6"
          >
            <v-card>
              <v-card-title class="text-h4">
                {{ item.name }}
              </v-card-title>

              <v-divider></v-divider>

              <v-list dense>
                <v-list-item v-for="(key, index) in filteredKeys" :key="index">
                  <v-list-item-content
                    :class="{ 'blue--text': sortBy === key }"
                  >
                    {{ item[key.toLowerCase()].name }}:
                  </v-list-item-content>
                  <v-list-item-content
                    class="text-wrap d-flex justify-end"
                    :class="{ 'blue--text': sortBy === key }"
                  >
                    {{ item[key.toLowerCase()].value }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <template v-slot:footer>
        <v-row class="mt-2 ml-5" align="center" justify="center">
          <span class="grey--text">Элементов на страницу</span>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                text
                color="primary"
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                {{ itemsPerPage }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(number, index) in itemsPerPageArray"
                :key="index"
                @click="updateItemsPerPage(number)"
              >
                <v-list-item-title>{{ number }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-spacer></v-spacer>

          <span class="mr-4 grey--text">
            Страниц {{ page }} из {{ numberOfPages }}
          </span>
          <v-btn
            fab
            dark
            color="grey darken-3"
            class="mr-1"
            @click="formerPage"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn fab dark color="grey darken-3" class="mx-5" @click="nextPage">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>
<script>
import { getProductionFacilitiesByOrganizationId } from "@/api/reference.api.js";

export default {
  name: "ItemProductionFacility",
  data() {
    return {
      itemsPerPageArray: [2, 3, 4, 5, 6, 7, 8],
      search: "",
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 2,
      sortBy: "name",
      keys: [
        "Name",
        // "Id",
        // "Code",
        // "Kilometer",
        "OilPipeline",
        "OperationType",
        // "OrganizationId",
        "Region",
        "Latitude",
        "Longitude",
        "Status",
      ],
      items: [],
    };
  },

  computed: {
    numberOfPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
    filteredKeys() {
      return this.keys.filter((key) => key !== "Name");
    },
  },
  methods: {
    async initialize() {
      const organizationId = localStorage.getItem('organizationId');
      const data = await getProductionFacilitiesByOrganizationId(organizationId);
      this.transformData(data);
    },
    transformData(data) {
      const result = data.map((item) => {
        const itemObj = {
          name: item.nameRu,
          // code: {
          //   name: "Код",
          //   value: item.code,
          // },
          // id: {
          //   name: "Идентификатор",
          //   value: item.id,
          // },
          // kilometer: {
          //   name: "Километер",
          //   value: item.kilometer,
          // },
          oilpipeline: {
            name: "Нефтепровод",
            value: item.oilPipeline.nameRu,
          },
          operationtype: {
            name: "Тип операции",
            value: item.operationType.name,
          },
          // organizationid: {
          //   name: "Организация",
          //   value: item.organizationId,
          // },
          region: {
            name: "Регион",
            value: item.region.nameRu,
          },
          latitude: {
            name: "Широта",
            value: item.latitude,
          },
          longitude: {
            name: "Долгота",
            value: item.longitude,
          },
          status: {
            name: "Статус",
            value: item.status.nameRu,
          },
        };

        return itemObj;
      });
      this.items = result;
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
  },
  async mounted() {
    await this.initialize();
  },
};
</script>