<template>
  <div
    ref="customControl"
    class="ol-unselectable ol-control"
    style="right: 0.6em; top: 3.5em"
  >
    <div
      id="settings-wrapper"
      style="height: 2.1em; width: 1.375em; margin: 1px; padding: 0"
    >
      <v-card
        id="settings"
        color="rgba(0,60,136,0.5)"
        dark
        flat
        link
        style="
          border-radius: 8px;
          z-index: 1;
          text-align: center;
          font-weight: bold;
          font-size: 1.375em;
        "
      >
        <!-- <span
          style="height: 1.375em; width: 1.375; line-height: 0.4em"
          class="mb-1"
        > -->
        S
        <!-- </span> -->
        <!-- mdi-cog -->
      </v-card>
    <div
      ref="connectionControl"
      class="ol-unselectable ol-control"
      style="right: 0em; top: 3.5em"
    >
      <div
        id="connection-wrapper"
        style="height: 2.1em; width: 1.375em; margin: 1px; padding: 0"
      >
      <v-card
        id="connection"
        color="rgba(0,60,136,0.5)"
        dark
        flat
        link
        style="
          border-radius: 8px;
          z-index: 1;
          text-align: center;
          font-weight: bold;
          font-size: 1.375em;
        "
      >
        <!-- <span
          style="height: 1.375em; width: 1.375; line-height: 0.4em"
          class="mb-1"
        > -->
        C
        <!-- </span> -->
        <!-- mdi-cog -->
      </v-card>
      </div>
      
      </div>
      <div
      ref="helpControl"
      class="ol-unselectable ol-control"
      style="right: 0em; top: 7em"
    >
      <div
        id="help-wrapper"
        style="height: 2.1em; width: 1.375em; margin: 1px; padding: 0"
      >
      <v-card
        id="help"
        color="rgba(0,60,136,0.5)"
        dark
        flat
        link
        style="
          border-radius: 8px;
          z-index: 1;
          text-align: center;
          font-weight: bold;
          font-size: 1.375em;
        "
      >
        <!-- <span
          style="height: 1.375em; width: 1.375; line-height: 0.4em"
          class="mb-1"
        > -->
        H
        <!-- </span> -->
        <!-- mdi-cog -->
      </v-card>
      </div>
      
      </div>
      <v-menu
        v-model="connectionMenu"
        :close-on-content-click="false"
        attach="#connection-wrapper"
        activator="#connection"
        bottom
        content-class="v-connection"
        left
        nudge-left="15"
        offset-x
        origin="top right"
        transition="scale-transition"
        max-width="400"
      >
      <v-card class="text-center mb-0" width="320">
          <v-card-text>
            <strong class="text-uppercase mb-3 d-inline-block"
              >Подключение</strong
            >
            <v-divider class="my-4 secondary" />
            <v-row align="center" no-gutters>
              <v-col cols="auto">Подключенные - Передают</v-col>
              <v-spacer />
              <v-col cols="auto">
                <v-switch
                  v-model="green"
                  class="ma-0 pa-0"
                  color="primary"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-divider class="my-4 secondary" />
            <v-row align="center" no-gutters>
              <v-col cols="auto">Подключенные - Не передают</v-col>
              <v-spacer />
              <v-col cols="auto">
                <v-switch
                  v-model="red"
                  class="ma-0 pa-0"
                  color="primary"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-divider class="my-4 secondary" />
            <v-row align="center" no-gutters>
              <v-col cols="auto">На Подключении</v-col>
              <v-spacer />
              <v-col cols="auto">
                <v-switch
                  v-model="yellow"
                  class="ma-0 pa-0"
                  color="primary"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-divider class="my-4 secondary" />
            <v-row align="center" no-gutters>
              <v-col cols="auto">Не Подключенные</v-col>
              <v-spacer />
              <v-col cols="auto">
                <v-switch
                  v-model="grey"
                  class="ma-0 pa-0"
                  color="primary"
                  hide-details
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-menu>
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        attach="#settings-wrapper"
        activator="#settings"
        bottom
        content-class="v-settings"
        left
        nudge-left="15"
        offset-x
        origin="top right"
        transition="scale-transition"
        max-width="400"
      >
        <v-card class="text-center mb-0" width="320">
          <v-card-text>
            <strong class="text-uppercase mb-3 d-inline-block"
              >Визуализация</strong
            >
            <v-divider class="my-4 secondary" />
            <v-row align="center" no-gutters>
              <v-col cols="auto">Нефтепроводы</v-col>
              <v-spacer />
              <v-col cols="auto">
                <v-switch
                  v-model="pipeline"
                  class="ma-0 pa-0"
                  color="primary"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-divider class="my-4 secondary" />
            <v-row align="center" no-gutters>
              <v-col cols="auto">Добыча</v-col>
              <v-spacer />
              <v-col cols="auto">
                <v-switch
                  v-model="deposit"
                  class="ma-0 pa-0"
                  color="primary"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-divider class="my-4 secondary" />
            <v-row align="center" no-gutters>
              <v-col cols="auto">Переработка</v-col>
              <v-spacer />
              <v-col cols="auto">
                <v-switch
                  v-model="facility"
                  class="ma-0 pa-0"
                  color="primary"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-divider class="my-4 secondary" />
            <v-row align="center" no-gutters>
              <v-col cols="auto">Транспортировка</v-col>
              <v-spacer />
              <v-col cols="auto">
                <v-switch
                  v-model="transport"
                  class="ma-0 pa-0"
                  color="primary"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-divider class="my-4 secondary" />
            <v-row align="center" no-gutters>
              <v-col cols="auto">Экспорт</v-col>
              <v-spacer />
              <v-col cols="auto">
                <v-switch
                  v-model="oilexport"
                  class="ma-0 pa-0"
                  color="primary"
                  hide-details
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-menu>
      <!-- my menu -->
      <v-menu
        v-model="helpMenu"
        :close-on-content-click="false"
        attach="#help-wrapper"
        activator="#help"
        bottom
        content-class="v-help"
        left
        nudge-left="15"
        offset-x
        origin="top right"
        transition="scale-transition"
        max-width="400"
      >
        <v-card class="text-center mb-0" width="320">
          <v-card-text>
            <strong class="text-uppercase mb-3 d-inline-block"
              >Справка</strong
            >
            <v-divider class="my-4 secondary" />
            <v-row align="center" no-gutters>
              <v-col cols="1" align-self="start"><img src="@/assets/img/marker-oil-produce-red.png" width="20"></v-col>
              <v-spacer />
              <v-col cols="11" align-self="start">Подключенная, но не передающая данные НДО</v-col>
            </v-row>
            <v-divider class="my-4 secondary" />
            <v-row align="1" no-gutters>
              <v-col cols="1" align-self="start"><img src="@/assets/img/marker-oil-produce-yellow.png" width="20"></v-col>
              <v-spacer />
              <v-col cols="11">На подключении</v-col>
            </v-row>
            <v-divider class="my-4 secondary" />
            <v-row align="center" no-gutters>
              <v-col cols="1" align-self="start"><img src="@/assets/img/marker-oil-produce-green.png" width="20"></v-col>
              <v-spacer />
              <v-col cols="11">Подключенная и передающая данные НДО</v-col>
            </v-row>
            <v-divider class="my-4 secondary" />
            <v-row align="center" no-gutters>
              <v-col cols="1" align-self="start"><img src="@/assets/img/marker-oil-produce-grey.png" width="20"></v-col>
              <v-spacer />
              <v-col cols="11">Не подключенное НДО</v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-menu>
      
    </div>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";

export default {
  name: "MapSettings",
  data: () => ({
    menu: false,
    connectionMenu: false,
    helpMenu: false,
  }),
  computed: {
    ...sync("map", [
      "show@facility",
      "show@deposit",
      "show@pipeline",
      "show@oilexport",
      "show@transport",
      "show@grey",
      "show@red",
      "show@yellow",
      "show@green"
    ])
  }
};
</script>

<style lang="sass">
.v-settings
  .v-item-group > *
    cursor: pointer

  &__item
    border-width: 3px
    border-style: solid
    border-color: transparent !important

    &--active
      border-color: #00cae3 !important
</style>
