import Vue from 'vue'
import { sync } from 'vuex-router-sync'
import App from './App.vue'

import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins'

import Dialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'

Vue.use(Dialog)

Vue.config.productionTip = false

sync(store, router)

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App),
}).$mount('#app')