var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Поиск","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"search":_vm.search,"footer-props":{
        itemsPerPageAllText: 'Все',
        itemsPerPageText: 'Строк на страницу'
      }},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" из "+_vm._s(items.itemsLength)+" ")]}},{key:"item.date",fn:function(ref){
      var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("date")(item.date)))])]}},{key:"item.dateTime",fn:function(ref){
      var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("date")(item.date)))])]}},{key:"item.mass",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.mass.toLocaleString())+" ")]}},{key:"item.temperature",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.temperature == null ? 'null' : item.temperature.toFixed(2))+" ")]}},{key:"item.density",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.density.toFixed(2))+" ")]}},{key:"item.volume",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.volume.toFixed(2))+" ")]}},{key:"item.begin",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.begin))+" ")]}},{key:"item.end",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.end))+" ")]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.status == false ? "Открыт" : "Закрыт")+" ")]}}],null,true)},[_c('v-progress-linear',{attrs:{"color":"blue","indeterminate":""},scopedSlots:_vm._u([{key:"progress",fn:function(){return undefined},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }