<template>
  <v-container class="lighten-5 mb-6 mx-0 px-0" fluid>
    <default-section-title
      title="ДОБЫЧА СЫРОЙ НЕФТИ"
      icon="mdi-account-hard-hat"
    />
    <v-row>
      <v-col>
        <apex-default-chart
          title="Сдача в нефтепровод"
          direction="process"
          type="line"
          :actions="actions.produce"
        />
      </v-col>
    </v-row>
    <default-section-title
      title="ТРАНСПОРТИРОВКА СЫРОЙ НЕФТИ"
      icon="mdi-swap-horizontal-circle-outline"
    />
    <v-row>
      <v-col cols="12" max-width="50%">
        <apex-default-chart
          title="Прием в нефтепровод"
          direction="process"
          type="bar"
          :actions="actions.transport"
        />
      </v-col>
      <v-col cols="12" max-width="50%">
        <apex-default-chart
          title="Сдача с нефтепровода"
          direction="shipment"
          type="bar"
          :actions="actions.transport"
        />
      </v-col>
    </v-row>
    <default-section-title
      title="ПЕРЕРАБОТКА/ЭКСПОРТ сырой НЕФТИ"
      icon="mdi-layers-outline"
    />
    <v-row>
      <!-- убрано по рребованию Жанарыса - 06.09.2021 -->
      <!-- <v-col cols="12" max-width="50%">
        <apex-default-chart
          title="Прием на НПЗ"
          direction="shipment"
          type="line"
        />
      </v-col> -->
      <v-col cols="12" max-width="50%">
        <apex-extended-chart
          title="Прием на НПЗ / Экспорт"
          direction="shipment"
          type="line"
          :actions="actions.refinery"
        />
      </v-col>
      <!-- убрано по рребованию Жанарыса - 06.09.2021 -->
      <!-- <v-col cols="6" max-width="50%">
        <apex-default-chart
          title="Хранение на НПЗ"
          direction="shipment"
          type="column"
        />
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import { Vue } from "vue-property-decorator";

export default Vue.extend({
  components: {
    ApexDefaultChart: () => import("@/components/chart/ApexBaseChart.vue"),
    ApexExtendedChart: () => import("@/components/chart/ApexExtendedChart.vue"),
    DefaultSectionTitle: () => import("@/components/SectionTitle.vue")
  },

  data() {
    return {
      actions: {
        produce: [
          {
            icon: "mdi-finance",
            text: "Смотреть отчеты...",
            link: "/app/report/produce"
          }
        ],
        transport: [
          {
            icon: "mdi-finance",
            text: "Смотреть отчеты...",
            link: "/app/report/transport"
          }
        ],
        refinery: [
          {
            icon: "mdi-finance",
            text: "Смотреть отчеты - Прием на НПЗ",
            link: "/app/report/refinery"
          },
          {
            icon: "mdi-finance",
            text: "Смотреть отчеты - Экспорт",
            link: "/app/report/transport",
            tab: {
              id: 4,
              name: "Экспорт",
              link: "4",
              disabled: false
            }
          }
        ]
      }
    };
  }
});
</script>
