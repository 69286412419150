var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.filteredHeaders,"items":_vm.items,"loading":_vm.loading,"search":_vm.search,"items-per-page":_vm.itemsPerPage,"footer-props":{
    itemsPerPageAllText: 'Все',
    itemsPerPageText: 'Строк на страницу',
  }},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" из "+_vm._s(items.itemsLength)+" ")]}},{key:"item.datetime",fn:function(ref){
  var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("date")(item.datetime)))])]}},{key:"item.registrationDate",fn:function(ref){
  var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("date")(item.registrationDate)))])]}},{key:"item.expirationDate",fn:function(ref){
  var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("date")(item.expirationDate)))])]}},(_vm.isVisible)?{key:"item.action",fn:function(ref){
  var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil-outline")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.removeItem(item)}}},[_vm._v("mdi-delete")])]}}:null,{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.reset}},[_vm._v("Reset")])]},proxy:true}],null,true)},[_c('v-progress-linear',{attrs:{"color":"blue","indeterminate":""},scopedSlots:_vm._u([{key:"progress",fn:function(){return undefined},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }