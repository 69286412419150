    <template>
    <v-data-table
      :headers="filteredHeaders"
      :items="items"
      :loading="loading"
      :search="search"
      :items-per-page="itemsPerPage"
      class="elevation-1"
      :footer-props="{
        itemsPerPageAllText: 'Все',
        itemsPerPageText: 'Строк на страницу',
      }"
    >
      <v-progress-linear
        v-slot:progress
        color="blue"
        indeterminate
      ></v-progress-linear>
      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }} из {{ items.itemsLength }}
      </template>

      <template v-slot:item.datetime="{ item }">
        <td>{{ item.datetime | date }}</td>
      </template>
      <template v-slot:item.registrationDate="{ item }">
        <td>{{ item.registrationDate | date }}</td>
      </template>
      <template v-slot:item.expirationDate="{ item }">
        <td>{{ item.expirationDate | date }}</td>
      </template>
      <template v-slot:item.action="{ item }" v-if="isVisible">
        <v-icon small class="mr-2" @click="editItem(item)"
          >mdi-pencil-outline</v-icon
        >
        <v-icon small @click="removeItem(item)">mdi-delete</v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="reset">Reset</v-btn>
      </template>
    </v-data-table>
  </template>
  <script>
  import { get } from "vuex-pathify";
  export default {
    name: "ReferenceTable.vue",
    props: {
      headers: {
        type: Array,
        required: true,
      },
      items: {
        type: Array,
        required: true,
      },
      itemsPerPage: {
        type: Number,
        default: 10,
      },
    },
    data() {
      return {
        filteredHeaders: [],
      };
    },
    computed: {
      search: get("reference/search"),
      loading: get("reference/loading"),
      isVisible: () =>
        JSON.parse(localStorage.getItem("user")).role.toLowerCase() == "admin" ||
        JSON.parse(localStorage.getItem("user")).role.toLowerCase() == "manager"
          ? true
          : false,
    },
    methods: {
      initialize() {
        if (this.isVisible == false) {
          this.filteredHeaders = this.headers.filter(
            (item) => item.value != "action"
          );
        } else {
          this.filteredHeaders = this.headers;
        }
      },
      reset() {
        this.$emit("reset");
      },
      editItem(item) {
        this.$emit("edit-item", item);
      },
      removeItem(item) {
        this.$emit("remove-item", item);
      },
    },
    mounted() {
      this.initialize();
    },
  };
  </script>