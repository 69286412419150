//! OpenLayer
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
// import OSM from "ol/source/OSM";
import LayerGroup from "ol/layer/Group";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Fill, Stroke, Style, Text, Circle, Icon } from "ol/style";
import GeoJSON from "ol/format/GeoJSON";
import { fromLonLat } from "ol/proj";
import { FullScreen, Control, ZoomToExtent, ScaleLine, defaults as defaultControls } from 'ol/control';

//! Local data
import dataCountry from "@/data/map/Country.json";
import dataContractTerritories from "@/data/map/ContractTerritories.json";
import dataPipeline from "@/data/map/Pipeline.json";
import { Overlay } from "ol";

//! Map options
const data = {
    country: dataCountry,
    contract: dataContractTerritories,
    deposit: [],
    pipeline: dataPipeline,
    facility: []
}
const path = {
    tile: "https://api.maptiler.com/tiles/satellite/{z}/{x}/{y}.jpg?key=NzqXIvKy1e4I3l47IIYi",
    geo: "api/Graphs/GetMapGeoData",
}
const projection = "EPSG:3857";
const defaultExtent = [3728245.25310963, 3353069.02887892, 10084178.830578446, 9014225.251928212];
const countryCoordinates = fromLonLat([65.32606, 47.41037])
// const countryCoordinates = fromLonLat([70.11, 48.481])
const maxZoom = 10;
const style = {
    default: new Style({
        fill: null,
        stroke: new Stroke({
            color: "cyan",
            width: 3,
        }),
    }),
    country: new Style({
        fill: new Fill({
            color: "rgba(255, 255, 235, 0.1)",
            weight: 4,
        }),
        stroke: new Stroke({
            color: "#1240ab",
            width: 3,
        }),
    }),
    contract: new Style({
        fill: new Fill({
            color: "rgba(111, 112, 113, 0.3)",
            weight: 4,
        }),
        stroke: new Stroke({
            color: "red",
            width: 3,
        }),
    }),
    /** Новые стили по ТЗ декабрь 2022 **/
    markerOilProduceRed: new Style({
        image: new Icon({
            offset: [0, 0],
            anchor: [0, 0],
            size: [400, 400],
            opacity: 1,
            scale: 0.05,
            src: require("@/assets/img/marker-oil-produce-red.png"),
        }),
    }),
    markerOilProduceYellow: new Style({
        image: new Icon({
            offset: [0, 0],
            anchor: [0, 0],
            size: [400, 400],
            opacity: 1,
            scale: 0.05,
            src: require("@/assets/img/marker-oil-produce-yellow.png"),
        }),
    }),
    markerOilProduceGreen: new Style({
        image: new Icon({
            offset: [0, 0],
            anchor: [0, 0],
            size: [400, 400],
            opacity: 1,
            scale: 0.05,
            src: require("@/assets/img/marker-oil-produce-green.png"),
        }),
    }),
    markerOilProduceGrey: new Style({
        image: new Icon({
            offset: [0, 0],
            anchor: [0, 0],
            size: [400, 400],
            opacity: 1,
            scale: 0.05,
            src: require("@/assets/img/marker-oil-produce-grey.png"),
        }),
    }),
    markerOilRefineryRed: new Style({
        image: new Icon({
            offset: [0, 0],
            anchor: [0, 0],
            size: [400, 400],
            opacity: 1,
            scale: 0.05,
            src: require("@/assets/img/marker-oil-refinery-red.png"),
        }),
    }),
    markerOilRefineryYellow: new Style({
        image: new Icon({
            offset: [0, 0],
            anchor: [0, 0],
            size: [400, 400],
            opacity: 1,
            scale: 0.05,
            src: require("@/assets/img/marker-oil-refinery-yellow.png"),

        }),
    }),
    markerOilRefineryGreen: new Style({
        image: new Icon({
            offset: [0, 0],
            anchor: [0, 0],
            size: [400, 400],
            opacity: 1,
            scale: 0.05,
            src: require("@/assets/img/marker-oil-refinery-green.png"),
        }),
    }),
    markerOilRefineryGrey: new Style({
        image: new Icon({
            // offset: [0, 0],
            // anchor: [0, 0],
            size: [400, 400],
            opacity: 1,
            scale: 0.05,
            src: require("@/assets/img/marker-oil-refinery-grey.png"),

        }),
    }),
    markerOilExportRed: new Style({
        image: new Icon({
            offset: [0, 0],
            anchor: [0, 0],
            size: [400, 400],
            opacity: 1,
            scale: 0.05,
            src: require("@/assets/img/marker-oil-export-red.png"),
        }),
    }),
    markerOilExportYellow: new Style({
        image: new Icon({
            offset: [0, 0],
            anchor: [0, 0],
            size: [400, 400],
            opacity: 1,
            scale: 0.05,
            src: require("@/assets/img/marker-oil-export-yellow.png"),
        }),
    }),
    markerOilExportGreen: new Style({
        image: new Icon({
            offset: [0, 0],
            anchor: [0, 0],
            size: [400, 400],
            opacity: 1,
            scale: 0.05,
            src: require("@/assets/img/marker-oil-export-green.png"),
        }),
    }),
    markerOilExportGrey: new Style({
        image: new Icon({
            offset: [0, 0],
            anchor: [0, 0],
            size: [400, 400],
            opacity: 1,
            scale: 0.05,
            src: require("@/assets/img/marker-oil-export-grey.png"),
        }),
    }),
    markerOilTransportRed: new Style({
        image: new Icon({
            offset: [0, 0],
            anchor: [0, 0],
            size: [400, 400],
            opacity: 1,
            scale: 0.05,
            src: require("@/assets/img/marker-oil-transport-red.png"),
        }),
    }),
    markerOilTransportYellow: new Style({
        image: new Icon({
            offset: [0, 0],
            anchor: [0, 0],
            size: [400, 400],
            opacity: 1,
            scale: 0.05,
            src: require("@/assets/img/marker-oil-transport-yellow.png"),
        }),
    }),
    markerOilTransportGreen: new Style({
        image: new Icon({
            offset: [0, 0],
            anchor: [0, 0],
            size: [400, 400],
            opacity: 1,
            scale: 0.05,
            src: require("@/assets/img/marker-oil-transport-green.png"),
        }),
    }),
    markerOilTransportGrey: new Style({
        image: new Icon({
            offset: [0, 0],
            anchor: [0, 0],
            size: [400, 400],
            opacity: 1,
            scale: 0.05,
            src: require("@/assets/img/marker-oil-transport-grey.png"),
        }),
    }),
    /** **/
    markerOilProduceEmpty: new Style({
        image: new Icon({
            offset: [0, 0],
            anchor: [0, 0],
            size: [400, 400],
            opacity: 1,
            scale: 0.05,
            src: require("@/assets/img/marker-oil-produce-empty.png"),
        }),
    }),
    markerOilProduceFull: new Style({
        image: new Icon({
            offset: [0, 0],
            anchor: [0, 0],
            size: [400, 400],
            opacity: 1,
            scale: 0.05,
            src: require("@/assets/img/marker-oil-produce-full.png"),
        }),
    }),
    markerOilRefineryFull: new Style({
        image: new Icon({
            offset: [0, 0],
            anchor: [0, 0],
            size: [400, 400],
            opacity: 1,
            scale: 0.05,
            src: require("@/assets/img/marker-oil-refinery-full.png"),

        }),
    }),
    markerOilRefineryEmpty: new Style({
        image: new Icon({
            offset: [0, 0],
            anchor: [0, 0],
            size: [512, 512],
            offsetOrigin: "bottom-left",
            opacity: 1,
            scale: 0.05,
            src: require("@/assets/img/marker-oil-refinery-empty.png"),
        }),
    }),
    markerOilExportFull: new Style({
        image: new Icon({
            offset: [0, 0],
            anchor: [0, 0],
            size: [400, 400],
            opacity: 1,
            scale: 0.05,
            src: require("@/assets/img/marker-oil-export-red.png"),
        }),
    }),
    markerOilExportEmpty: new Style({
        image: new Icon({
            offset: [0, 0],
            anchor: [0, 0],
            size: [400, 400],
            offsetOrigin: "bottom-left",
            opacity: 1,
            scale: 0.05,
            src: require("@/assets/img/marker-oil-export-yellow.png"),
        }),
    }),
    markerOilTransportFull: new Style({
        image: new Icon({
            offset: [0, 0],
            anchor: [0, 0],
            size: [400, 400],
            opacity: 1,
            scale: 0.05,
            src: require("@/assets/img/marker-oil-transport-full.png"),
        }),
    }),
    markerOilTransportEmpty: new Style({
        image: new Icon({
            offset: [0, 0],
            anchor: [0, 0],
            size: [400, 400],
            offsetOrigin: "bottom-left",
            opacity: 1,
            scale: 0.05,
            src: require("@/assets/img/marker-oil-transport-empty.png"),
        }),
    }),
    // markerOilFakeProduceEmpty: new Style({
    //     image: new Icon({
    //         offset: [0, 0],
    //         anchor: [0, 0],
    //         size: [400, 400],
    //         opacity: 1,
    //         scale: 0.05,
    //         src: require("@/assets/img/marker-oil-fake-produce-empty.png"),
    //     }),
    // }),
    outerCircle: new Style({
        image: new Circle({
            offset: [50, 50],
            anchor: [0, 0],
            radius: 5,
            scale: 2,
            stroke: new Stroke({
                color: "blue",
                width: 1,
            }),
            fill: new Fill({
                color: "white",
            }),
        }),
    }),
    innerCircle: new Style({
        image: new Circle({
            radius: 5,
            offset: [5, 50],
            scale: 1,
            stroke: new Stroke({
                color: "blue",
                width: 1,
            }),
            fill: new Fill({
                color: "cyan",
            }),
        }),
    }),
    text: new Style({
        text: new Text({
            font: "Icons", text: "\f073",
            offsetY: 30,
            offsetX: 10,
            scale: 1,
            fill: new Fill({
                color: "black",
            }),
        }),
    }),
}
const pipelineLayerGroup = new LayerGroup({ layers: [], name: "PipelineGroup", })
const depositLayerGroup = new LayerGroup({ layers: [], name: "DepositGroup", })
const facilityLayerGroup = new LayerGroup({ layers: [], name: "FacilityGroup", })
const exportLayerGroup = new LayerGroup({ layers: [], name: "ExportGroup", })
const transportLayerGroup = new LayerGroup({ layers: [], name: "TransportGroup", })
const getVectorLayer = (data, style) => new VectorLayer({
    source: new VectorSource({
        features: new GeoJSON().readFeatures(data, { featureProjection: projection, }),
        format: new GeoJSON(),
    }),
    style: style,
});

//! Methods
const getTileLayer = () => new TileLayer({
    source: new XYZ({
        url: path.tile,
        maxZoom: maxZoom,
        extent: [
            3728245.25310963, 3353069.02887892,
            10084178.830578446, 9014225.251928212
        ],
    }),
})
const getCountryLayer = () => getVectorLayer(data.country, style.country)
const getContractTerritoriesLayer = () => getVectorLayer(data.contract, style.contract) //! Не удалять - ожидаем разрешения и данные для отображения

const getPipelineLayer = () => {
    const pipelineLayer = getVectorLayer(data.pipeline, style.default);
    pipelineLayer.set('Name', "PipelineLayer", true)
    pipelineLayerGroup.set('Name', "PipelineGroup", true)
    //! Каждый нефтепровод красится индивидуально
    pipelineLayer.getSource().getFeatures().forEach(feature => {
        feature.setStyle(new Style({
            stroke: new Stroke({
                color: feature.get('color'),
                width: feature.get('width'),
            }),
        }))
    })
    pipelineLayerGroup.getLayers().getArray().push(pipelineLayer)
    return pipelineLayerGroup
}

const getDepositLayer = () => {
    const depositLayer = getVectorLayer(data.deposit, null);
    depositLayer.set('Name', "DepositLayer", true)
    depositLayerGroup.set('Name', "DepositGroup", true)

    depositLayerGroup.getLayers().getArray().push(depositLayer)
    colorizeDepositLayerGroup()
    return depositLayerGroup
}

const getFacilityLayer = () => {
    style.markerOilRefineryFull
    const facilityLayer = getVectorLayer(data.facility, style.markerOilRefineryGrey);
    facilityLayer.set('Name', "FacilityLayer", true)
    facilityLayerGroup.set('Name', "FacilityGroup", true)

    facilityLayerGroup.getLayers().getArray().push(facilityLayer)
    colorizeFacilityLayerGroup()
    return facilityLayerGroup
}
const getExportLayer = () => {
    const exportLayer = getVectorLayer(data.export, null);
    exportLayer.set('Name', "ExportLayer", true)
    exportLayerGroup.set('Name', "ExportGroup", true)

    exportLayerGroup.getLayers().getArray().push(exportLayer)
    colorizeExportLayerGroup()
    return exportLayerGroup
}
const getTransportLayer = () => {
    const transportLayer = getVectorLayer(data.transport, null);
    transportLayer.set('Name', "TransportLayer", true)
    transportLayerGroup.set('Name', "TransportGroup", true)

    transportLayerGroup.getLayers().getArray().push(transportLayer)
    colorizeTransportLayerGroup()
    return transportLayerGroup
}

const colorizeDepositLayerGroup = (grey = true, red = true, yellow = true, green = true) => {
    depositLayerGroup.getLayers().forEach(layer => layer.getSource().getFeatures().forEach(feature => {
        const volume = feature.get('volume');
        const connectionStatus = feature.get('connectionStatus');

        // Не подключен
        if (connectionStatus == 0) {
            // feature.setStyle(null)?
            feature.setStyle(grey ? [style.markerOilProduceGrey] : null)
        }

        // На подключении
        else if (connectionStatus == 1) {
            feature.setStyle(yellow ? [style.markerOilProduceYellow] : null)
        }

        // Подключен
        else if (connectionStatus == 2) {
            // Не передает
            if (volume == '0' || volume == '' || volume == undefined) {
                feature.setStyle(red ? [style.markerOilProduceRed] : null)
            }
            // Передает
            else {
                feature.setStyle(green ? [style.markerOilProduceGreen] : null)
            }
        }
    }))
}
const colorizeFacilityLayerGroup = (grey = true, red = true, yellow = true, green = true) => {
    facilityLayerGroup.getLayers().forEach(layer => layer.getSource().getFeatures().forEach(feature => {
        const volume = feature.get('volume');
        const connectionStatus = feature.get('connectionStatus');

        // Не подключен
        if (connectionStatus == 0) {
            // feature.setStyle(null)?
            feature.setStyle(grey ? [style.markerOilRefineryGrey] : null)
        }

        // На подключении
        else if (connectionStatus == 1) {
            feature.setStyle(yellow ? [style.markerOilRefineryYellow] : null)
        }

        // Подключен
        else if (connectionStatus == 2) {
            // Не передает
            if (volume == '0' || volume == '' || volume == undefined) {
                feature.setStyle(red ? [style.markerOilRefineryRed] : null)
            }
            // Передает
            else {
                feature.setStyle(green ? [style.markerOilRefineryGreen] : null)
            }
        }
    }))
}
const colorizeExportLayerGroup = (grey = true, red = true, yellow = true, green = true) => {
    exportLayerGroup.getLayers().forEach(layer => layer.getSource().getFeatures().forEach(feature => {
        const volume = feature.get('volume');
        const connectionStatus = feature.get('connectionStatus');

        // Не подключен
        if (connectionStatus == 0) {
            // feature.setStyle(null)?
            feature.setStyle(grey ? [style.markerOilExportGrey] : null)
        }

        // На подключении
        else if (connectionStatus == 1) {
            feature.setStyle(yellow ? [style.markerOilExportYellow] : null)
        }

        // Подключен
        else if (connectionStatus == 2) {
            // Не передает
            if (volume == '0' || volume == '' || volume == undefined) {
                feature.setStyle(red ? [style.markerOilExportRed] : null)
            }
            // Передает
            else {
                feature.setStyle(green ? [style.markerOilExportGreen] : null)
            }
        }
    }))
}
const colorizeTransportLayerGroup = (grey = true, red = true, yellow = true, green = true) => {
    transportLayerGroup.getLayers().forEach(layer => layer.getSource().getFeatures().forEach(feature => {
        const volume = feature.get('volume');
        const connectionStatus = feature.get('connectionStatus');

        // Не подключен
        if (connectionStatus == 0) {
            grey ? feature.setStyle([style.markerOilTransportGrey]) : feature.setStyle([new Style()])
            // console.log(feature.getStyle())

        }

        // На подключении
        else if (connectionStatus == 1) {
            yellow ? feature.setStyle([style.markerOilTransportYellow]) : feature.setStyle([new Style()])
        }

        // Подключен
        else if (connectionStatus == 2) {
            // Не передает
            if (volume == '0' || volume == '' || volume == undefined) {
                red ? feature.setStyle([style.markerOilTransportRed]) : feature.setStyle([new Style()])
            }
            // Передает
            else {
                green ? feature.setStyle([style.markerOiltransportGreen]) : feature.setStyle([new Style()])
            }
        }
    }))
}

class AppMap {
    constructor(mapRefs, datas) {
        data.facility = datas.facilityData;
        data.deposit = datas.depositData;
        data.export = datas.exportData;
        data.transport = datas.transportData;
        this.target = mapRefs.Target;
        this.control = mapRefs.Control;
        this.popup = mapRefs.Popup;
        this.table = mapRefs.Table;
        this.overlay = this.getOverlay(this.popup);
        const that = this;
        this.map = new Map({
            controls: defaultControls().extend([
                new ZoomToExtent({
                    extent: defaultExtent,
                }),
                new FullScreen(),
                new ScaleLine(),
                new Control({ element: this.control })
            ]),
            target: this.target,
            layers: [
                getTileLayer(),
                getCountryLayer(),
                // getContractTerritoriesLayer(), //! Показ контрактных территорий убран временно - не удалять           
                getFacilityLayer(),
                getDepositLayer(),
                getExportLayer(),
                getTransportLayer(),
                getPipelineLayer(),


            ],
            overlays: [this.overlay],
            view: new View({
                zoom: 0.01,
                center: countryCoordinates,
                // constrainResolution: true,
                extent: defaultExtent,
            }),
        })
        this.map.on("singleclick", function (evt) {
            // that.overlay.setPosition(undefined); // закрытие предыдущего окна - если закомментированно то окно закрывается только по нажатию на кнопку, иначе на любом месте карты
            that.removePopup();
            that.displayFeatureInfo(evt);
        });
        let previousPipeline = null;
        this.map.on("pointermove", function (evt) {
            if (previousPipeline !== null) {
                previousPipeline.getStyle().getStroke().setWidth(3);
                previousPipeline.changed();
                evt.map.render();
                previousPipeline = null;

            }
            if (evt.dragging) {
                return;
            }
            const pixel = evt.pixel;
            const features = [];

            evt.map.forEachFeatureAtPixel(
                pixel,
                function (feature, layer) {
                    feature.layerName = layer.get("Name")
                    features.push(feature);
                },
                {
                    layerFilter: function (layer) {
                        const layerName = layer.get("Name")
                        return layerName == "FacilityLayer" || layerName == "DepositLayer" || layerName == "ExportLayer" || layerName == "TransportLayer" || layerName == "PipelineLayer";
                    },
                }
            );
            if (features.length == 0) {
                return;
            }
            let selectedPipeline = features.find(feature => feature.layerName == "PipelineLayer");
            if (selectedPipeline != undefined) {
                selectedPipeline.getStyle().getStroke().setWidth(6);
                selectedPipeline.changed();
                previousPipeline = selectedPipeline;
                evt.map.renderSync();
            }
        });

    }
    displayFeatureInfo(evt) {
        const pixel = evt.pixel;
        // const coordinate = evt.coordinate;
        const features = [];

        this.map.forEachFeatureAtPixel(
            pixel,
            function (feature, layer) {
                feature.layerName = layer.get("Name")
                features.push(feature);
            },
            {
                layerFilter: function (layer) {
                    const layerName = layer.get("Name")
                    return layerName == "FacilityLayer" || layerName == "DepositLayer" || layerName == "ExportLayer" || layerName == "TransportLayer" || layerName == "PipelineLayer";
                },
            }
        );

        if (features.length > 0) {
            const info = [];
            for (let k = 0; k < features.length; k++) {
                if (features[k].layerName == "PipelineLayer") {
                    features[k].values_.organizationName = features[k].values_.name;
                    this.table.headers = [
                        { text: "Организация", value: "organizationName"}
                    ];
                    this.table.disableFiltering = true;
                }
                else {
                    this.table.headers = [
                        { text: "Организация", value: "organizationName" },
                        { text: "СИКН", value: "facilityName" },
                        // { text: "From", value: "CodeFrom" },
                        // { text: "To", value: "Code" },
                        { text: "Дата", value: "date" },
                        { text: "Масса (тонн)", value: "volume" },
                        { text: "Тип учета", value: "accountingType" }
                      ];
                }
            }
            for (let i = 0, ii = features.length; i < ii; ++i) {
                info.push(features[i].getProperties());
            }
            this.table.items = info;
            const report = this.popup.querySelector("#popup-report");
            let path = ''
            switch (features[0].layerName) {
                case "DepositLayer":
                    path = 'produce'
                    break;
                case "ExportLayer":
                    path = 'transport'
                    break;
                case "TransportLayer":
                    path = 'transport'
                    break;
                case "FacilityLayer":
                    path = 'refinery'
                    break;
                case "PipelineLayer":
                    path = 'transport'
                    break;
                default:
                    break;
            }
            // report.innerText = features[0].layerName
            report.setAttribute("href", `/app/report/${path}`);
            const closer = this.popup.querySelector("#popup-closer");
            const that = this;
            this.overlay.setPosition(evt.coordinate);
            this.popup.querySelector("div.popup").style.display = "block";
        }
    }
    removePopup() {
        let popup = this.popup.querySelector("div.popup");
        popup.style.display = "none";
    }
    getMap() { return this.map }
    getOverlay(popup) {
        return new Overlay({
            element: popup,
            positioning: "bottom-center",
            autoPan: true,
            autoPanAnimation: {
                duration: 250
            },
            stopEvent: false,
        })
    }
    getFacilityLayerGroup() { return facilityLayerGroup }
    showDeposit(visible) { depositLayerGroup.setVisible(visible) };
    showPipeline(visible) { pipelineLayerGroup.setVisible(visible) };
    showFacility(visible) { facilityLayerGroup.setVisible(visible) };
    showExport(visible) { exportLayerGroup.setVisible(visible) };
    showTransport(visible) { transportLayerGroup.setVisible(visible) };
    showLayersGroupByConnectionStatus(grey = true, red = true, yellow = true, green = true) {
        colorizeDepositLayerGroup(grey, red, yellow, green)
        colorizeFacilityLayerGroup(grey, red, yellow, green)
        colorizeExportLayerGroup(grey, red, yellow, green)
        colorizeTransportLayerGroup(grey, red, yellow, green)


        // depositLayerGroup.getLayers().forEach(layer => layer.getSource().refresh());
        // facilityLayerGroup.getLayers().forEach(layer => layer.getSource().refresh());
        // exportLayerGroup.getLayers().forEach(layer => layer.getSource().refresh());
        // transportLayerGroup.getLayers().forEach(layer => layer.getSource().refresh());
        this.map.render()

    }
}
export { AppMap }