<template>
  <v-row>
    <v-col>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Поиск"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :search="search"
        class="elevation-1"
        :footer-props="{
          itemsPerPageAllText: 'Все',
          itemsPerPageText: 'Строк на страницу'
        }"
      >
        <v-progress-linear
          v-slot:progress
          color="blue"
          indeterminate
        ></v-progress-linear>
        <template v-slot:[`footer.page-text`]="items">
          {{ items.pageStart }} - {{ items.pageStop }} из
          {{ items.itemsLength }}
        </template>

        <template v-slot:item.date="{ item }">
          <td>{{ item.date | date }}</td>
        </template>
        <!-- для OilFlowDatas -->
        <template v-slot:item.dateTime="{ item }">
          <td>{{ item.date | date }}</td>
        </template>
        <template v-slot:item.mass="{ item }">
          {{ item.mass.toLocaleString() }}
        </template>
        <template v-slot:item.temperature="{ item }">
          {{ item.temperature == null ? 'null' : item.temperature.toFixed(2) }}
        </template>
        <template v-slot:item.density="{ item }">
          {{ item.density.toFixed(2) }}
        </template>
        <template v-slot:item.volume="{ item }">
          {{ item.volume.toFixed(2) }}
        </template>

        <!-- для DataTransferAudit -->
        <template v-slot:item.begin="{ item }">
          {{ item.begin | date }}
        </template>
        <template v-slot:item.end="{ item }">
          {{ item.end | date }}
        </template>
        <template v-slot:item.status="{ item }">
          {{ item.status == false ? "Открыт" : "Закрыт" }}
        </template>
        
        <!-- для UserActivity -->
        <template v-slot:item.userName="{ item }">
          {{ item.userName }}
        </template>
        <template v-slot:item.role="{ item }">
          {{ item.role }}
        </template>
        <template v-slot:item.firstName="{ item }">
          {{ item.firstName }}
        </template>
        <template v-slot:item.lastName="{ item }">
          {{ item.lastName }}
        </template>
        <template v-slot:item.methodName="{ item }">
          {{ item.methodName }}
        </template>
        <template v-slot:item.description="{ item }">
          {{ item.description }}
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>
<script>
import { get, sync } from "vuex-pathify";
export default {
  name: "ReportTable.vue",
  computed: {
    ...get("report", ["loading"])
  },
  props: {
    headers: {
      type: Array
    },
    items: {
      type: Array
    }
  },
  data() {
    return {
      search: ""
    };
  }
};
</script>
