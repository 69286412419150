<template>
  <v-container fluid>
    <report-title :title="options.title" class="mb-2" />
    <report-sub-category :tabs="options.tabs" @select-tab="selectTab" />
    <report-filter
      :organizations="options.organizations"
      @load-report="loadReport($event)"
      @export-table="exportTableToXLSX($event)"
    />
    <report-kpi-bar />
    <report-chart
      :chartOptions="chartOptions"
      :series="series"
      :actionOptions="options.chartAction"
    />
    <report-table :headers="options.headers" :items="options.data" />
    <report-alert />
  </v-container>
</template>

<script>
import { Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import { json2xlsx } from "@/util/helpers";

export default Vue.extend({
  name: "ReportTemplate",
  components: {
    ReportTitle: () => import("@/components/report/ReportTitle.vue"),
    ReportChart: () => import("@/components/report/ReportChart.vue"),
    ReportSubCategory: () =>
      import("@/components/report/ReportSubCategory.vue"),
    ReportFilter: () => import("@/components/report/ReportFilter.vue"),
    ReportKpiBar: () => import("@/components/report/ReportKPIBar.vue"),
    ReportTable: () => import("@/components/report/ReportTable.vue"),
    ReportAlert: () => import("@/components/report/ReportAlert.vue")
  },
  props: {
    options: {
      type: Object,
      required: true
    },
    chartOptions: {
      type: Object,
      required: true
    },
    series: {
      type: Array,
      required: true
    },
    selectTab: {
      type: Function,
      required: true
    },
    loadReport: {
      type: Function,
      required: true
    }
  },
  computed: {
    ...mapState({ errorMessage: state => state.report.errorMessages.export })
  },
  data() {
    return {};
  },
  methods: {
    exportTableToXLSX(currentTab) {
      this.options.data.length > 0
        ? json2xlsx({
            data: this.options.data,
            header: [this.options.headers.map(header => header.text)],
            sheetName: "Отчет",
            fileName:
              "ИСУН - " + this.options.title + " - " + currentTab.name + ".xlsx"
          })
        : console.log(this.errorMessage);
    }
  },
  mounted() {}
});
</script>
