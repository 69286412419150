<template>
  <transition name="fade" mode="out-in">
    <v-form ref="form" v-model="valid" lazy-validation justify-center>
      <v-row justify="space-around" class="px-0">
        <v-col cols="3">
          <report-date-picker :rangeValue="range.start" />
        </v-col>
        <v-col cols="3">
          <report-date-picker :rangeValue="range.end" />
        </v-col>
        <v-col cols="2" align="center">
          <div>
            <v-btn rounded color="primary" dark @click="showReport">
              Показать отчет
            </v-btn>
          </div>
        </v-col>
        <v-col cols="2" align="center">
          <div>
            <v-btn-toggle>
              <v-btn color="secondary" dark @click="exportTable()">
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </v-btn-toggle>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </transition>
</template>
<script>
import { get } from "vuex-pathify";

export default {
  name: "ReportFilterLog.vue",
  components: {
    ReportDatePicker: () => import("@/components/report/ReportDatePicker.vue"),
  },
  computed: {
    ...get("report", ["range"]),
  },
  data() {
    return {
      valid: true,
    };
  },
  methods: {
    showReport() {
      if (
        this.$refs.form.validate() &&
        this.range.start.value != null &&
        this.range.end.value != null
      ) {
        const options = {
          params: {
            date_from: this.range.start.value,
            date_to: this.range.end.value,
          },
        };
        this.$emit("change-filter", options);
      }
    },
    resetFilter() {
      this.$refs.form.reset();
    },
    exportTable() {
      this.$emit("export-table");
    }
  },
};
</script>
<style lang='scss'>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>