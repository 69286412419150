import { make } from "vuex-pathify";
import axios from "axios";
import { differenceInDays, startOfYear, format, parseISO } from "date-fns";
import { PRODUCTION_FACILITY_TYPES } from "@/util/globals";
import { ORGANIZATION_TYPES } from "@/util/globals";
import { STATUS } from "@/util/globals";
import { sortByNameRuAscending } from "@/util/helpers";

const state = {
    organization: {
        all: {
            id: 0,
            statusId: 3,
            nameRu: 'Все организации',
            bin: '----0000----'
        },
        path: {
            base: `api/Reference/Organization/${ORGANIZATION_TYPES.OilCompany}/`,
            sub: `/${STATUS.Active}`
        }
    },
    showError: false,
    errorMessages: {
        organization: "Error while loading organizations list",
        report: "Error while loading report.",
        export: "An empty table could not be exported."
    },
    errorMessage: "",
    loading: true,
    currentTab: null,
    range: {
        start: {
            label: "Начальная дата",
            value: null
        },
        end: {
            label: "Конечная дата",
            value: null
        }
    },
    reportKpi: {
        numberOfDays: {
            value: 0,
            text: "дне-(й/я)",
            icon: "mdi-calendar-range"
        },
        oilProduction: { value: 0, text: "тонн", icon: "mdi-trending-up" }
    },
    series: [],
    chartOptions: {
        //colors: ['#FFFFFF'],// Цвет линий графика
        chart: {
            defaultLocale: 'ru',
            locales: [{
                name: 'ru',
                options: {
                    months: ["Январь",
                        "Февраль",
                        "Март",
                        "Апрель",
                        "Май",
                        "Июнь",
                        "Июль",
                        "Август",
                        "Сентябрь",
                        "Октябрь",
                        "Ноябрь",
                        "Декабрь"],
                    shortMonths: ["Янв",
                        "Фев",
                        "Мар",
                        "Апр",
                        "Май",
                        "Июн",
                        "Июл",
                        "Авг",
                        "Сен",
                        "Окт",
                        "Ноя",
                        "Дек"],
                    days: ["Воскресенье",
                        "Понедельник",
                        "Вторник",
                        "Среда",
                        "Четверг",
                        "Пятница",
                        "Суббота"],
                    shortDays: ["Вс",
                        "Пн",
                        "Вт",
                        "Ср",
                        "Чт",
                        "Пт",
                        "Сб"],
                    toolbar: {
                        "download": "Сохранить",
                        "exportToSVG": "Сохранить SVG",
                        "exportToPNG": "Сохранить PNG",
                        "exportToCSV": "Сохранить CSV",
                        "menu": "Меню",
                        "selection": "Выбор",
                        "selectionZoom": "Выбор с увеличением",
                        "zoomIn": "Увеличить",
                        "zoomOut": "Уменьшить",
                        "pan": "Перемещение",
                        "reset": "Сбросить увеличение"
                    }
                }
            }],
            type: "area",
            stacked: false,
            height: 350,
            zoom: {
                type: "x",
                enabled: true,
                autoScaleYaxis: true
            },
            toolbar: {
                autoSelected: "zoom",
            }
        },
        dataLabels: {
            enabled: false,
        },
        markers: {
            size: 0
        },
        title: {
            text: "ISUN Report Produce Chart - organization",
            align: "left",
            style: {
                color: '#FFFFFF',
            },
        },
        fill: {
            type: "gradient",
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 90, 100]
            }
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return val.toFixed(0);
                    // return (val / 1000000).toFixed(0);
                },
                style: {
                    colors: '#78909C',
                    // colors: '#FFFFFF',
                }
            },
            title: {
                text: "Нефть (тонн)",
                style: {
                    color: "#78909C",
                }
            }
        },
        xaxis: {
            type: "datetime",
            labels: {
                // datetimeUTC: false,
                style: {
                    colors: '#78909C',
                }

            }
        },
        tooltip: {
            shared: false,
            y: {
                formatter: function (val) {
                    // return (val / 1000000).toFixed(0);
                    return `${val.toFixed(0).toLocaleString()} т.`;
                }
            }
        }
    },
    optionsProduce: {
        title: "Отчет по нефтедобывающим организациям",
        facilityType: PRODUCTION_FACILITY_TYPES.OilProducingCompany,
        path: {
            base: "api/Report/Produce/"
        },
        tabs: [
            {
                id: 1,
                name: "Поставка на транспортные организации",
                link: "Transport",
                disabled: false
            },
            // Скрыто времено по распоряжению Смагулова Аскара 07.04.2022
            // {
            //     id: 2,
            //     name: "Сдача на перерабатывающие заводы",
            //     link: "Process",
            // },
            {
                id: 3,
                name: "Отгрузка на транспортные организации",
                link: "Flow",
                disabled: false
            },
            {
                id: 4,
                name: "Хранение",
                link: "Storage",
                disabled: false
            },
            {
                id: 5,
                name: "Подготовка",
                link: "Preparation",
                disabled: false
            },
            {
                id: 6,
                name: "Отгрузка на экспорт",
                link: "Export",
                disabled: false
            },
            {
                id: 7,
                name: "Отгрузка потребителям (В разработке)",
                link: "",
                disabled: true
            },
            
            
        ],

        // Заголовки по умолчанию, показываются при старте страницы
        headers: [
            { text: "Организация", value: "organizationName" },
            // { text: "БИН", value: "bin" },
            { text: "Магистральный нефтепровод", value: "pipeline" },
            // { text: "Местоположение объекта", value: "location" },
            { text: "Прибор учета", value: "meteringDevice" },
            { text: "Дата", value: "date" },
            { text: "Масса", value: "mass" }
        ],
        headersReport: {
            base: [
                { text: "Организация", value: "organizationName" },
                // { text: "БИН", value: "bin" },
                { text: "Магистральный нефтепровод", value: "pipeline" },
                // { text: "Местоположение объекта", value: "location" },
                { text: "Прибор учета", value: "meteringDevice" },
                { text: "Дата", value: "date" },
                { text: "Масса", value: "mass" }
            ],
            export: [
                { text: "Организация", value: "organizationName" },
                // { text: "БИН", value: "bin" },
                { text: "Магистральный нефтепровод", value: "pipelineName" },
                // { text: "Местоположение объекта", value: "location" },
                { text: "Прибор учета", value: "productionFacilityName" },
                { text: "Дата", value: "date" },
                { text: "Масса", value: "mass" }
            ],
            oilFlow: [
                { text: "Организация", value: "organizationName" },
                { text: "Прибор учета", value: "productionFacilityName" },
                { text: "Магистральный нефтепровод", value: "pipelineName" },
                { text: "Дата", value: "date" },
                { text: "Масса", value: "mass" },
                { text: "Масса на начало", value: "massFlowBegin" },
                { text: "Масса на конец", value: "massFlowEnd" },
                { text: "Температура", value: "temperature" },
                { text: "Плотность", value: "density" },
                { text: "Объем", value: "volume" }
            ],
            oilTank: [
                { text: "Организация", value: "organizationName" },
                { text: "Прибор учета", value: "productionFacilityName" },
                { text: "Дата", value: "date" },
                { text: "Масса", value: "mass" },
                { text: "Плотность", value: "density" },
                { text: "Уровень", value: "tankLevel" },
                { text: "Температура", value: "temperature" },
                { text: "Объем", value: "volume" }
            ]
        },

        organizations: [],
        data: []
    },
    optionsTransport: {
        title: "Отчет по нефтетранспортным организациям",
        facilityType: PRODUCTION_FACILITY_TYPES.OilTransportationCompany,
        path: {
            base: "api/Report/Transport/"
        },
        tabs: [
            {
                id: 1,
                name: "Прием от нефтедобывающих организации",
                link: "1",
                disabled: false
                // link: "DeliveryFromOilProduction",
            },
            {
                id: 2,
                name: "Сдача на перерабатывающие заводы",
                link: "2",
                disabled: false
                // link: "ShipmentToOilProduction",
            },
            {
                id: 3,
                name: "Сдача на транспортные организации",
                link: "3",
                disabled: false
                // link: "ShipmentToTransportCompany",
            },
            {
                id: 4,
                name: "Экспорт",
                link: "4",
                disabled: false
                // link: "ShipmentToExport",
            },
            {
                id: 5,
                name: "Хранение (В разработке)",
                link: "5",
                disabled: true
            }
        ],
        headers: [
            { text: "Организация", value: "organizationName" },
            // { text: "БИН", value: "bin" },
            { text: "Магистральный нефтепровод", value: "pipeline" },
            // { text: "Местоположение объекта", value: "location" },
            { text: "Прибор учета", value: "meteringDevice" },
            { text: "Дата", value: "date" },
            { text: "Масса", value: "mass" }
        ],
        organizations: [],
        data: []
    },
    optionsTerminals: {
        title: "Отчет по нефтяным терминалам",
        facilityType: PRODUCTION_FACILITY_TYPES.OilTerminal,
        path: {
            base: "api/Report/Terminals/"
        },
        tabs: [
            {
                id: 1,
                name: "Прием от добывающих организаций",
                link: "Flow",
                disabled: false
            },
            {
                id: 2,
                name: "Подготовка",
                link: "Preparation",
                disabled: false
            },
            {
                id: 3,
                name: "Хранение",
                link: "Storage",
                disabled: false
            },
        ],
        headers: [
            { text: "Организация", value: "organizationName" },
            { text: "Прибор учета", value: "productionFacilityName" },
            { text: "Магистральный нефтепровод", value: "pipelineName" },
            { text: "Дата", value: "date" },
            { text: "Масса", value: "mass" },
            { text: "Масса на начало", value: "massFlowBegin" },
            { text: "Масса на конец", value: "massFlowEnd" },
            { text: "Температура", value: "temperature" },
            { text: "Плотность", value: "density" },
            { text: "Объем", value: "volume" }
        ],
        headersReport: {
            base: [
                { text: "Организация", value: "organizationName" },
                { text: "Прибор учета", value: "productionFacilityName" },
                { text: "Магистральный нефтепровод", value: "pipelineName" },
                { text: "Дата", value: "date" },
                { text: "Масса", value: "mass" },
                { text: "Масса на начало", value: "massFlowBegin" },
                { text: "Масса на конец", value: "massFlowEnd" },
                { text: "Температура", value: "temperature" },
                { text: "Плотность", value: "density" },
                { text: "Объем", value: "volume" }
            ],
            oilTank: [
                { text: "Организация", value: "organizationName" },
                { text: "Прибор учета", value: "productionFacilityName" },
                { text: "Дата", value: "date" },
                { text: "Масса", value: "mass" },
                { text: "Плотность", value: "density" },
                { text: "Уровень", value: "tankLevel" },
                { text: "Температура", value: "temperature" },
                { text: "Объем", value: "volume" }
            ]
        },
        organizations: [],
        data: []
    },
    optionsRefinery: {
        title: "Отчет по нефтеперерабатывающим организациям",
        facilityType: PRODUCTION_FACILITY_TYPES.OilRefiningCompany,
        path: {
            base: "api/Report/Refinery/"
        },
        tabs: [
            {
                id: 1,
                name: "Прием от нефтетранспортных организаций",
                link: "Transport",
                disabled: false
            },
            {
                id: 2,
                name: "Прием от нефтедобывающих организаций",
                link: "Flow",
                disabled: false
            },
            {
                id: 3,
                name: "Хранение",
                link: "Storage",
                disabled: false
            }
        ],

        // Заголовки по умолчанию, показываются при старте страницы
        headers: [
            { text: "Организация", value: "organizationName" },
            // { text: "БИН", value: "bin" },
            { text: "Магистральный нефтепровод", value: "pipeline" },
            // { text: "Местоположение объекта", value: "location" },
            { text: "Прибор учета", value: "meteringDevice" },
            { text: "Дата", value: "date" },
            { text: "Масса", value: "mass" }
        ],
        headersReport: {
            base: [
                { text: "Организация", value: "organizationName" },
                // { text: "БИН", value: "bin" },
                { text: "Магистральный нефтепровод", value: "pipeline" },
                // { text: "Местоположение объекта", value: "location" },
                { text: "Прибор учета", value: "meteringDevice" },
                { text: "Дата", value: "date" },
                { text: "Масса", value: "mass" }
            ],
            oilFlow: [
                { text: "Организация", value: "organizationName" },
                { text: "Прибор учета", value: "productionFacilityName" },
                { text: "Магистральный нефтепровод", value: "pipelineName" },
                { text: "Дата", value: "date" },
                { text: "Масса", value: "mass" },
                { text: "Масса на начало", value: "massFlowBegin" },
                { text: "Масса на конец", value: "massFlowEnd" },
                { text: "Температура", value: "temperature" },
                { text: "Плотность", value: "density" },
                { text: "Объем", value: "volume" }
            ],
            oilTank: [
                { text: "Организация", value: "organizationName" },
                { text: "Прибор учета", value: "productionFacilityName" },
                { text: "Дата", value: "date" },
                { text: "Масса", value: "mass" },
                { text: "Плотность", value: "density" },
                { text: "Уровень", value: "tankLevel" },
                { text: "Температура", value: "temperature" },
                { text: "Объем", value: "volume" }
            ]
        },
        organizations: [],
        data: []
    },
    optionsFactories: {
        title: "Отчет по битумным заводам",
        facilityType: PRODUCTION_FACILITY_TYPES.BitumenFactory,
        path: {
            base: "api/Report/Factories"
        },
        tabs: [
            {
                id: 1,
                name: "Прием от нефтетранспортных организаций",
                link: "",
                disabled: false
            },
            {
                id: 2,
                name: "Прием от нефтедобывающих заводов (В разработке)",
                link: "",
                disabled: true
            },
            {
                id: 3,
                name: "Хранение (В разработке)",
                link: "",
                disabled: true
            },
        ],
        headers: [
            { text: "Организация", value: "organizationName" },
            // { text: "БИН", value: "bin" },
            { text: "Магистральный нефтепровод", value: "pipeline" },
            // { text: "Местоположение объекта", value: "location" },
            { text: "Прибор учета", value: "meteringDevice" },
            { text: "Дата", value: "date" },
            { text: "Масса", value: "mass" }
        ],
        organizations: [],
        data: []
    },
    
    optionsUsersActivity: {
        title: "Отчет по активности пользователей",
        path: {
            base: "api/Account/Activity"
        },
        headers: [
            // { text: "UserId", value: "userId" },
            { text: "Логин", value: "userName" },
            { text: "Роль", value: "role" },
            // { text: "PersonId", value: "personId" },
            { text: "Имя", value: "firstName" },
            { text: "Фамилия", value: "lastName" },
            { text: "Действие", value: "methodName" },
            // { text: "Задача", value: "task" },
            // { text: "Результат", value: "result" },
            { text: "Примечание", value: "description" }
        ],
        data: []
    },
    optionsDataTransferAudit: {
        title: "Отчет по сбоям передачи данных",
        facilityType: PRODUCTION_FACILITY_TYPES.OilTransportationCompany,
        path: {
            base: "api/Analytic/Transfers/OilDatas"
        },
        tabs: [
            {
                id: 1,
                name: "OilDatas",
                link: "DeliveryFromOilProduction"
            },
            {
                id: 2,
                name: "OilFlowDatas",
                link: "ShipmentToOilProduction"
            },
            {
                id: 3,
                name: "OilTanks",
                link: "ShipmentToTransportCompany"
            }
        ],
        headers: [
            { text: "Организация", value: "organizationName" },
            { text: "Прибор учета", value: "productionFacilityName" },
            { text: "Дата начала инцидента", value: "begin" },
            { text: "Дата окончания инцидента", value: "end" },
            { text: "Источник", value: "source" },
            { text: "Статус инцидента", value: "status" }
        ],
        organizations: [],
        data: [],
        dataFake: [
            {
                orgName: "КазТрансНефть",
                begin: "01.10.2021",
                end: "02.10.2021",
                status: true
            },
            {
                orgName: "КаспийНефть",
                begin: "01.10.2021",
                end: "",
                status: false
            }
        ]
    },
    optionsAnomalies: {
        title: "Аномальные значения",
        path: {
            base: "api/Analytic/Limits",
            max: "/Max",
            min: "/Min"
        },
        tabs: [
            {
                id: 0,
                name: "Максимальные",
                link: "Max"
            },
            {
                id: 1,
                name: "Минимальные",
                link: "Min"
            }
        ],
        headers: [
            // { text: "Id", value: "id" },
            { text: "Организациия", value: "orgName" },
            { text: "Прибор учета", value: "meteringDevice" },
            // { text: "Код", value: "productionFacilityCode" },
            // { text: "БИН", value: "bin" },
            // { text: "Нефтепровод", value: "pipeline" },
            { text: "Дата инцидента", value: "period" },
            { text: "Значение", value: "mass" },
            // { text: "Регион", value: "location" }
        ],
        items: [],
        data: {
            max: [],
            min: []
        }
    },
    optionsKPI: {
        title: "Плановые показатели",
        path: {
            base: "api/Analytic/Limits",
        },
        tabs: [
            {
                id: 0,
                name: "Добыча",
                link: "produce"
            },
            {
                id: 1,
                name: "Переработка",
                link: "refinery"
            },
            {
                id: 2,
                name: "Экспорт",
                link: "export"
            }
        ],
        headers: [
            { text: "Месяц", value: "month" },
            { text: "План (т. т)", value: "plan" },
            { text: "Факт (т. т)", value: "fact" },
            { text: "Исполнение (%)", value: "percent" },
            { text: "Отклонение (%)", value: "deviation" },
            { text: "Отклонение (т. т.)", value: "weightDeviation" },
        ],
        items: [],
        data: [],
        organizations: {},
    }
};

const mutations = {
    ...make.mutations(state),
    //*** ***//
    CHART_SERIES: (state, seriesItem) => {
        state.series = [seriesItem];
    },
    // https://bestofvue.com/repo/apexcharts-vue-apexcharts-vuejs-charts
    CHART_OPTIONS_TITLE: (state, chartTitle) => {
        state.chartOptions = {
            ...state.chartOptions,
            ...{
                title: {
                    text: chartTitle,
                    align: "left",
                    style: {
                        color: '#FFFFFF',
                    },
                }
            }
        };
    },
    KPI_ORGANIZATIONS: (state, data) => {
        state.optionsKPI.organizations = data;
    },
    SET_KPI_DATA: (state, data) => {
        state.optionsKPI.data[data.type] = data.plan;
    },
    PRODUCE_ORGANIZATIONS: (state, data) => {
        state.optionsProduce.organizations = data;
    },
    PRODUCE_DATA: (state, data) => {
        state.optionsProduce.data = data;
    },
    SET_PRODUCE_HEADERS: (state, type) => {
        state.optionsProduce.headers =
            type == "flow"
                ? state.optionsProduce.headersReport.oilFlow
                : type == "storage" || type == "preparation"
                    ? state.optionsProduce.headersReport.oilTank
                        : type == "export"
                            ? state.optionsProduce.headersReport.export
                    : state.optionsProduce.headersReport.base;
    },
    TRANSPORT_ORGANIZATIONS: (state, data) => {
        state.optionsTransport.organizations = data;
    },
    TRANSPORT_DATA: (state, data) => {
        state.optionsTransport.data = data;
    },
    SET_REFINERY_HEADERS: (state, type) => {
        state.optionsRefinery.headers =
            type == "transport"
                ? state.optionsRefinery.headersReport.base
                : type == "flow"
                    ? state.optionsRefinery.headersReport.oilFlow
                    : state.optionsRefinery.headersReport.oilTank;
    },
    SET_TERMINALS_HEADERS: (state, type) => {
        state.optionsTerminals.headers =
            type == "flow"
                ? state.optionsTerminals.headersReport.base
                : type == "storage"
                    ? state.optionsTerminals.headersReport.oilTank
                    : state.optionsTerminals.headersReport.oilTank;
    },
    REFINERY_ORGANIZATIONS: (state, data) => {
        state.optionsRefinery.organizations = data;
    },
    REFINERY_DATA: (state, data) => {
        state.optionsRefinery.data = data;
    },
    TERMINALS_ORGANIZATIONS: (state, data) => {
        state.optionsTerminals.organizations = data;
    },
    TERMINALS_DATA: (state, data) => {
        state.optionsTerminals.data = data;
    },
    FACTORIES_ORGANIZATIONS: (state, data) => {
        state.optionsFactories.organizations = data;
    },
    FACTORIES_DATA: (state, data) => {
        state.optionsFactories.data = data;
    },
    ANOMALIES_MAX_DATA: (state, data) => {
        state.optionsAnomalies.data.max = data;
    },
    ANOMALIES_MIN_DATA: (state, data) => {
        state.optionsAnomalies.data.min = data;
    },
    ANOMALIES_ITEM_SELECT: (state, index) => {
        state.optionsAnomalies.items = state.optionsAnomalies.data[index];
    },
    TRANSFER_AUDIT_ORGANIZATIONS: (state, data) => {
        state.optionsDataTransferAudit.organizations = data;
    },
    TRANSFER_AUDIT_DATA: (state, data) => {
        state.optionsDataTransferAudit.data = data;
    },
    USERS_ACTIVITY_DATA: (state, data) => {
        state.optionsUsersActivity.data = data;
    },
    LOADING: (state, isLoading) => {
        state.loading = isLoading;
    },
    KPI: (state, payload) => {
        state.reportKpi.numberOfDays.value = payload.days;
        state.reportKpi.oilProduction.value = payload.values;
    },
    RANGE: (state, data) => {
        state.range.start.value = data.start;
        state.range.end.value = data.end;
    },
    SET_RANGE_YTD: () => {
        state.range.start.value = format(startOfYear(Date.now()), "yyyy-MM-dd");
        state.range.end.value = format(Date.now(), "yyyy-MM-dd");
    },
    CURRENT_TAB: (state, data) => {
        state.currentTab = data;
    },
    ERROR: (state, isError) => {
        state.showError = isError;
    },
    ERROR_MESSAGE: (state, message) => {
        state.errorMessage = message;
    }
};

const actions = {
    ...make.actions(state),
    setKpi: ({ commit }, payload) => {
        commit("KPI", payload);
    },
    resetProduceReport: ({ commit }, tab) => {
        commit("SET_PRODUCE_HEADERS", tab.link.toLowerCase());
        commit("PRODUCE_DATA", []);
        commit("KPI", { days: 0, values: 0 });
        commit("RANGE", { start: null, end: null });
        // commit("SET_RANGE_YTD");
        commit("CHART_OPTIONS_TITLE", "");
        commit("CHART_SERIES", { name: "", data: [] });
    },
    fetchProduceOrganizations: async ({ commit }, type) => {
        try {
            const path = `api/Report/Produce/Organization/${type}`;
            const response = await axios.get(path);
            const organizations = response.data.sort(sortByNameRuAscending);
            organizations.unshift(state.organization.all)
            commit("PRODUCE_ORGANIZATIONS", organizations);
            commit("LOADING", false);
        } catch (e) {
            const message = `${state.errorMessages.organization}: ${e.message}`;
            commit("ERROR_MESSAGE", message);
            commit("ERROR", true);
        }
    },
    fetchProduceData: async ({ commit }, options) => {
        try {
            commit("LOADING", true);
            const path = state.optionsProduce.path.base + options.path;
            const response = await axios.get(path, { params: options.params });
            const data = response.data;
            if (data.length > 0) {
                const reduceDates = convertDataChart(data)
                const chartTitle = `График по всем нефтедобывающим организациям за период ${format(new Date(options.params.date_from), "dd-MM-yyyy")} - ${format(new Date(options.params.date_to), "dd-MM-yyyy")}`;
                const series = {
                    name: chartTitle,
                    data: reduceDates
                };
                commit("CHART_OPTIONS_TITLE", chartTitle);
                commit("CHART_SERIES", series);
                // console.log(reduceDates)
            } else {
                commit("CHART_OPTIONS_TITLE", "");
                commit("CHART_SERIES", { name: "", data: [] });
            }
            commit("PRODUCE_DATA", data);
            const days = differenceInDays(
                Date.parse(options.params.date_to),
                Date.parse(options.params.date_from)
            );
            const values = data.reduce(
                (accumulator, currentValue) =>
                    accumulator + parseInt(currentValue.mass, 10),
                0
            );
            commit("KPI", { days, values });
            commit("LOADING", false);
        } catch (e) {
            const message = `${state.errorMessages.report}: ${e.message}`;
            commit("ERROR_MESSAGE", message);
            commit("ERROR", true);
        }
    },
    resetTransportReport: ({ commit }, tab) => {
        commit("TRANSPORT_DATA", []);
        commit("KPI", { days: 0, values: 0 });
        commit("RANGE", { start: null, end: null });
        // commit("SET_RANGE_YTD");
        commit("CHART_OPTIONS_TITLE", "");
        commit("CHART_SERIES", { name: "", data: [] });
    },
    fetchTransportOrganizations: async ({ commit, state }, type) => {
        try {
            const path = `api/Report/Transport/Organization/${type}`;
            const response = await axios.get(path);
            const organizations = response.data.sort(sortByNameRuAscending);
            organizations.unshift(state.organization.all)
            commit("TRANSPORT_ORGANIZATIONS", organizations);
            commit("LOADING", false);
        } catch (e) {
            const message = `${state.errorMessages.organization}: ${e.message}`;
            commit("ERROR_MESSAGE", message);
            commit("ERROR", true);
        }
    },
    fetchTerminalsOrganizations: async ({ commit, state }) => {
        try {
            const path = `api/Report/Terminals/Organization/`;
            const response = await axios.get(path);
            const organizations = response.data.sort(sortByNameRuAscending);
            organizations.unshift(state.organization.all)
            commit("TERMINALS_ORGANIZATIONS", organizations);
            commit("LOADING", false);
        } catch (e) {
            const message = `${state.errorMessages.organization}: ${e.message}`;
            commit("ERROR_MESSAGE", message);
            commit("ERROR", true);
        }
    },
    fetchTransportData: async ({ commit }, options) => {
        try {
            commit("LOADING", true);
            const path = state.optionsTransport.path.base + options.path;
            const response = await axios.get(path, { params: options.params });
            const data = response.data;
            if (data.length > 0) {
                const reduceDates = convertDataChart(data)
                const chartTitle = `График по всем нефтетранспортным организациям за период ${format(new Date(options.params.date_from), "dd-MM-yyyy")} - ${format(new Date(options.params.date_to), "dd-MM-yyyy")}`;
                const series = {
                    name: chartTitle,
                    data: reduceDates
                };
                commit("CHART_OPTIONS_TITLE", chartTitle);
                commit("CHART_SERIES", series);
            } else {
                commit("CHART_OPTIONS_TITLE", "");
                commit("CHART_SERIES", { name: "", data: [] });
            }
            commit("TRANSPORT_DATA", data);
            const days = differenceInDays(
                Date.parse(options.params.date_to),
                Date.parse(options.params.date_from)
            );
            const values = data.reduce(
                (accumulator, currentValue) =>
                    accumulator + parseInt(currentValue.mass, 10),
                0
            );
            commit("KPI", { days, values });
            commit("LOADING", false);
        } catch (e) {
            const message = `${state.errorMessages.report}: ${e.message}`;
            commit("ERROR_MESSAGE", message);
            commit("ERROR", true);
        }
    },
    resetRefineryReport: ({ commit }, tab) => {
        commit("SET_REFINERY_HEADERS", tab.link.toLowerCase());
        commit("REFINERY_DATA", []);
        commit("KPI", { days: 0, values: 0 });
        commit("RANGE", { start: null, end: null });
        // commit("SET_RANGE_YTD");
        commit("CHART_OPTIONS_TITLE", "");
        commit("CHART_SERIES", { name: "", data: [] });
    },
    resetTerminalsReport: ({ commit }, tab) => {
        commit("SET_TERMINALS_HEADERS", tab.link.toLowerCase());
        commit("TERMINALS_DATA", []);
        commit("KPI", { days: 0, values: 0 });
        commit("RANGE", { start: null, end: null });
        commit("CHART_OPTIONS_TITLE", "");
        commit("CHART_SERIES", { name: "", data: [] });
    },
    fetchRefineryOrganizations: async ({ commit, state }, type) => {
        try {
            const path = `api/Report/Refinery/Organization/${type}`;
            const response = await axios.get(path);
            const organizations = response.data.sort(sortByNameRuAscending);
            organizations.unshift(state.organization.all)
            commit("REFINERY_ORGANIZATIONS", organizations);
            commit("LOADING", false);
        } catch (e) {
            const message = `${state.errorMessages.organization}: ${e.message}`;
            commit("ERROR_MESSAGE", message);
            commit("ERROR", true);
        }
    },

    fetchRefineryData: async ({ commit }, options) => {
        try {
            commit("LOADING", true);
            const path = state.optionsRefinery.path.base + options.path;
            const response = await axios.get(path, { params: options.params });
            const data = response.data;
            if (data.length > 0) {
                const reduceDates = convertDataChart(data)
                const chartTitle = `График по всем нефтеперерабатывающим организациям за период ${format(new Date(options.params.date_from), "dd-MM-yyyy")} - ${format(new Date(options.params.date_to), "dd-MM-yyyy")}`;
                const series = {
                    name: chartTitle,
                    data: reduceDates
                };
                commit("CHART_OPTIONS_TITLE", chartTitle);
                commit("CHART_SERIES", series);
            } else {
                commit("CHART_OPTIONS_TITLE", "");
                commit("CHART_SERIES", { name: "", data: [] });
            }
            commit("REFINERY_DATA", data);
            const days = differenceInDays(
                Date.parse(options.params.date_to),
                Date.parse(options.params.date_from)
            );
            const values = data.reduce(
                (accumulator, currentValue) =>
                    accumulator + parseInt(currentValue.mass, 10),
                0
            );
            commit("KPI", { days, values });
            commit("LOADING", false);
            return response;
        } catch (e) {
            const message = `${state.errorMessages.report}: ${e.message}`;
            commit("ERROR_MESSAGE", message);
            commit("ERROR", true);
        }
    },
    fetchTerminalsData: async ({ commit }, options) => {
        try {
            commit("LOADING", true);
            const path = state.optionsTerminals.path.base + options.path;
            const response = await axios.get(path, { params: options.params });
            const data = response.data;
            if (data.length > 0) {
                const reduceDates = convertDataChart(data)
                const chartTitle = `График по всем нефтяным терминалам за период ${format(new Date(options.params.date_from), "dd-MM-yyyy")} - ${format(new Date(options.params.date_to), "dd-MM-yyyy")}`;
                const series = {
                    name: chartTitle,
                    data: reduceDates
                };
                commit("CHART_OPTIONS_TITLE", chartTitle);
                commit("CHART_SERIES", series);

            } else {
                commit("CHART_OPTIONS_TITLE", "");
                commit("CHART_SERIES", { name: "", data: [] });
            }
            commit("TERMINALS_DATA", data);
            const days = differenceInDays(
                Date.parse(options.params.date_to),
                Date.parse(options.params.date_from)
            );
            const values = data.reduce(
                (accumulator, currentValue) =>
                    accumulator + parseInt(currentValue.mass, 10),
                0
            );
            commit("KPI", { days, values });
            commit("LOADING", false);
        } catch (e) {
            const message = `${state.errorMessages.report}: ${e.message}`;
            commit("ERROR_MESSAGE", message);
            commit("ERROR", true);
        }
    },
    resetFactoriesReport: ({ commit }) => {
        commit("FACTORIES_DATA", []);
        commit("KPI", { days: 0, values: 0 });
        commit("RANGE", { start: null, end: null });
        commit("CHART_OPTIONS_TITLE", "");
        commit("CHART_SERIES", { name: "", data: [] });
    },

    fetchFactoriesOrganizations: async ({ commit, state }) => {
        try {
            //const path = state.organization.path.base + state.optionsFactories.facilityType + state.organization.path.sub;
            const path = `api/Report/Factories/Organization`;
            const { data } = await axios.get(path);
            commit("FACTORIES_ORGANIZATIONS", data.sort(sortByNameRuAscending));
            commit("LOADING", false);
        } catch (e) {
            const message = `${state.errorMessages.organization}: ${e.message}`;
            commit("ERROR_MESSAGE", message);
            commit("ERROR", true);
        }
    },

    fetchFactoriesData: async ({ commit }, options) => {
        try {
            commit("LOADING", true);
            const path = state.optionsFactories.path.base + options.path;
            const response = await axios.get(path, { params: options.params });
            const data = response.data;
            if (data.length > 0) {
                const reduceDates = convertDataChart(data)
                const chartTitle = `График по всем битумным заводам за период ${format(new Date(options.params.date_from), "dd-MM-yyyy")} - ${format(new Date(options.params.date_to), "dd-MM-yyyy")}`;
                const series = {
                    name: chartTitle,
                    data: reduceDates
                };
                commit("CHART_OPTIONS_TITLE", chartTitle);
                commit("CHART_SERIES", series);
            } else {
                commit("CHART_OPTIONS_TITLE", "");
                commit("CHART_SERIES", { name: "", data: [] });
            }
            commit("FACTORIES_DATA", data);
            const days = differenceInDays(
                Date.parse(options.params.date_to),
                Date.parse(options.params.date_from)
            );
            const values = data.reduce(
                (accumulator, currentValue) =>
                    accumulator + parseInt(currentValue.mass, 10),
                0
            );
            commit("KPI", { days, values });
            commit("LOADING", false);
        } catch (e) {
            const message = `${state.errorMessages.report}: ${e.message}`;
            commit("ERROR_MESSAGE", message);
            commit("ERROR", true);
        }
    },

    fetchAnomaliesData: async ({ commit }) => {
        try {
            const path = state.optionsAnomalies.path.base;
            const pathMin = state.optionsAnomalies.path.min;
            const pathMax = state.optionsAnomalies.path.max;
            commit("LOADING", true);
            const maxResponse = await axios.get(path + pathMax);
            commit("ANOMALIES_MAX_DATA", maxResponse.data);
            const minResponse = await axios.get(path + pathMin);
            commit("ANOMALIES_MIN_DATA", minResponse.data);
            commit("LOADING", false);
            commit("ANOMALIES_ITEM_SELECT", "max");
        } catch (e) {
            const message = `${state.errorMessages.report}: ${e.message}`;
            commit("ERROR_MESSAGE", message);
            commit("ERROR", true);
        }
    },
    selectAnomaliesData: async ({ commit }, index) => {
        commit("ANOMALIES_ITEM_SELECT", index);
    },
    resetDataTransferAuditReport: ({ commit }) => {
        commit("TRANSFER_AUDIT_DATA", []);
        commit("RANGE", { start: null, end: null });
    },
    fetchDataTransferAuditOrganizations: async ({ commit, state }) => {
        try {
            const path =
                state.organization.path.base +
                state.optionsDataTransferAudit.facilityType +
                state.organization.path.sub;
            const response = await axios.get(path);
            commit(
                "TRANSFER_AUDIT_ORGANIZATIONS",
                response.data.sort(sortByNameRuAscending)
            );
            commit("LOADING", false);
        } catch (e) {
            const message = `${state.errorMessages.organization}: ${e.message}`;
            commit("ERROR_MESSAGE", message);
            commit("ERROR", true);
        }
    },
    fetchDataTransferAuditData: async ({ commit }, options) => {
        try {
            // const path = state.optionsDataTransferAudit.path.base + options.path;
            const path = state.optionsDataTransferAudit.path.base;
            // const params = {
            //     org: options.params.organizationId,//TODO Унифицировать наименование переменных
            //     date_from: options.params.date_from,
            //     date_to: options.params.date_to
            // }
            commit("LOADING", true);
            // const response = await axios.get(path, { params });
            const response = await axios.get(path);
            const data = response.data;

            commit("TRANSFER_AUDIT_DATA", data);
            commit("LOADING", false);
        } catch (e) {
            const message = `${state.errorMessages.report}: ${e.message}`;
            commit("ERROR_MESSAGE", message);
            commit("ERROR", true);
        }
    },
    resetUsersActivityReport: ({ commit }) => {
        commit("LOADING", false);
        commit("REFINERY_DATA", []);
        commit("RANGE", { start: null, end: null });
    },
    fetchUsersActivityData: async ({ commit }, options) => {
        try {
            const path = state.optionsUsersActivity.path.base;
            const url = `${path}/${options.params.date_from}/${options.params.date_to}`;
            commit("LOADING", true);
            const response = await axios.get(url);
            const data = response.data;
            commit("USERS_ACTIVITY_DATA", data);
            commit("LOADING", false);
        } catch (e) {
            const message = `${state.errorMessages.report}: ${e.message}`;
            commit("ERROR_MESSAGE", message);
            commit("ERROR", true);
        }
    },
    resetRange: ({ commit }) => {
        commit("RANGE", { start: null, end: null });
    },
    currentTab: ({ commit }, data) => {
        commit("CURRENT_TAB", data);
    },
    fetchKPIOrganizations: async ({ commit }) => {
        try {
            const path = `api/Analytic/Plan/Organizations`;
            const response = await axios.get(path);
            const organizations = response.data
            commit("KPI_ORGANIZATIONS", organizations);
            commit("LOADING", false);
        } catch (e) {
            const message = `${state.errorMessages.organization}: ${e.message}`;
            commit("ERROR_MESSAGE", message);
            commit("ERROR", true);
        }
    },
    fetchKPIPlans: async ({ commit }, options) => {
        const path = {
            'produce': `api/Analytic/Plan/Production`,
            'refinery': `api/Analytic/Plan/Refinery`,
            'export': `api/Analytic/Plan/Export`,
        };
        try {
            const response = await axios.get(`${path[options.type]}/?bin=${options.bin}&year=${options.year}`);
            const plan = response.data
            commit("SET_KPI_DATA", { type: options.type, plan });
            commit("LOADING", false);
        } catch (e) {
            const message = `${state.errorMessages.organization}: ${e.message}`;
            commit("ERROR_MESSAGE", message);
            commit("ERROR", true);
        }
    },
    fetchCustomerKPIPlans: async ({ commit }) => {
        const bin = localStorage.getItem("bin");
        const year = new Date().getFullYear();
        try {
            const response = await axios.get(`api/Analytic/Plan/Customer/?bin=${bin}&year=${year}`);
            const plan = response.data;
            commit("SET_KPI_DATA", { type: "all", plan }); // Предполагается, что у вас есть "all" тип для всех табов
            commit("LOADING", false);
          } catch (e) {
            const message = `${state.errorMessages.organization}: ${e.message}`;
            commit("ERROR_MESSAGE", message);
            commit("ERROR", true);
          }
    },
    uploadPlans: async ({ commit }, options) => {
        const path = {
            'produce': `api/Analytic/Plan/Production/Upload`,
            'refinery': `api/Analytic/Plan/Refinery/Upload`,
            'export': `api/Analytic/Plan/Export/Upload`,
        };
        try {
            const response = await axios.post(`${path[options.type]}`, options.data);
            return(response); // Добавлено возвращение response
        } catch (error) {
            const message = `${state.errorMessages.organization}: ${error.message}`;
            commit("ERROR_MESSAGE", message);
            commit("ERROR", true);
        }
    }
};

const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

function convertDataChart(data) {
    // Данная система построения графиков (ApexChart)
    // по умолчанию воспринимает время в UTC.
    // Для отключения этой опции необходимо установить labels.datetimeUTC = false
    // При использовании утилит, типа format Date-FNS, эта опция просто должна быть удалена,
    // что равносильно состоянию true
    const reduceDates = [];
    const dates = data.map(item => ({
        x: format(new Date(item.date), 'yyyy-MM-dd'),
        y: item.mass
    }));
    dates.forEach((item) => {
        const currentDate = item.x;
        const currentIndex = reduceDates.findIndex(item => item.x === currentDate);
        currentIndex == -1
            ? reduceDates.push(item)
            : reduceDates[currentIndex].y += item.y
    });
    // console.log(reduceDates)
    return reduceDates;
}