import { make, set } from 'vuex-pathify'

const state = {
    show: {
        facility: true,
        deposit: true,
        pipeline: true,
        oilexport: true,
        transport: true,
        grey: true,
        red: true,
        yellow: true,
        green: true,
    },
}

const mutations = make.mutations(state)

const actions = {
    // Вариант 1
    // Скрываем всю именованную группу
    // Вариант 2
    // Скрываем точечно только конкретный слой из группы
    // Но у этого способа есть косяк, до сих пор не решенный - https://gis.stackexchange.com/questions/254248/openlayers-3-setvisible-not-working-until-map-clicked
    // После вызова layer.setVisible(visible) необходимо вызывать this.map.updateSize()
    // const layer = this.depositLayerGroup.getLayers().getArray()[0];
    // layer.setVisible(visible);
    // this.map.updateSize();

    // showDeposit: ({ commit }, visible) => {
    //     state.depositLayerGroup.setVisible(visible);
    // },
    // showPipeline: ({ commit }, visible) => {
    //     state.pipelineLayerGroup.setVisible(visible);
    // },
    // showFacility: ({ commit }, visible) => {
    //     const facilityContainer = document.getElementsByClassName(
    //         "show-facility"
    //     );
    //     Array.prototype.forEach.call(facilityContainer, (item, index) => {
    //         visible ? (item.style.display = "") : (item.style.display = "none");
    //     });
    // },
}

const getters = {}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}