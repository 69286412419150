<template>
  <v-card outlined tile>
    <v-row>
      <v-col cols="12" class="px-5 pb-0">
        <default-chart-title :title="title" />
      </v-col>
      <v-col>
        <v-row>
          <v-col cols="6" align="left" class="pl-5">
            <v-btn
              elevation="5"
              class="ml-5"
              v-for="action in actions"
              :key="`action-${action.id}`"
              text
              :to="action.link"
            >
              <v-icon left class="mr-1" small v-text="action.icon" />
              {{ action.text }}
            </v-btn>
          </v-col>
          <v-col cols="6" align="right" class="px-15 pb-0">
            <chart-filter-button
              title="Неделя"
              @click="() => appChart.showChartForLastWeek()"
              class="mr-2"
            />
            <chart-filter-menu
              :title="appChart.displayMonth + ' Месяц'"
              :interval="appChart.months"
              :handler="index => appChart.showChartForMonth(index)"
            />
            <chart-filter-button
              title="9 месяцев"
              @click="() => appChart.showChartForNineMonths()"
              class="mr-2"
            />  
            <chart-filter-menu
              :title="appChart.displayQuarter + ' Квартал'"
              :interval="appChart.quarter"
              :handler="index => appChart.showChartForQuarter(index)"
            />
            <chart-filter-menu
              :title="appChart.displayYear + ' год'"
              :interval="appChart.years"
              :handler="index => appChart.showChartForYear(index)"
            />
          </v-col>
        </v-row>
      </v-col>

      <!-- <v-col cols="12"> -->
      <!--убрано временно - по указанию Жанарыса  - 04.02.2021-->
      <!-- <default-chart-performance-indicators
                :actual="valueTotal"
                planned="0"
              /> -->
      <!-- </v-col> -->
      <v-col cols="12">
        <apex-chart-card
          v-if="appChart.chartOptions"
          :series="appChart.chartOptions.series"
          :options="appChart.chartOptions.options"
        >
          <template #subtitle>
            <div class="font-weight-light white--text">
              <div
                v-html="
                  appChart.loading
                    ? appChart.loadingMessage
                    : appChart.chartOptions.subtitle
                "
              />
            </div>
          </template>
          <template #actions>
            <v-icon class="mr-1" small> mdi-clock-outline </v-icon>
            <span
              class="text-caption white--text font-weight-light"
              v-text="appChart.chartOptions.time"
            />
          </template>
        </apex-chart-card>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { Vue } from "vue-property-decorator";
import { get } from "vuex-pathify";
import { Chart } from "@/services/apexchart.service.js";

export default Vue.extend({
  name: "ApexBaseChart",
  components: {
    ApexChartCard: () => import("@/components/chart/ApexChartCard.vue"),
    DefaultChartTitle: () => import("@/components/chart/ChartTitle.vue"),
    DefaultChartPerformanceIndicators: () =>
      import("@/components/chart/ChartPerformanceIndicators.vue"),
    ChartFilterButton: () => import("@/components/chart/ChartFilterButton.vue"),
    ChartFilterMenu: () => import("@/components/chart/ChartFilterMenu.vue")
  },
  props: {
    title: String,
    direction: String,
    type: {
      type: String,
      required: true,
      validator: v => ["bar", "line", "column"].includes(v)
    },
    actions: Array
  },
  data() {
    return {
      appChart: null
    };
  },
  computed: {
    // months: get("app/shortRU"),
    months: get("app/longRU")
  },
  methods: {
    init() {
      this.appChart = new Chart({
        title: this.title,
        type: this.type.trim().toLowerCase(),
        direction: this.direction,
        months: this.months
      });
      // console.log(this.months);
    }
  },
  beforeMount() {
    this.init();
    this.appChart.showChartForLastWeek();
  }
});
</script>
