<template>
  <v-container class="lighten-5 mb-6 mt-6 mx-0 px-0" fluid>
    <default-section-title
      title="ПРОИЗВОДСТВЕННЫЙ ГРАФИК"
      icon="mdi-account-hard-hat"
    />
    <v-row>
      <v-col>
       <template v-if="organizationTypeAllowed(['10'])">
          <apex-default-chart
            title="Сдача в нефтепровод"
            direction="process"
            type="line"
            :actions="actions.produce"
          />
        </template>
        <template v-if="organizationTypeAllowed(['11'])">
          <apex-default-chart
            title="Прием в нефтепровод"
            direction="delivery"
            type="bar"
            :actions="actions.transport"
          />
          <apex-default-chart
            title="Сдача с нефтепровода"
            direction="shipment"
            type="bar"
            :actions="actions.transport"
          />
        </template>
          <template v-if="organizationTypeAllowed(['13', '14'])">
          <apex-default-chart
            title="Поставка от транспортных организаций"
            direction="refinery"
            type="line"
            :actions="actions.crefinery"
          />
        </template>
        <template v-if="organizationTypeAllowed(['10', '16'])">
          <apex-default-chart
            title="Подготовка"
            direction="preparation"
            type="bar"
            :actions="actions.produce"
          />
        </template>
        <template v-if="organizationTypeAllowed(['10', '13', '14', '16'])">
          <apex-default-chart
            title="Хранение"
            direction="storage"
            type="bar"
          />
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Vue } from "vue-property-decorator";

export default Vue.extend({
  components: {
    ApexDefaultChart: () => import("@/components/chart/ApexBaseChart.vue"),
    ApexExtendedChart: () => import("@/components/chart/ApexExtendedChart.vue"),
    DefaultSectionTitle: () => import("@/components/SectionTitle.vue")
  },

  data() {
    return {
      actions: {
        produce: [
          {
            icon: "mdi-finance",
            text: "Смотреть отчеты...",
            link: "/app/customer/creport/cproduce"
          }
        ],
        transport: [
          {
            icon: "mdi-finance",
            text: "Смотреть отчеты...",
            link: "/app/customer/creport/ctransport"
          }
        ],
        refinery: [
          {
            icon: "mdi-finance",
            text: "Смотреть отчеты - Прием на НПЗ",
            link: "/app/customer/creport/crefinery"
          },
          {
            icon: "mdi-finance",
            text: "Смотреть отчеты - Экспорт",
            link: "/app/customer/creport/ctransport",
            tab: {
              id: 4,
              name: "Экспорт",
              link: "4",
              disabled: false
            }
          }
        ],
        crefinery: [
          {
            icon: "mdi-finance",
            text: "Смотреть отчеты...",
            link: "/app/customer/creport/crefinery"
          }
        ],
      }
    };
  },
  methods: {
    // Метод для проверки, входит ли тип организации в список разрешенных для отображения графиков
    organizationTypeAllowed(allowedTypes) {
      const organizationType = localStorage.getItem("organizationType");
      return allowedTypes.includes(organizationType);
    }
  }
});
</script>
