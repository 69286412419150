<template>
  <v-dialog v-model="showULA" max-width="67%">
    <v-card>
      <v-card-text>
        <pdf
          v-for="i in numPages"
          :key="i"
          :src="src"
          :page="i"
          style="display: inline-block; width: 100%"
        ></pdf>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn text @click="close">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import pdf from "vue-pdf";
//var loadingTask = pdf.createLoadingTask("/downloads/isun-ula.pdf");

export default {
  name: "ULA",
  components: {
    pdf,
  },
  props: {
    showULA: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      src: null, //loadingTask,
      numPages: undefined,
    };
  },
  methods: {
    close() {
      this.$emit("close-ula");
    },
  },
  created() {
    this.src = pdf.createLoadingTask("/downloads/isun-ula.pdf");
    this.src.promise
      .then((pdf) => {
        this.numPages = pdf.numPages;
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>