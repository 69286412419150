<template>
  <v-card outlined tile>
    <v-row class="pt-5">
      <v-col cols="12">
        <apex-chart-card
          :series="series"
          :options="appChart.chartOptions.options"
        />
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { Vue } from "vue-property-decorator";
import { get } from "vuex-pathify";
import { Chart } from "@/services/apexchart.kpi.service.js";

export default Vue.extend({
  name: "KPIChart",
  components: {
    ApexChartCard: () => import("@/components/chart/ApexChartCard.vue")
  },
  props: {
    title: String,
    direction: String,
    type: {
      type: String,
      required: true,
      validator: v => ["bar", "line", "column"].includes(v)
    },
    rows: Array
  },
  data() {
    return {
      appChart: null
    };
  },
  computed: {
    months: get("app/longRU"),
    series() {
      return this.setSeries();
    }
  },
  watch: {
    title: function(newVal) {
      this.appChart = new Chart({
        title: newVal,
        type: this.type.trim().toLowerCase(),
        direction: this.direction,
        months: this.months
      });
    }
  },

  methods: {
    setSeries() {
      const items = [
        {
          name: "План",
          type: "line",
          data: []
        },
        {
          name: "Факт",
          type: "line",
          data: []
        }
      ];
      const plans = [];
      const facts = [];

      this.rows.forEach(item => {
        plans.push(item.plan);
        facts.push(item.fact);
      });

      items[0].data = plans;
      items[1].data = facts;

      return [items[0], items[1]];
    },
    init() {
      this.appChart = new Chart({
        title: this.title,
        type: this.type.trim().toLowerCase(),
        direction: this.direction,
        months: this.months
      });
    }
  },
  beforeMount() {
    this.init();
  },
  async mounted() {}
});
</script>
