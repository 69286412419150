<template>
  <v-container fluid class="mx-0 px-0">
    <v-slide-y-transition mode="out-in">
      <v-card class="pa-2" outlined tile>
        <industry-tool-bar
          :title="options.title.secondary"
          :counter="options.items.length.toString()"
        >
        </industry-tool-bar>
        <industry-table
          :headers="options.headers"
          :items="options.items"
          @info-item="infoItem($event)"
        />
      </v-card>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
import { Vue } from "vue-property-decorator";
import { get } from "vuex-pathify";
import { mapActions } from "vuex";
import { ORGANIZATION_TYPES } from "@/util/globals";
import { PRODUCTION_FACILITY_TYPES } from "@/util/globals";  

export default Vue.extend({
  name: "IndustryOrganization.vue",
  components: {
    IndustryTable: () =>
      import("@/components/reference/industry/IndustryTable.vue"),
    IndustryToolBar: () =>
      import("@/components/reference/industry/IndustryToolBar.vue"),
  },
  computed: {
    options: get("reference/optionsIndustry"),
  },
  watch: {
    facilityType(type) {
      this.items = this.fetchIndustry(type);
    },
  },
  data() {
    return {
      pathItem: "/app/reference/industry/item/",
    };
  },
  props: {
    facilityType: {
      type: Number,
      default: ORGANIZATION_TYPES.Produce,
      validator: (v) => Object.values(ORGANIZATION_TYPES).includes(v),
    },
  },
  methods: {
    ...mapActions({
      fetchIndustry: "reference/fetchIndustryOrganizations",
      setIndustryItem: "reference/setIndustryOrganization",
    }),
    initialize() {
      this.fetchIndustry(10);
    },
    infoItem(itemId) {
      this.setIndustryItem(itemId);
      // this.$store.dispatch("reference/setIndustryOrganization", itemId);
      const item = JSON.stringify(this.options.item);
      localStorage.setItem("item", item);
      this.$router.push({ path: this.pathItem + `${itemId}` }).catch((e) => {});
    },
  },
  created() {
    this.initialize();
  },
});
</script>