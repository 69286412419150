import axios from "axios";

const url = {
    refine: "api/Map/Refine",
    produce: "api/Map/Produce",
    export: "api/Map/Export",
    transport: "api/Map/Transport",
};

const getRefineData = async () => await getMapDatas(url.refine);
const getProduceData = async () => await getMapDatas(url.produce);
const getExportData = async () => await getMapDatas(url.export);
const getTransportData = async () => await getMapDatas(url.transport);

const getMapDatas = async (path) => {
    try {
        const response = await axios.get(path);
        //console.log(`Success load from ${path}: `, response);
        return response
    } catch (error) {
        console.log(`Error load from ${path}: `, error.message);
    }
};

export { getRefineData, getProduceData, getExportData, getTransportData }
