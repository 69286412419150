<template>
  <v-card class="py-2">
    <h2 style="text-align: center">{{ title }}</h2>
  </v-card>
</template>
<script>
export default {
  name: "CustomerReportTitle.vue",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>