<template>
  <v-card>
    <v-card-title class="text-h4">Реквизиты</v-card-title>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-item>
        <v-list-item-content>Тип предприятия:</v-list-item-content>
        <v-list-item-content class="align-end">
          {{ organizationType }}
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>БИН:</v-list-item-content>
        <v-list-item-content class="align-end">
          {{ bin }}
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>Адрес:</v-list-item-content>
        <v-list-item-content class="align-end">
          {{ address }}
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>Телефон горячей линии:</v-list-item-content>
        <v-list-item-content class="align-end">
          {{ hotlinePhone == null ? "" : hotlinePhone }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>
<script>
export default {  
  name: "ItemProps",
  props: {
    organizationType: String,
    bin: String,
    address: String,
    hotlinePhone: String
  }
};
</script>
