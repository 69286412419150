import axios from "axios";

const path = {
    productionFacility: "api/Reference/Organization/ProductionFacility/",
    organizationContact: "api/Reference/Organization/Contact/",
    organization: "api/Reference/Organization/",
};

// const getPath = (url) => path[url.trim().toLowerCase()];

const getProductionFacilitiesByOrganizationId = async (organizationId) => {
    try {
        const response = await axios.get(path.productionFacility + `${organizationId}`);
        const data = response.data
        // console.log(`Success load from ${path}: `, data);
        return data
    } catch (error) {
        console.log(`Error load from ${path}: `, error.message);
    }
};

const getContactByOrganizationId = async (organizationId) => {
    try {
        const response = await axios.get(path.organizationContact + `${organizationId}`);
        const data = response.data
        // console.log(`Success load from ${path}: `, data);
        return data
    } catch (error) {
        console.log(`Error load from ${path}: `, error.message);
    }
};
const getOrganizationById = async (organizationId) => {
    try {
        const response = await axios.get(path.organization + `${organizationId}`);
        const data = response.data
        // console.log(`Success load from ${path}: `, data);
        return data
    } catch (error) {
        console.log(`Error load from ${path}: `, error.message);
    }
};


export { getProductionFacilitiesByOrganizationId, getContactByOrganizationId, getOrganizationById }
