<template>
  <transition name="fade" mode="out-in">
    <v-form ref="form" v-model="valid" lazy-validation justify-center>
      <v-row justify="space-around" class="px-0">
        <v-col cols="4" v-if="organizations">
          <v-autocomplete
            v-model="organizationId"
            :items="organizations"
            item-text="nameRu"
            item-value="id"
            dense
            filled
            :rules="[v => v != null || 'Item is required']"
            label="Выберите организацию"
            required
          ></v-autocomplete>
          <!-- :rules="[(v) => !!v || 'Item is required']" -->
        </v-col>
        <v-col cols="3">
          <report-date-picker :rangeValue="range.start" />
        </v-col>
        <v-col cols="3">
          <report-date-picker :rangeValue="range.end" />
        </v-col>
        <v-col cols="2" align="center">
          <div>
            <v-btn-toggle>
              <v-btn rounded color="primary" dark @click="loadReport">
                Показать отчет
              </v-btn>
              <v-btn color="secondary" dark @click="exportTable">
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </v-btn-toggle>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </transition>
</template>
<script>
import { get, sync } from "vuex-pathify";

export default {
  name: "ReportFilter.vue",
  components: {
    ReportDatePicker: () => import("@/components/report/ReportDatePicker.vue")
  },
  computed: {
    ...sync("report", ["range"]),
    ...get("report", ["currentTab"])
  },
  watch: {
    currentTab() {
      this.resetFilter();
    }
  },
  props: {
    organizations: { type: Array }
  },
  data() {
    return {
      valid: true,
      organizationId: 0
    };
  },
  methods: {
    exportTable() {
      this.$emit("export-table", this.currentTab);
    },
    loadReport() {
      if (
        this.$refs.form.validate() &&
        this.range.start.value != null &&
        this.range.end.value != null
      ) {
        const options = {
          path: this.currentTab.link,
          params: {
            organizationId: this.organizationId,
            date_from: this.range.start.value,
            date_to: this.range.end.value
          }
        };
        this.$emit("load-report", options);
      }
    },
    resetFilter() {
      this.$refs.form.reset();
    }
  },
  mounted() {
    // форма приходит пустая при маунте и при смене табы
    // если перенесу organizations из пропсов в стор, надо будет ресетить организации
    // потому что если я перейду от одной страницы к другой, то старые организации сохранятся
    // this.resetFilter();
  }
};
</script>
<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
