<template>
  <div>
    <!--убрано временно - по указанию Жанарыса  - 04.02.2021-->
    <!-- <v-row no-gutters>
      <v-col cols="4">
        <p class="font-weight-bold text-center text--accent-1">
          Факт: &nbsp;{{ actual }}
        </p>
      </v-col>
      <v-col cols="4">
        <p class="font-weight-bold text-center text--accent-1">
          План: &nbsp;{{ planned }}
        </p>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
export default {
  name: "ChartPerformanceIndicators",
  components: {},
  props: {
    actual: 0,
    planned: 0,
  },
};
</script>