<template>
  <material-card
    v-bind="$attrs"
    class="v-apex-card--material-chart"
    full-header
  >
    <template #heading>
      <div class="px-5">
        <apexchart
          :options="options"
          :series="series"
          :height="height"
          style="margin: left"
        ></apexchart>
      </div>
    </template>

    <template #subtitle>
      <slot name="subtitle" />
    </template>

    <slot />

    <template #actions>
      <slot name="actions" />
    </template>
  </material-card>
</template>

<script>
export default {
  name: "ApexChartCard",
  inheritAttrs: false,
  props: {
    options: Object,
    series: Array,
  },
  data() {
    return {
      height: 250,
    };
  },
};
</script>

<style lang="sass">
.apexcharts-tooltip
  background: #000
  color: black

.v-apex-card--material-chart
  p
    color: #999

  .v-apex-card--material__sheet
    max-height: 400px
    width: 100%
</style>
