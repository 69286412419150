// Imports
import Vue from 'vue'
import Router from 'vue-router'
import { trailingSlash } from '@/util/helpers'
import {
  layout,
  route,
} from '@/util/routes'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition
    return { x: 0, y: 0 }
  },
  routes: [
    layout(
      'Public',
      [
        route(
          'Home/Home',
          null,
          {
            title: 'Главная',
            isGuest: true,
          },
          '/'),
        route(
          'Account/Login',
          null,
          {
            title: 'Вход',
            isGuest: true,
          },
          '/auth/login'),
        route(
          'Account/Register',
          null,
          {
            title: 'Регистрация',
            isGuest: true,
          },
          '/auth/register'
        ),
      ]),
    layout(
      'Default',
      [
        route(
          'Dashboard/Dashboard',
          null,
          {
            title: 'Главная',
            requiresAuth: true,
            isAdmin: true,
            isManager: true,
            isSupervisor: true,
            items: [
              {
                text: 'Главная',
                disabled: true,
                href: '',
              }
            ],
          },
          '/app/dashboard'
          ),
          
        route(
          'Report/Produce',
          null,
          {
            title: 'Отчеты/Добыча',
            requiresAuth: true,
            isAdmin: true,
            isManager: true,
            isSupervisor: true,
            items: [
              {
                text: 'Отчеты',
                disabled: true,
                href: '',
              },
              {
                text: 'Производство',
                disabled: true,
                href: '',
              }
            ],
          },
          'app/report/produce'
        ),
        route(
          'Customer/CReport/CProduce',
          null,
          {
            title: 'Отчеты/Добыча',
            requiresAuth: true,
            isCustomer: true,
            items: [
              {
                text: 'Отчеты',
                disabled: true,
                href: '',
              },
              {
                text: 'Производство',
                disabled: true,
                href: '',
              }
            ],
          },
          'app/customer/creport/cproduce' //путь для роли Customer
        ),
        route(
          'Report/Refinery',
          null,
          {
            title: 'Отчеты/Переработка',
            requiresAuth: true,
            isAdmin: true,
            isManager: true,
            isSupervisor: true,
            items: [
              {
                text: 'Отчеты',
                disabled: true,
                href: '',
              },
              {
                text: 'Переработка',
                disabled: true,
                href: '',
              }
            ],
          },
          'app/report/refinery'
        ),
        route(
          'Customer/CReport/CRefinery',
          null,
          {
            title: 'Отчеты/Переработка',
            requiresAuth: true,
            isCustomer: true,
            items: [
              {
                text: 'Отчеты',
                disabled: true,
                href: '',
              },
              {
                text: 'Переработка',
                disabled: true,
                href: '',
              }
            ],
          },
          'app/report/crefinery' //путь для роли Customer
        ),
        route(
          'Report/Transport',
          null,
          {
            title: 'Отчеты/Транспортировка',
            requiresAuth: true,
            isAdmin: true,
            isManager: true,
            isSupervisor: true,
            items: [
              {
                text: 'Отчеты',
                disabled: true,
                href: '',
              },
              {
                text: 'Транспортировка',
                disabled: true,
                href: '',
              }
            ],
          },
          'app/report/transport'
        ),
        route(
          'Report/Factories',
          null,
          {
            title: 'Отчеты/Битумные заводы',
            requiresAuth: true,
            isAdmin: true,
            isManager: true,
            isSupervisor: true,
            items: [
              {
                text: 'Отчеты',
                disabled: true,
                href: '',
              },
              {
                text: 'Заводы',
                disabled: true,
                href: '',
              }
            ],
          },
          'app/report/factories'
        ),
        route(
          'Report/Terminals',
          null,
          {
            title: 'Отчеты/Терминалы',
            requiresAuth: true,
            isAdmin: true,
            isManager: true,
            isSupervisor: true,
            items: [
              {
                text: 'Отчеты',
                disabled: true,
                href: '',
              },
              {
                text: 'Терминалы',
                disabled: true,
                href: '',
              }
            ],
          },
          'app/report/terminals'
        ),
        route(
          'Reference/Organization',
          null,
          {
            title: 'Справочники/Компании',
            requiresAuth: true,
            isAdmin: true,
            isManager: true,
            items: [
              {
                text: 'Справочники',
                disabled: true,
                href: '',
              },
              {
                text: 'Компании',
                disabled: true,
                href: '',
              }
            ],
          },
          'app/reference/organization'
        ),
        route(
          'Reference/Industry/Items',
          null,
          {
            title: 'Справочники/Организации',
            requiresAuth: true,
            isAdmin: true,
            isManager: true,
            isSupervisor: true,
            items: [
              {
                text: 'Справочники',
                disabled: true,
                href: 'app/reference/industry/items',
              },
              {
                text: 'Организации',
                disabled: true,
                href: 'app/reference/industry/items',
              }
            ],
          },
          'app/reference/industry/items'
        ),
        route(
          'Reference/Industry/Item',
          null,
          {
            title: 'Справочники/Организации/Паспорт',
            requiresAuth: true,
            isAdmin: true,
            isManager: true,
            isSupervisor: true,
            items: [
              {
                text: 'Справочники',
                disabled: true,
                href: '',
              },
              {
                text: 'Организации',
                disabled: false,
                href: '/app/reference/industry/items',
              },
              {
                text: 'Паспорт',
                disabled: true,
                href: '',
              }
            ],
          },
          'app/reference/industry/item/:id'
        ),
        route(
          'Reference/OrganizationType',
          null,
          {
            title: 'Справочники/Организации/Типы',
            requiresAuth: true,
            isAdmin: true,
            isManager: true,
            items: [
              {
                text: 'Справочники',
                disabled: true,
                href: '',
              },
              {
                text: 'Организации/Типы',
                disabled: true,
                href: '',
              }
            ],
          },
          'app/reference/organization_type'
        ),
        route(
          'Reference/Contact',
          null,
          {
            title: 'Справочники/Контакты',
            requiresAuth: true,
            isAdmin: true,
            isManager: true,
            isSupervisor: true,
            items: [
              {
                text: 'Справочники',
                disabled: true,
                href: '',
              },
              {
                text: 'Контакты',
                disabled: true,
                href: '',
              }
            ],
          },
          'app/reference/contact'
        ),
        route(
          'Reference/Region',
          null,
          {
            title: 'Справочники/Регионы',
            requiresAuth: true,
            isAdmin: true,
            isManager: true,
            isSupervisor: true,
            items: [
              {
                text: 'Справочники',
                disabled: true,
                href: '',
              },
              {
                text: 'Регионы',
                disabled: true,
                href: '',
              }
            ],
          },
          'app/reference/region'
        ),
        route(
          'Reference/ProductionFacility',
          null,
          {
            title: 'Справочники/Объекты',
            requiresAuth: true,
            isAdmin: true,
            isManager: true,
            isSupervisor: true,
            items: [
              {
                text: 'Справочники',
                disabled: true,
                href: '',
              },
              {
                text: 'Объекты',
                disabled: true,
                href: '',
              }
            ],
          },
          'app/reference/production_facility'
        ),
        route(
          'Reference/Contract/Items',
          null,
          {
            title: 'Справочники/Контракты',
            requiresAuth: true,
            isAdmin: true,
            isManager: true,
            isSupervisor: true,
            items: [
              {
                text: 'Справочники',
                disabled: true,
                href: '',
              },
              {
                text: 'Контракты',
                disabled: true,
                href: '',
              }
            ],
          },
          'app/reference/contract/items'
        ),
        route(
          'Reference/ProductionFacilityType',
          null,
          {
            title: 'Справочники/Объекты/Типы',
            requiresAuth: true,
            isAdmin: true,
            isManager: true,
            items: [
              {
                text: 'Справочники',
                disabled: true,
                href: '',
              },
              {
                text: 'Объекты/Типы',
                disabled: true,
                href: '',
              }
            ],
          },
          'app/reference/production_facility_type'
        ),
        route(
          'Reference/Status',
          null,
          {
            title: 'Справочники/Статусы',
            requiresAuth: true,
            isAdmin: true,
            isManager: true,
            items: [
              {
                text: 'Справочники',
                disabled: true,
                href: '',
              },
              {
                text: 'Статусы',
                disabled: true,
                href: '',
              }
            ],
          },
          'app/reference/status'
        ),
        route(
          'Reference/Pipeline',
          null,
          {
            title: 'Справочники/Нефтепроводы',
            requiresAuth: true,
            isAdmin: true,
            isManager: true,
            isSupervisor: true,
            items: [
              {
                text: 'Справочники',
                disabled: true,
                href: '',
              },
              {
                text: 'Нефтепроводы',
                disabled: true,
                href: '',
              }
            ],
          },
          'app/reference/pipeline'
        ),
        route(
          'Reference/Operation',
          null,
          {
            title: 'Справочники/Операции',
            requiresAuth: true,
            isAdmin: true,
            isManager: true,
            items: [
              {
                text: 'Справочники',
                disabled: true,
                href: '',
              },
              {
                text: 'Операции',
                disabled: true,
                href: '',
              }
            ],
          },
          'app/reference/operation'
        ),
        route(
          'Reference/Device',
          null,
          {
            title: 'Справочники/Устройства',
            requiresAuth: true,
            isAdmin: true,
            isManager: true,
            items: [
              {
                text: 'Справочники',
                disabled: true,
                href: '',
              },
              {
                text: 'Устройства',
                disabled: true,
                href: '',
              }
            ],
          },
          'app/reference/device'
        ),
        route(
          'Analytics/Anomalies',
          null,
          {
            title: 'Аналитика/Аномалии',
            requiresAuth: true,
            isAdmin: true,
            isManager: true,
            isSupervisor: true,
            items: [
              {
                text: 'Аналитика',
                disabled: true,
                href: '',
              },
              {
                text: 'Аномалии',
                disabled: true,
                href: '',
              }
            ],
          },
          'app/analytics/oil/anomalies/max'
        ),
        route(
          'Analytics/Kpi/PlannedIndicators',
          null,
          {
            title: 'KPI/Плановые_показатели',
            requiresAuth: true,
            isAdmin: true,
            isManager: true,
            isSupervisor: true,
            items: [
              {
                text: 'KPI',
                disabled: true,
                href: '',
              },
              {
                text: 'Плановые показатели',
                disabled: true,
                href: '',
              }
            ],
          },
          'app/analytics/oil/kpi/planned-indicators'
        ),
        route(
          'Analytics/Kpi/CustomerPlans',
          null,
          {
            title: 'KPI/Плановые_показатели',
            requiresAuth: true,
            isCustomer: true,
            items: [
              {
                text: 'KPI',
                disabled: true,
                href: '',
              },
              {
                text: 'Плановые показатели',
                disabled: true,
                href: '',
              }
            ],
          },
          'app/analytics/oil/kpi/customer-plans'
        ),
        route(
          'Analytics/Audit/DataTransfer',
          null,
          {
            title: 'Аналитика/Аудит',
            requiresAuth: true,
            isAdmin: true,
            isManager: true,
            isSupervisor: true,
            items: [
              {
                text: 'Аналитика',
                disabled: true,
                href: '',
              },
              {
                text: 'Аудит',
                disabled: true,
                href: '',
              }
            ],
          },
          '/app/analytics/audit/data-transfer',
        ),
        route(
          'Account/Role',
          null,
          {
            title: 'Администрирование/Роли',
            requiresAuth: true,
            isAdmin: true,
            // isManager: true,
            items: [
              {
                text: 'Администрирование',
                disabled: true,
                href: '',
              },
              {
                text: 'Роли',
                disabled: true,
                href: '',
              }
            ],
          },
          'app/account/role'
        ),
        route(
          'Account/User',
          null,
          {
            title: 'Администрирование/Пользователи',
            requiresAuth: true,
            isAdmin: true,
            isManager: true,
            items: [
              {
                text: 'Администрирование',
                disabled: true,
                href: '',
              },
              {
                text: 'Пользователи',
                disabled: true,
                href: '',
              }
            ],
          },
          'app/account/user'
        ),
        route(
          'Account/UsersActivity',
          null,
          {
            title: 'Администрирование/Пользователи/Активность',
            requiresAuth: true,
            isAdmin: true,
            // isManager: true,
            // isSupervisor: true,
            // isCustomer: true,
            items: [
              {
                text: 'Администрирование',
                disabled: true,
                href: '',
              },
              {
                text: 'Пользователи/Активность',
                disabled: true,
                href: '',
              }
            ],
          },
          'app/account/usersActivity'
        ),
        route(
          'User/Profile',
          null,
          {
            title: 'Пользователь/Профиль',
            requiresAuth: true,
            isAdmin: true,
            isManager: true,
            isSupervisor: true,
            isCustomer: true,
            items: [
              {
                text: 'Администрирование',
                disabled: true,
                href: '',
              },
              {
                text: 'Пользователи/Профиль',
                disabled: true,
                href: '',
              }
            ],
          },
          'app/user/profile'
        ),
        route(
          'Customer/CustomerPage',
          null,
          {
              title: 'Главная',
              requiresAuth: true,
              isCustomer: true,
              items: [
                  {
                      text: 'Главная',
                      disabled: true,
                      href: '',
                  }
              ],
            },
          '/app/customerpage'
        ),
        route(
          'Customer/Passport',
          null,
          {
            title: 'Паспорт организации',
            requiresAuth: true,
            isCustomer: true,
            items: [
                {
                  text: 'Паспорт',
                  disabled: true,
                  href: ''
                }
            ],
          },
          '/app/customer/passport'
        ),
        route(
          'Customer/CReference/Contract/Fields',
          null,
          {
            title: 'Месторождения',
            requiresAuth: true,
            isCustomer: true,
            items: [
              {
                text: 'Месторождения',
                disabled: true,
                href: ''
              }
            ]
          },
          'app/customer/fields'
        ),
        route(
          'Customer/CReport/CProduce',
          null,
          {
            title: 'Отчеты/Добыча',
            requiresAuth: true,
            isCustomer: true,
            items: [
              {
                text: 'Отчеты',
                disabled: true,
                href: '',
              },
              {
                text: 'Производство',
                disabled: true,
                href: '',
              }
            ],
          },
          'app/customer/creport/cproduce'
        ),
        route(
          'Customer/CReport/CTransport',
          null,
          {
            title: 'Отчеты/Транспортировка',
            requiresAuth: true,
            isCustomer: true,
            items: [
              {
                text: 'Отчеты',
                disabled: true,
                href: '',
              },
              {
                text: 'Транспортировка',
                disabled: true,
                href: '',
              }
            ],
          },
          'app/customer/creport/ctransport'
        ),
        route(
          'Customer/CReport/CRefinery',
          null,
          {
            title: 'Отчеты/Переработка',
            requiresAuth: true,
            isCustomer: true,
            items: [
              {
                text: 'Отчеты',
                disabled: true,
                href: '',
              },
              {
                text: 'Переработка',
                disabled: true,
                href: '',
              }
            ],
          },
          'app/customer/creport/crefinery'
        ),
        route(
          'Customer/CReport/CFactories',
          null,
          {
            title: 'Отчеты/Битумные заводы',
            requiresAuth: true,
            isCustomer: true,
            items: [
              {
                text: 'Отчеты',
                disabled: true,
                href: '',
              },
              {
                text: 'Заводы',
                disabled: true,
                href: '',
              }
            ],
          },
          'app/customer/creport/cfactories'
        ),
        route(
          'Customer/CReport/CTerminals',
          null,
          {
            title: 'Отчеты/Терминалы',
            requiresAuth: true,
            isCustomer: true,
            items: [
              {
                text: 'Отчеты',
                disabled: true,
                href: '',
              },
              {
                text: 'Терминалы',
                disabled: true,
                href: '',
              }
            ],
          },
          'app/customer/creport/cterminals'
        ),
        route(
          'Customer/CReference/Contract/CustomerItems',
          null,
          {
            title: 'Контракты',
            requiresAuth: true,
            isCustomer: true,
            items: [
              {
                text: 'Контракты',
                disabled: true,
                href: ''
              }
            ]
          },
          'app/customer/contracts'
        ),
        
        route(
          'User/PassChangePage',
          null,
          {
            title: 'Смена пароля',
            requiresAuth: true,
            isManager: true,
            isAdmin: true,
            isSupervisor: true,
            isCustomer: true
          },
          'app/user/profile/changepass'
        )
      ]),
  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("user") === null) {
      next({
        path: "/auth/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user.hasOwnProperty("role") &&
        user.role === "Admin" &&
        to.matched.some((record) => record.meta.isAdmin)
      ) {
        next();
      } else if (
        user.hasOwnProperty("role") &&
        user.role === "Manager" &&
        to.matched.some((record) => record.meta.isManager)
      ) {
        next();
      } else if (
        user.hasOwnProperty("role") &&
        user.role === "Supervisor" &&
        to.matched.some((record) => record.meta.isSupervisor)
      ) {
        next();
      } else if (
        user.hasOwnProperty("role") &&
        user.role === "Customer" &&
        to.matched.some((record) => record.meta.isCustomer)
      ) {
        next();
      } else {
        alert("Доступ запрещен.");
        return false;
      }
    }
  }
  else if (to.matched.some((record) => record.meta.isGuest)) {
    next();
  } else {
    next();
  }
  return to.path.endsWith('/') ? next() : next(trailingSlash(to.path))
})
export default router
