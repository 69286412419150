<template>
  <v-alert :value="showError" type="error" v-text="errorMessage">
    This is an error alert.
  </v-alert>
</template>
<script>
import { get, sync } from "vuex-pathify";

export default {
  name: "CustomerReportAlert.vue",
  computed: {
    ...get("report", ["showError"]),
    ...get("report", ["errorMessage"]),
  },
};
</script>