<template>
  <div>
    <v-tabs
      class="elevation-2"
      dark
      :grow="true"
      show-arrows
      v-model="activeTab"
    >
      <v-tabs-slider :color="colorSlider"></v-tabs-slider>
      <v-tab
        v-for="tab in tabs"
        :href="`#tab-${tab.name}`"
        :key="tab.id"
        @click="selectTab(tab)"
        :style="{ color: colorText }"
        :disabled="tab.disabled"
      >
        {{ tab.name }}
      </v-tab>
      <v-tab-item v-for="tab in tabs" :key="tab.id"> </v-tab-item>
    </v-tabs>
  </div>
</template>
<script>
import { get, sync } from "vuex-pathify";
export default {
  name: "CustomerReportSubCategory.vue",
  props: {
    tabs: { type: Array }
  },
  computed: {
    ...sync("report", ["currentTab"])
  },
  data() {
    return {
      activeTab: null,
      colorText: "#BBDEFB",
      colorSlider: "#BBDEFB"
    };
  },
  methods: {
    selectTab(tab) {
      if (this.currentTab !== tab) {
        this.currentTab = tab;
        this.$emit("select-tab", this.currentTab);
      }
    }
  },
  mounted() {
    if (this.currentTab != null) {
      this.activeTab = "tab-" + this.currentTab.name;
      this.selectTab(this.currentTab);
    }
  }
};
</script>
