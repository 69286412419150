<template>
  <v-tabs
    class="elevation-2"
    dark
    :grow="true"
    show-arrows
    v-model="activeItem"
  >
    <v-tabs-slider :color="colorSlider"></v-tabs-slider>
    <v-tab
      v-for="tab in tabs"
      :key="tab.id"
      :href="'#' + `tab-${tab.id}`"
      @click="selectTab(tab)"
      :style="{ color: colorText }"
    >
      {{ tab.name }}
    </v-tab>
    <v-tabs-items v-model="activeItem">
      <v-tab-item
        v-for="(item, index) in componentList"
        :key="index"
        :value="`tab-${tabs[index].id}`"
      >
        <component :is="item.name" v-bind="item.props" />
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>
<script>
import { get } from "vuex-pathify";

export default {
  name: "ContractCategory",
  components: {
    ContractCategoryItemPrimary: () =>
      import("@/components/reference/contract/ContractCategoryItemPrimary.vue"),
    ContractCategoryItemSecondary: () =>
      import(
        "@/components/reference/contract/ContractCategoryItemSecondary.vue"
      ),
  },
  computed: {
    options: get("reference/optionsContract"),
  },
  data() {
    return {
      activeItem: 0,
      colorText: "#BBDEFB",
      colorSlider: "#BBDEFB",
      currentTab: null,
      tabs: [],
      componentList: [],
    };
  },
  methods: {
    initialize() {
      this.tabs = this.options.category;
      this.tabs.forEach((tab, index) => {
        const name =
          tab.headerId == 0
            ? "contract-category-item-primary"
            : "contract-category-item-secondary";
        const props = {
          title: tab.name,
          headers: this.options.headers[tab.headerId],
          path: tab.path,
        };
        this.componentList.push({ name, props });
      });
    },
    selectTab(tab) {
      if (this.currentTab !== tab) {
        this.currentTab = tab;
        this.$emit("select-tab", this.currentTab.id);
      }
    },
  },
  created() {
    this.currentTab = this.tabs[0];
    this.initialize();
  },
};
</script>