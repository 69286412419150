<template>
  <v-container fluid>
    <v-slide-y-transition mode="out-in">
      <v-row no-gutters>
        <v-col>
          <v-card class="pa-2 ma-2" outlined tile>
            <!-- toolbar -->
            <!-- :editedIndex="editedIndex" -->
            <reference-tool-bar
              :title="title"
              :createItemTitle="createTitle"
              :formTitle="formTitle"
              :dialog="dialog"
              @save="save"
              @close="close"
              @open="open"
            >
              <template v-slot:formCreateEdit>
                <v-flex xs12 sm12 md12>
                  <v-autocomplete
                    v-model="organizationSelect"
                    :hint="
                      `Идентификатор организации - [${organizationSelect.id}]`
                    "
                    :items="organizationList"
                    item-text="nameRu"
                    item-value="id"
                    label="Организация"
                    persistent-hint
                    return-object
                    single-line
                  >
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="editedItem.registrationNumber"
                    label="Регистрационный номер"
                    :hint="`Введите регистрационный номер`"
                    :rules="[rules.required, rules.min]"
                    autofocus
                  ></v-text-field>
                </v-flex>
                <!-- <v-flex xs12 sm12 md12>
                  <v-select
                    v-model="contractTypeSelect"
                    :hint="
                      `Идентификатор типа контракта - [${contractTypeSelect.id}]`
                    "
                    :items="contractTypeList"
                    item-text="nameRu"
                    item-value="id"
                    label="Тип контракта"
                    persistent-hint
                    return-object
                    single-line
                  >
                  </v-select> 
                </v-flex>-->
                <v-flex xs12 sm12 md12>
                  <v-select
                    v-model="contractCategorySelect"
                    :hint="
                      `Идентификатор вида контракта - [${contractCategorySelect.id}]`
                    "
                    :items="contractCategoryList"
                    item-text="nameRu"
                    item-value="id"
                    label="Вид контракта"
                    persistent-hint
                    return-object
                    single-line
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <contract-date-picker
                    :dateValue="contractDate.registration"
                  />
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <contract-date-picker :dateValue="contractDate.expiration" />
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-select
                    v-model="contractMineralGroupSelect"
                    :hint="
                      `Идентификатор группы полезных ископаемых - [${contractMineralGroupSelect.id}]`
                    "
                    :items="contractMineralGroupList"
                    item-text="nameRu"
                    item-value="id"
                    label="Группа полезных ископаемых"
                    persistent-hint
                    return-object
                    single-line
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-select
                    v-model="contractSubsoilTypeSelect"
                    :hint="
                      `Идентификатор вида недропользования - [${contractSubsoilTypeSelect.id}]`
                    "
                    :items="contractSubsoilTypeList"
                    item-text="nameRu"
                    item-value="id"
                    label="Вид недропользования"
                    persistent-hint
                    return-object
                    single-line
                  >
                  </v-select>
                </v-flex>
                <!-- <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="editedItem.nameRu"
                    label="NameRu"
                    :hint="`Наименование контракта - Допускаются только буквы`"
                    :rules="[rules.required, rules.min, rules.alfabetRu]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="editedItem.nameKz"
                    label="NameKz"
                    :hint="`Наименование контракта - Допускаются только буквы`"
                    :rules="[rules.required, rules.min, rules.alfabetRu]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="editedItem.nameEn"
                    label="NameEn"
                    :hint="`Наименование контракта - Допускаются только буквы`"
                    :rules="[rules.required, rules.min, rules.alfabetEn]"
                  ></v-text-field>
                </v-flex> -->
                <v-flex xs12 sm12 md12>
                  <v-select
                    v-model="statusSelect"
                    :hint="`Идентификатор статуса - [${statusSelect.id}]`"
                    :items="statusList"
                    item-text="nameRu"
                    item-value="id"
                    label="Статус"
                    persistent-hint
                    return-object
                    single-line
                  >
                  </v-select>
                </v-flex>
              </template>
            </reference-tool-bar>
            <!--Таблица с данными-->
            <reference-table
              :headers="headers"
              :items="items"
              @edit-item="editItem($event)"
              @remove-item="removeItem($event)"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
import { Vue } from "vue-property-decorator";
import { get } from "vuex-pathify";
import { mapActions } from "vuex";
import { rules } from "@/util/rules";
import { formatISO } from "date-fns";
import { ORGANIZATION_TYPES } from "@/util/globals";

export default Vue.extend({
  name: "ContractCategoryItemPrimary",
  components: {
    ReferenceTable: () => import("@/components/reference/ReferenceTable.vue"),
    ReferenceToolBar: () =>
      import("@/components/reference/ReferenceToolBar.vue"),
    ContractDatePicker: () =>
      import("@/components/reference/contract/ContractDatePicker.vue")
  },
  props: {
    title: {
      type: String
    },
    headers: {
      type: Array
    },
    path: {
      type: String
    }
  },
  computed: {
    statusList: get("reference/getStatus"),
    formTitle() {
      return this.editedIndex === -1 ? "Создать" : "Редактировать";
    }
  },
  data() {
    return {
      rules,
      dialog: false,
      editedIndex: -1,
      statusSelect: 0,
      items: [],
      createTitle: "Создать новый элемент",
      editedItem: {
        id: 0,
        registrationNumber: "",
        registrationDate: "",
        expirationDate: "",
        nameRu: "",
        nameKz: "",
        nameEn: ""
      },
      defaultItem: {
        id: 0,
        registrationNumber: "",
        registrationDate: "",
        expirationDate: "",
        nameRu: "",
        nameKz: "",
        nameEn: ""
      },
      organizationList: [],
      organizationSelect: 0,
      contractTypeList: [],
      contractTypeSelect: 0,
      contractSubsoilTypeList: [],
      contractSubsoilTypeSelect: 0,
      contractCategoryList: [],
      contractCategorySelect: 0,
      contractMineralGroupList: [],
      contractMineralGroupSelect: 0,
      referenceList: [
        {
          name: "Type",
          url: `api/Reference/Contract/Type/0`,
          items: []
        },
        {
          name: "Category",
          url: `api/Reference/Contract/Category/0`,
          items: []
        },
        {
          name: "SubsoilType",
          url: `api/Reference/Contract/SubsoilType/0`,
          items: []
        },
        {
          name: "MineralGroup",
          url: `api/Reference/Contract/MineralGroup/0`,
          items: []
        }
      ],
      contractDate: {
        registration: {
          label: "Дата регистрации",
          value: null
        },
        expiration: {
          label: "Дата окончания",
          value: null
        }
      }
    };
  },
  methods: {
    ...mapActions({
      fetchStatusReference: "reference/fetchStatus"
    }),
    setItemsParams(data) {
  this.items = data.map(element => {
    const organization = this.organizationList.find(
      current => current.id === element.organizationId
    );

    if (organization) {
      element.organizationName = organization.nameRu;
    } else {
      // Обработка ситуации, когда объект не найден
      element.organizationName = "Неизвестная организация";
    }

    return element;
  });
},
    setItemsSelectors() {
      this.contractTypeList = this.referenceList.find(
        element => element.name == "Type"
      ).items;
      this.contractCategoryList = this.referenceList.find(
        element => element.name == "Category"
      ).items;
      this.contractSubsoilTypeList = this.referenceList.find(
        element => element.name == "SubsoilType"
      ).items;
      this.contractMineralGroupList = this.referenceList.find(
        e => e.name == "MineralGroup"
      ).items;

      this.contractTypeSelect = this.contractTypeList[0];
      this.contractCategorySelect = this.contractCategoryList[0];
      this.contractSubsoilTypeSelect = this.contractSubsoilTypeList[0];
      this.contractMineralGroupSelect = this.contractMineralGroupList[0];
    },
    fetchOrganizations() {
      const path = `api/Reference/Organization/${ORGANIZATION_TYPES.Produce}/0`;
      (async () => {
        const { data } = await this.$http.get(path);
        this.organizationList = data;
        this.organizationSelect = this.organizationList[0];
      })();
    },
    fetchContracts() {
      (async () => {
        const { data } = await this.$http.get(this.path + "/0");
        this.setItemsParams(data);
        this.setItemsSelectors();
      })();
    },
    fetchContractsReference() {
      const fetchURL = url => this.$http.get(url);
      const URLs = this.referenceList.map(element => element.url);
      const promiseArray = URLs.map(fetchURL);
      Promise.all(promiseArray)
        .then(data => {
          data.forEach((response, index) => {
            this.referenceList[index].items = response.data;
          });
        })
        .catch(err => {});
    },
    initialize() {
      this.fetchOrganizations();
      this.fetchStatusReference().then(result => {
        this.statusSelect = this.statusList.find(
          status => status.name == "Active"
        );
      });
      this.fetchContractsReference();
      this.fetchContracts();
    },
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.statusSelect = this.statusList[0];
        this.contractDate.registration.value = null;
        this.contractDate.expiration.value = null;
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      const model = {
        id: this.editedItem.id,
        registrationNumber: this.editedItem.registrationNumber,
        registrationDate: this.contractDate.registration.value,
        expirationDate: this.contractDate.expiration.value,
        contractTypeId: this.contractTypeSelect.id,
        contractSubsoilTypeId: this.contractSubsoilTypeSelect.id,
        contractCategoryId: this.contractCategorySelect.id,
        contractMineralGroupId: this.contractMineralGroupSelect.id,
        organizationId: this.organizationSelect.id,
        statusId: this.statusSelect.id,
        nameRu: this.editedItem.nameRu,
        nameKz: this.editedItem.nameKz,
        nameEn: this.editedItem.nameEn,
        created: this.editedItem.created
      };
      if (this.editedIndex > -1) {
        this.$http.put(this.path, model).then(response => {
          if (response.status === "Ok") this.initialize();
        });
      } else {
        model.id = 0;
        this.$http.post(this.path, model).then(response => {
          if (response.status === "Ok") this.initialize();
        });
      }
      this.close();
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.organizationSelect = this.organizationList.find(
        organization => organization.id == item.organizationId
      );
      this.statusSelect = { id: item.status.id, name: item.status.NameRu };
      this.contractTypeSelect = item.contractType;
      this.contractCategorySelect = item.contractCategory;
      this.contractSubsoilTypeSelect = item.contractSubsoilType;
      this.contractMineralGroupSelect = item.contractMineralGroup;
      this.contractDate.registration.value = formatISO(
        new Date(item.registrationDate),
        { representation: "date" }
      );
      this.contractDate.expiration.value = formatISO(
        new Date(item.expirationDate),
        { representation: "date" }
      );
      this.open();
    },
    removeItem(item) {
      if (confirm("Are you sure you want to delete this item?")) {
        const model = Object.assign({}, item);
        this.$http.delete(this.path, { data: model }).then(response => {
          if (response.status === "Ok") this.initialize();
        });
      }
    }
  },
  created() {
    this.initialize();
  }
});
</script>
